import type { FC } from 'react';
import { ButtonProps, Tooltip, TooltipProps } from 'antd';
import { Button } from '../../atoms/Button';


export interface ProjectModeSelectorCardButtonProps {
  title: string,
  buttonProps: ButtonProps,
  tooltipProps?: TooltipProps,
}

export const ProjectModeSelectorCardButton: FC<ProjectModeSelectorCardButtonProps> = ({
  title,
  buttonProps,
  tooltipProps,
}) => {
  const button = (
    <Button
      {...buttonProps}
    >
      { title }
    </Button>
  );
  if (!tooltipProps) {
    return button;
  }
  return (
    <Tooltip
      {...tooltipProps}
    >
      { button }
    </Tooltip>
  );
};
