import type { FC, ReactNode } from 'react';
import type { ModalProps as AntDModalProps } from 'antd';
import { StyledModal } from './Modal.styles';
import { ModalFooter } from './ModalFooter';


export interface ModalProps extends AntDModalProps {
  actions?: ReactNode;
}

export const Modal: FC<ModalProps> = ({ actions, ...rest }) => (
  <StyledModal {...rest} footer={actions ? <ModalFooter>{actions}</ModalFooter> : null} />
);
