import type { FC } from 'react';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { RadioGroup } from '../../../../molecules/RadioGroup';
import { FormItem } from '../../../../molecules/FormItem';


export const TypeStepFormItem: FC = () => {
  const tooltip = `
  <div>
    <p>Tell us what you are selling! Is it a product or a service?</p>
      <ul style="padding-left: 15px">
          <li>Products are generally tangible items — something that your customers can physically hold in their 
          hands. EXAMPLE: Your company sells branded items and merchandise to other businesses.</li>
          <li>Services are typically intangible — something that you provide or perform for another person.</li>
      </ul>
  </div>
`;
  const renderMarkdown = useMemo(
    () => <ReactMarkdown rehypePlugins={[rehypeRaw]}>{tooltip}</ReactMarkdown>,
    [tooltip]
  );
  return (
    <FormItem
      name="type"
      label="Do you offer a product or service?"
      rules={[{ required: true, whitespace: true, message: 'Please select product type!' }]}
      tooltip={{
        title: renderMarkdown,
        arrow: true,
        placement: 'left',
        overlayInnerStyle: { padding: '2px 16px' }
      }}
      largeLabel
    >
      <RadioGroup
        options={[
          { value: 'product', label: 'Product' },
          { value: 'service', label: 'Service' },
        ]}
      />
    </FormItem>
  );
};
