import type { FC } from 'react';
import { InputNumberProps as AntDInputNumberProps } from 'antd';
import { StyledInputNumber } from './InputNumber.styles';


type InputNumberProps = AntDInputNumberProps;

export const InputNumber: FC<InputNumberProps> = (props) => (
  <StyledInputNumber {...props} />
);
