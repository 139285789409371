export const restoreInputFocus = ({ customInputRefs, index, inputName }) => {
  const currentFocusedElement = document.activeElement;
  const currentFocusedElementTagName = currentFocusedElement?.tagName.toLowerCase();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const currentFocusedElementName = currentFocusedElement?.name;

  // After force dispatching form update the input is re-rendered and loses focus
  setTimeout(() => {
    // Check if the input was focused before force dispatch
    const wasInputFocused = currentFocusedElementTagName === 'input' && currentFocusedElementName === inputName;

    if (wasInputFocused) {
      const inputRef = customInputRefs?.[index];
      inputRef?.current?.focus();
    }
    // timeout must be greater than 0 to trigger after the form onValuesChange
  }, 1);
};
