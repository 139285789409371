import axios from 'axios';
import {
  Project,
  ProjectInsights,
  ProjectsListStatusTypes,
  SurveyStatistics,
  ProductCategory,
  ServiceCategory
} from '../types/project';
import { ProjectSurveyFormSuggestion } from '../types/survey';
import { CategorySurveyConfig } from '../constants/surveyConfig';


export const createProject = async (project: Project): Promise<Project> => {
  const url = '/project';

  return axios.post(url, project);
};
export const updateProject = async (id: string, project: Project): Promise<Project> => {
  const url = `/project/${id}`;

  return axios.patch(url, project);
};

export const getProjects = async (status?: 'finished'): Promise<Project[]> => {
  const url = '/project';

  const params: { status?: 'finished'; } = {};

  if (status) {
    params.status = status;
  }

  return axios.get(url, { params });
};

export const getProject = async (id: string, includeLastSurveyResponseDate?: boolean): Promise<Project> => {
  const url = `/project/${id}`;
  let params;

  if (includeLastSurveyResponseDate) {
    params = { includeLastSurveyResponseDate: true };
  }

  return axios.get(url, { params });
};

export const getProjectShareToken = async (id: string): Promise<{ url: string }> => {
  const url = `/project/${id}/share`;

  return axios.get(url);
};

export const getSharedProject = async (
  shareToken: string,
  includeLastSurveyResponseDate?: boolean
): Promise<Project> => {
  const url = `/project/shared/${shareToken}`;
  let params;

  if (includeLastSurveyResponseDate) {
    params = { includeLastSurveyResponseDate: true };
  }

  return axios.get(url, { params });
};

export const getNewestProject = async (): Promise<Project> => {
  const url = '/project/newest';

  return axios.get(url);
};

export const deleteProject = async (id: string): Promise<void> => {
  const url = `/project/${id}`;

  return axios.delete(url);
};

export const archiveProject = async (id: string): Promise<void> => {
  const url = `/project/${id}/archive`;

  return axios.patch(url);
};

export const getProjectSurveyStatistic = async (id: string, shareToken?: string): Promise<SurveyStatistics> => {
  const url = `/project/${id}/survey/statistics`;
  let params;

  if (shareToken) {
    params = { shareToken };
  }

  return axios.get(url, { params });
};

export const getProjectInsights = async (): Promise<ProjectInsights> => {
  const url = '/projects-insights';

  return axios.get(url);
};

export const getProjectSuggestions = async (itemName: string, projectConfig: CategorySurveyConfig):
Promise<ProjectSurveyFormSuggestion> => {
  const url = '/project/suggestions';

  return axios.post(url, { itemName, projectConfig });
};

export const getProjectSuggestionsWithQuestions = async (
  item: Pick<Project['item'], 'name' | 'description'>,
  targetGroup: Project['targetGroup'],
  surveyStepsConfig: CategorySurveyConfig,
  language?: string
):
Promise<ProjectSurveyFormSuggestion> => {
  const url = '/project/suggestions/questions';

  return axios.post(url, {
    item,
    targetGroup,
    surveyStepsConfig,
    language
  });
};

export const getProjectCategorySuggestion = async (
  item: Pick<Project['item'], 'name' | 'description'>,
  categories: Array<{ label: string; value: string; }>
): Promise<{ category: ProductCategory | ServiceCategory }> => {
  const url = '/project/suggestions/category';

  return axios.post(url, { item, categories });
};

export const adminCreateProject = async (project: Project): Promise<Project> => {
  const url = '/admin/project';

  return axios.post(url, project);
};

export const adminUpdateProject = async (projectId: string, project: Project): Promise<Project> => {
  const url = `/admin/projects/${projectId}`;

  return axios.patch(url, project);
};

export const adminShareProvisionedProject = async (projectId: string, companyIds: string[]): Promise<void> => {
  const url = `/admin/projects/${projectId}/share`;

  const body = {
    sharedWith: companyIds
  };

  return axios.patch(url, body);
};

export const adminGetProvisionedProjects = async (
  status?: ProjectsListStatusTypes,
  type?: string
): Promise<Project[]> => {
  const url = '/admin/projects';
  const params: { status?: ProjectsListStatusTypes; type?: string } = {
    status: undefined,
    type: undefined,
  };

  if (status) {
    params.status = status;
  }

  if (type) {
    params.type = type;
  }

  return axios.get(url, { params });
};

export const adminGetOneProject = async (projectId: string): Promise<Project> => {
  const url = `/admin/projects/${projectId}`;

  return axios.get(url);
};

export const adminDeleteOneProject = async (projectId: string): Promise<Project> => {
  const url = `/admin/projects/${projectId}`;

  return axios.delete(url);
};

export const adminGetProjectImagesSignedUrls = async (
  projectImagesConfig: { contentType: string; step: string }[]
): Promise<
{ contentType: string; step: string; preSignedUrl: string; }[]
> => {
  const url = '/admin/project/image/presigned-urls';

  return axios.post(url, projectImagesConfig);
};

export const adminArchiveProject = async (id: string): Promise<void> => {
  const url = `/admin/project/${id}/archive`;

  return axios.patch(url);
};

export const adminRestartCINTSurvey = async (
  id: string,
  project?: Partial<Project>
): Promise<void> => {
  const url = `/admin/project/${id}/restart`;

  return axios.post(url, project);
};

export const partnerCreateProject = async (project: Project): Promise<Project> => {
  const url = '/project/partner';

  return axios.post(url, project);
};
