import { AdminProjectForm, WTPContentToEditType } from './AdminCreateProject.types';
import { CategorySurveyConfig } from '../../../constants/surveyConfig';
import { ProjectForm } from '../../organisms/AddProjectForm/AddProjectForm.types';
import {
  MIN_SURVEY_COMPLETION_TIME
} from '../../organisms/AddProjectForm/steps/TypeStepFormItem/SettingsFormItem/SettingsFormItem';


export const combineWTPEditsWithProjectSurveyConfig = (
  wtpEdits: WTPContentToEditType,
  projectSurveyConfig: CategorySurveyConfig
) => {
  const wtpStepWithIntro = 'price.low';

  const steps = projectSurveyConfig.steps.map((currentStep) => {
    if (currentStep.step in wtpEdits) {
      if (currentStep?.categories?.general) {
        const parsedBody = wtpEdits[currentStep.step].split('\n').filter(Boolean);
        const modifiedStep = {
          ...currentStep,
          categories: {
            ...currentStep.categories,
            general: {
              ...currentStep.categories.general,
              body: parsedBody
            }
          }
        };

        if (currentStep.step === wtpStepWithIntro) {
          const wtpIntroStepName = 'price.low.intro';
          let newIntro: string[] | string = wtpEdits[wtpIntroStepName];
          if (newIntro.includes('\n')) {
            newIntro = newIntro.split('\n').filter(Boolean);
          }

          return {
            ...modifiedStep,
            intro: newIntro
          };
        }

        return modifiedStep;
      }

      const parsedBody = wtpEdits[currentStep.step].split('\n').filter(Boolean);

      return {
        ...currentStep,
        body: parsedBody
      };
    }

    return currentStep;
  });

  return {
    ...projectSurveyConfig,
    steps
  };
};

export const initialFormValues: AdminProjectForm = {
  type: 'product',
  item: { category: 'general' } as ProjectForm['item'],
  settings: {
    qualityControl: {
      minCompletionTime: MIN_SURVEY_COMPLETION_TIME,
    },
  },
  schemaVersion: 2,
};

export const ADMIN_PAYMENT_MODELS = [
  { label: 'Per unit sold', value: 'per_unit_sold' },
  { label: 'Fixed', value: 'fixed' },
  { label: 'Per Hour', value: 'per_hour' },
  { label: 'Per week', value: 'per_week' },
  { label: 'Per Month', value: 'per_month' },
  { label: 'Per Year', value: 'per_year' },
];

export const getRespondentLimit = (steps: AdminProjectForm['steps']) => {
  if (!steps) {
    return;
  }

  const highestOptionsAmount = steps.reduce((accumulator, currentStep) => {
    if (!currentStep.options || !Array.isArray(currentStep.options)) {
      return accumulator;
    }

    const optionsAmount = currentStep.options.length;

    if (optionsAmount > accumulator) {
      return optionsAmount;
    }

    return accumulator;
  }, 0);

  return highestOptionsAmount * 100;
};

export const WTP_EDIT_STEP_NAMES = ['intro', 'price.low', 'price.high', 'price.midHigh', 'price.midLow'];
