import en from './en.json';
import enGB from './en-gb.json';
import de from './de.json';
import sv from './sv.json';
import da from './da.json';
import nb from './nb.json';
import es from './es.json';
import fr from './fr.json';


export const translations = {
  en,
  'en-gb': enGB,
  de,
  sv,
  da,
  nb,
  es,
  fr,
};

export const appLangs = Object.keys(translations) as (keyof typeof translations)[];
