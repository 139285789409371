import {
  Divider,
  Form,
  Space,
  Input as AntDInput,
  Row,
  Col,
  Switch,
  Typography
} from 'antd';
import {
  FC, useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import {
  useMatch, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import { Card } from '../../atoms/Card';
import { DescriptionStepFormItem } from '../../organisms/AddProjectForm/steps';
import { Select } from '../../atoms/Select';
import { PreviewProjectSurveyModal } from '../../molecules/PreviewProjectSurveyModal';
import { getSurveyConfig } from '../../../helpers/getSurveyConfig';
import { RenderProjectSurveyPreview } from '../../../helpers/RenderProjectSurveyPreview';
import type { AdminProject, Project } from '../../../types/project';
import { CategorySurveyConfig } from '../../../constants/surveyConfig';
import { adminGetCompanies } from '../../../api/company';
import { useAlert } from '../../../contexts/AlertContext';
import { IAdminCompaniesList } from '../../../types/company.types';
import { ProjectForm } from '../../organisms/AddProjectForm/AddProjectForm.types';
import { getTranslatedProjectConfigSteps } from '../../organisms/ProjectWizard/ProjectWizard.helper';
import {
  createProjectSurveyConfig,
  getProjectSurveyConfig,
  updateProjectSurveyConfig
} from '../../../api/project-survey-config';
import {
  adminCreateProject,
  adminGetOneProject,
  adminGetProjectImagesSignedUrls,
  adminUpdateProject,
  partnerCreateProject,
} from '../../../api/project';
import { Spinner } from '../../atoms/Spinner';
import { validateAddProjectForm } from '../../organisms/AddProjectForm/helpers/validateAddProjectForm';
import { AdminProjectForm, WTPContentToEditType } from './AdminCreateCustomerProject.types';
import { useProjectFormTranslations } from '../../../hooks/useProjectFormTranslations';
import { OptionInterface/* , useOptionsTranslation */ } from '../../../hooks/useOptionsTranslation';
import { deeplTranslate } from '../../../helpers/deeplTranslate';
import { FormItem } from '../../molecules/FormItem';
import { DynamicCustomProjectStepsForm } from '../../organisms/DynamicCustomProjectStepsForm';
import { uploadFileToS3SignedUrl } from '../../../helpers/uploadFileToS3SignedUrl';
import { EditWTPContentModal } from '../../organisms/EditWTPContentModal';
import {
  ADMIN_PAYMENT_MODELS,
  combineWTPEditsWithProjectSurveyConfig,
  initialFormValues, resetRegionState,
  WTP_EDIT_STEP_NAMES
} from './AdminCreateCustomerProject.helper';
import { getProjectFormConfig } from '../../../helpers/getProjectFormConfig';
import { TargetGroupCountryRegionsSelector } from '../../organisms/TargetGroupCountryRegionsSelector';
import { getCINTCountries, getProfilingQuestionsPublic } from '../../../api/cint';
import { callbackWithRetry } from '../../../helpers/callbackWithRetry';
import type { CINTCountry } from '../../../types/cint';
import { InputNumber } from '../../atoms/InputNumber';
import { TargetGroupLanguageSelect } from '../../molecules/TargetGroupLanguageSelect';


interface AdminCreateCustomerProjectProps {
  isPartnerPage?: boolean;
}

const AdminCreateCustomerProject: FC<AdminCreateCustomerProjectProps> = ({ isPartnerPage }) => {
  // TODO: Add isPreviewOpen query search param support for 'Launch survey' button in admin surveys list
  const [searchParams] = useSearchParams();
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const alert = useAlert();
  const { id } = useParams();
  const isEditMode = useMatch('/admin/projects/customer/:id/edit');
  const isDuplicateMode = useMatch('/admin/projects/customer/:id/duplicate');

  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState<AdminProjectForm>(initialFormValues);
  const [companies, setCompanies] = useState<IAdminCompaniesList[]>();
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false);
  const [CINTCountries, setCINTCountries] = useState<CINTCountry[]>();

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isProjectLoading, setIsProjectLoading] = useState(false);

  const [projectSurveyConfig, setProjectSurveyConfig] = useState<CategorySurveyConfig>();
  const [isProjectSurveyConfigLoading, setIsProjectSurveyConfigLoading] = useState(false);
  const cachedProjectValuesRef = useRef<ProjectForm | null>(null);

  const [isEnableWTPEdit, setIsEnableWTPEdit] = useState(false);
  const [isEditWTPContentModalOpen, setIsEditWTPContentModalOpen] = useState(false);
  const [WTPContentToEdit, setWTPContentToEdit] = useState<WTPContentToEditType>();

  const isLastStep = step === 4;

  const navigate = useNavigate();

  const composeCustomStepsFromProjectSurveyConfig = (surveyConfig: CategorySurveyConfig) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const customSteps = surveyConfig?.steps?.filter((stepConfig) => stepConfig.step.startsWith('customStep'));

    return customSteps.map((stepConfig) => {
      const generalCategoryConfig = { ...stepConfig.categories.general };

      if ('image' in generalCategoryConfig && generalCategoryConfig.image.url) {
        generalCategoryConfig.image.src = [
          {
            url: generalCategoryConfig.image.url,
            isUploaded: true
          }
        ];
      }

      if (stepConfig.placeAfterWTP) {
        generalCategoryConfig.placeAfterWTP = stepConfig.placeAfterWTP;
      }

      if (stepConfig.multiselect) {
        generalCategoryConfig.multiselect = stepConfig.multiselect;

        if (stepConfig.maxSelected) {
          generalCategoryConfig.maxSelected = stepConfig.maxSelected;
        }
      }
      if (stepConfig.isFork) {
        generalCategoryConfig.isFork = stepConfig.isFork;
      }

      if (stepConfig.skipOptionsShuffle) {
        generalCategoryConfig.skipOptionsShuffle = stepConfig.skipOptionsShuffle;
      }

      return generalCategoryConfig;
    });
  };

  const fetchProject = useCallback(async () => {
    if (!id) {
      i18n.changeLanguage('en');

      return;
    }

    setIsProjectLoading(true);

    try {
      const project = await adminGetOneProject(id);
      i18n.changeLanguage(project.surveyLanguage || 'en');

      const surveyConfig = await getProjectSurveyConfig(id);

      const formValuesSteps = composeCustomStepsFromProjectSurveyConfig(surveyConfig);

      const projectObject = {
        ...project,
        steps: formValuesSteps
      };

      if (isDuplicateMode) {
        projectObject.name = `${project.name}-copy`;
        delete projectObject.hasUserAcknowledgedPayment;
        delete projectObject.stripeInvoiceId;
        delete projectObject.hostedInvoiceUrl;
        delete projectObject.CINTSurveyId;
        delete projectObject.respondentsLimit;

        delete surveyConfig.customWTPTexts;
      }

      if (surveyConfig) {
        setProjectSurveyConfig(surveyConfig);
      }

      if (surveyConfig.customWTPTexts) {
        setIsEnableWTPEdit(surveyConfig.customWTPTexts);
      }

      cachedProjectValuesRef.current = projectObject;
      setFormValues(projectObject);
      form.setFieldsValue(projectObject);

      return;
    } catch (err) {
      alert?.displayErrorAlert(err);
    } finally {
      setIsProjectLoading(false);
    }
  }, [alert, form, i18n, id, isDuplicateMode]);

  useEffect(() => {
    const fetchProfilingQuestions = async () => {
      const countryId = formValues.targetGroup?.geo?.country?.value;

      if (!countryId) {
        return;
      }
      try {
        const result = await getProfilingQuestionsPublic(countryId);

        if (!result?.incomeProfilingQuestion) {
          return;
        }
        const incomeOptions = result.incomeProfilingQuestion.variables?.map(
          ({ id: profilerId, name }) => JSON.stringify({ label: name, value: `${profilerId}` })
        );
        setFormValues((prevState) => {
          const item = {
            ...prevState.item,
            income: incomeOptions
          } as typeof prevState['item'];

          return { ...prevState, item };
        });
      } catch (err) {
        console.log('err', err);
      }
    };

    fetchProfilingQuestions();
  }, [formValues.targetGroup?.geo?.country?.value, setFormValues]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  useEffect(() => {
    const getCompaniesNames = async () => {
      setIsCompaniesLoading(true);

      try {
        const response = await adminGetCompanies(true);

        setCompanies(response);
      } catch (err) {
        alert?.displayErrorAlert(err);
      } finally {
        setIsCompaniesLoading(false);
      }
    };

    getCompaniesNames();
  }, [alert]);

  const fetchCountriesWithRetry = useCallback(async () => {
    const getCountries = async () => {
      const countries = await getCINTCountries();

      setCINTCountries(countries);
    };

    try {
      await callbackWithRetry(getCountries);
    } catch (err) {
      alert?.displayErrorAlert(err);
    }
  }, [alert]);

  useEffect(() => {
    fetchCountriesWithRetry();
  }, [fetchCountriesWithRetry]);

  const handleNextStep = useCallback(async () => {
    try {
      // NOTE: validateFields throws an error if validation fails
      await form.validateFields();

      setStep((prevStep) => prevStep + 1);
    } catch (err) {
      console.debug('err', err);
    }
  }, [form]);

  const handlePreviousStep = () => {
    setStep((prevStep) => {
      if (prevStep > 1) {
        return prevStep - 1;
      }
      return prevStep;
    });
  };

  const onFormValuesChange = (_, allFields: Partial<AdminProjectForm>) => {
    setFormValues((prevState) => {
      const base = cloneDeep(prevState);

      if ('steps' in allFields) {
        const stepsWithFilteredOptions = allFields.steps?.map((customStep) => {
          const filteredOptions = customStep?.options?.filter((answerAlternative) => {
            return !!answerAlternative?.label;
          });

          return { ...customStep, options: filteredOptions };
        });

        base.steps = stepsWithFilteredOptions?.filter((customStep) => !!customStep.title);

        return base;
      }

      if ('sharedWith' in allFields) {
        base.sharedWith = allFields.sharedWith;
      }

      return merge(base, allFields);
    });
  };

  const previewSurveyConfig = useMemo(() => {
    const defaultSurveyConfig = getSurveyConfig();
    const productSurveyConfigSteps = defaultSurveyConfig.product.steps;

    const defaultIntroStep = productSurveyConfigSteps[0];
    const defaultSocialMediaStepIndex = productSurveyConfigSteps.findIndex(
      (configStep) => configStep.step === 'socialMedia'
    );
    const defaultRestSteps = productSurveyConfigSteps.slice(defaultSocialMediaStepIndex);

    const formValueSteps = formValues.steps ?? [];

    const mappedFormValueSteps = formValueSteps.map((formValueSurveyStep, index) => {
      const options = formValueSurveyStep?.options ?? [];
      const mappedOptions = options.map((option, answerAlternativeIndex) => {
        return {
          ...option,
          value: `customField_${answerAlternativeIndex}*`
        };
      });

      return {
        // TODO: Navigate to the custom survey question form step on pencil click
        step: `customStep-${index}`,
        categories: {
          general: {
            ...formValueSurveyStep,
            image: isEmpty(formValueSurveyStep.image?.src) ? undefined : formValueSurveyStep.image,
            options: mappedOptions
          }
        },
        skipOptionsShuffle: formValueSurveyStep.skipOptionsShuffle,
        multiselect: formValueSurveyStep.multiselect,
        maxSelected: formValueSurveyStep.maxSelected,
        isFork: formValueSurveyStep.isFork,
        placeAfterWTP: formValueSurveyStep.placeAfterWTP
      };
    });

    const { regularCustomSteps, postWTPCustomSteps } = mappedFormValueSteps.reduce((accumulator, currentStep) => {
      if (currentStep.placeAfterWTP) {
        accumulator.postWTPCustomSteps.push(currentStep);
      } else {
        accumulator.regularCustomSteps.push(currentStep);
      }

      return accumulator;
    }, { regularCustomSteps: [], postWTPCustomSteps: [] } as
      { regularCustomSteps: typeof mappedFormValueSteps; postWTPCustomSteps: typeof mappedFormValueSteps; });

    const surveyConfigSteps = [defaultIntroStep, ...regularCustomSteps, ...defaultRestSteps, ...postWTPCustomSteps];

    return {
      type: 'product',
      steps: surveyConfigSteps
    } as CategorySurveyConfig;
  }, [formValues.steps]);

  const onCustomStepTitleTranslate = useCallback(async (questionTitle: string) => {
    const titleTranslationResult = await deeplTranslate(questionTitle, i18n.language);

    return titleTranslationResult?.translations[0].text;
  }, [i18n.language]);

  const onCustomStepCustomFieldsTranslate = useCallback(async (
    options: OptionInterface[],
    forceTranslate?: boolean
  ) => {
    const optionsPromises: Promise<OptionInterface>[] = options.map((option: OptionInterface) => {
      return new Promise((resolve) => {
        if (option.value.startsWith('customField') || forceTranslate) {
          if (i18n.language === 'en') {
            if ('translatedLabel' in option) {
              const { translatedLabel, ...restOptionFields } = option;

              resolve({ ...restOptionFields });
              return;
            }

            resolve(option);
            return;
          }

          if (option.skipTranslation) {
            const { translatedLabel, ...restOptionFields } = option;
            resolve(restOptionFields);
            return;
          }

          deeplTranslate(option.label, i18n.language).then((translatedCustomField) => {
            const translatedLabel = translatedCustomField?.translations[0].text;
            resolve({
              ...option,
              translatedLabel: translatedLabel || option.label
            });
          });
        } else {
          resolve(option);
        }
      });
    });

    return Promise.all(optionsPromises);
  }, [i18n.language]);

  const constructProjectSurveyConfig = useCallback(async (projectValues: AdminProjectForm) => {
    // Note: Spell-check is disabled for admin
    // Only run spell-check for english language
    // const shouldSkipSpellCheck = i18n.language !== 'en';

    setIsProjectSurveyConfigLoading(true);

    let defaultSurveyConfig = previewSurveyConfig;

    if (isEnableWTPEdit && projectSurveyConfig) {
      const defaultConfigSteps = defaultSurveyConfig.steps;

      const mergedSteps = defaultConfigSteps.map((stepConfig) => {
        if (WTP_EDIT_STEP_NAMES.includes(stepConfig.step)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const currentProjectSurveyConfigStep = projectSurveyConfig.steps.find(
            (currentStepConfig) => currentStepConfig.step === stepConfig.step
          );

          if (currentProjectSurveyConfigStep) {
            return currentProjectSurveyConfigStep;
          }
        }

        return stepConfig;
      });

      defaultSurveyConfig = {
        ...defaultSurveyConfig,
        steps: mergedSteps
      };
    }


    const config = await getTranslatedProjectConfigSteps({
      project: projectValues,
      t,
      skipSpellcheck: true,
      defaultSurveyConfig,
      translateCustomStepTitle: onCustomStepTitleTranslate,
      translateCustomFields: onCustomStepCustomFieldsTranslate,
      wtpStepNames: WTP_EDIT_STEP_NAMES,
      skipWTPQuestions: isEnableWTPEdit
    });

    if (config) {
      setProjectSurveyConfig(config);
    }

    setIsProjectSurveyConfigLoading(false);

    return config;
  }, [
    isEnableWTPEdit,
    onCustomStepCustomFieldsTranslate,
    onCustomStepTitleTranslate,
    previewSurveyConfig,
    projectSurveyConfig,
    t
  ]);

  const onLanguageChange = useCallback((language: string) => {
    form.setFieldValue('surveyLanguage', language);
  }, [form]);

  const { composeSurveyOnPreviewModalOpen } = useProjectFormTranslations({
    formValues,
    setFormValues,
    constructProjectSurveyConfig,
    i18n,
    onLanguageChange
  });

  const toggleIsPreviewModalOpen = useCallback(() => {
    setIsPreviewModalOpen((prevState) => {
      const isOpen = !prevState;

      if (isOpen) {
        composeSurveyOnPreviewModalOpen(cachedProjectValuesRef, projectSurveyConfig);
      }

      return isOpen;
    });
  }, [composeSurveyOnPreviewModalOpen, projectSurveyConfig]);

  const onPreviewClick = useCallback(async () => {
    try {
      // NOTE: validateFields throws an error if validation fails
      await form.validateFields();

      toggleIsPreviewModalOpen();
    } catch (err) {
      console.debug('err', err);
    }
  }, [form, toggleIsPreviewModalOpen]);

  const handleCreateProjectSurveyConfig = async (projectId: string, config?: CategorySurveyConfig) => {
    if (!config) {
      return;
    }

    try {
      await createProjectSurveyConfig(projectId, config);
    } catch (err) {
      console.debug(err);
    }
  };

  const uploadSurveyImages = useCallback(async (surveyConfig: CategorySurveyConfig) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const imagesConfig = surveyConfig.steps.reduce((accumulator, currentStep) => {
      const isCustomStep = currentStep.step.startsWith('customStep');
      const categoryConfig = currentStep?.categories?.general;
      const hasImage = categoryConfig && 'image' in categoryConfig;
      const image = categoryConfig?.image?.src?.[0];

      if (!isCustomStep || !hasImage || image?.isUploaded || !image?.type) {
        return accumulator;
      }

      const result = {
        step: currentStep.step,
        contentType: image.type
      };
      accumulator.push(result);

      return accumulator;
    }, []);

    if (imagesConfig.length) {
      const signedUrlsConfigs = await adminGetProjectImagesSignedUrls(imagesConfig);
      const uploadImagesPromises = signedUrlsConfigs.map((currentConfig) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const currentCustomStep = surveyConfig.steps.find((stepConfig) => {
          return stepConfig.step === currentConfig.step;
        });

        const file = currentCustomStep?.categories?.general?.image?.src?.[0]?.originFileObj;

        return new Promise((resolve, reject) => {
          uploadFileToS3SignedUrl(currentConfig.preSignedUrl, file).then((imageUrl) => {
            currentCustomStep.categories.general.image.url = imageUrl;
            resolve(true);
          }).catch((err) => {
            console.log('error uploading an image: ', err);
            reject(err);
          });
        });
      });

      await Promise.all(uploadImagesPromises);
    }
  }, []);

  const onSubmit = useCallback(async (project: AdminProjectForm, surveyConfig?: CategorySurveyConfig) => {
    if (!surveyConfig) {
      alert?.error('Couldn\'t compose survey, please try again later');
      return;
    }

    await uploadSurveyImages(surveyConfig);

    if (isEditMode && id) {
      await adminUpdateProject(id, project as Project);
      await updateProjectSurveyConfig(id, surveyConfig);
    } else {
      const createProjectAPI = isPartnerPage ? partnerCreateProject : adminCreateProject;
      const partnerSuggestionProject = {
        ...project,
        ...(isPartnerPage && {
          partner: {
            email: searchParams.get('email') ?? 'UNSPECIFIED',
            company: searchParams.get('company') ?? 'UNSPECIFIED',
          },
        }),
      } as AdminProject;
      const newProject = await createProjectAPI(partnerSuggestionProject);
      await handleCreateProjectSurveyConfig(newProject._id, surveyConfig);
    }


    setIsPreviewModalOpen(false);

    if (isPartnerPage) {
      setStep(1);
      setFormValues(initialFormValues);
      form.resetFields();

      return;
    }
    navigate('/admin/projects/customer');
  }, [alert, form, id, isEditMode, isPartnerPage, navigate, uploadSurveyImages, searchParams]);

  const handleCreateProjectSubmit = useCallback(async () => {
    const projectObject = {
      ...formValues,
      status: 'active' as const,
    };

    try {
      let config = projectSurveyConfig;
      if (config && isEnableWTPEdit) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config = { ...projectSurveyConfig, customWTPTexts: isEnableWTPEdit };
      }

      await onSubmit(projectObject, config);

      alert?.success('The project has been successfully launched 🎉', 5);
    } catch (err) {
      console.log('err', err);
      alert?.displayErrorAlert(err);
    }
  }, [alert, formValues, isEnableWTPEdit, onSubmit, projectSurveyConfig]);

  const handleDraftSubmit = useCallback(async (isOutsidePreviewModal?: boolean) => {
    const projectObject = {
      ...formValues,
      status: 'draft' as const
    };

    setIsSavingDraft(true);

    try {
      let config = projectSurveyConfig;
      if (isOutsidePreviewModal) {
        const surveyConfig = await composeSurveyOnPreviewModalOpen(cachedProjectValuesRef, projectSurveyConfig);

        if (surveyConfig) {
          config = surveyConfig;
        }
      }

      if (config && isEnableWTPEdit) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        config = { ...config, customWTPTexts: isEnableWTPEdit };
      }
      await onSubmit(projectObject, config);

      alert?.success('The project has been saved as draft!');
    } catch (err) {
      console.log('err', err);
      alert?.displayErrorAlert(err);
    } finally {
      setIsSavingDraft(false);
    }
  }, [alert, composeSurveyOnPreviewModalOpen, formValues, isEnableWTPEdit, onSubmit, projectSurveyConfig]);

  const handleDraftSubmitOutsidePreviewModal = useCallback(() => {
    return handleDraftSubmit(true);
  }, [handleDraftSubmit]);

  const isPreviewDisabled = useMemo(
    () => !validateAddProjectForm(formValues as Project, {}),
    [formValues],
  );

  const mainActionButton = useMemo(() => {
    let nextStepButton: JSX.Element = <></>;
    let previewButton: JSX.Element = <></>;

    if (!isLastStep) {
      nextStepButton = (
        <Button
          key="Next step"
          variant={isPreviewDisabled ? 'primary' : 'tertiary'}
          type={isPreviewDisabled ? 'primary' : 'default'}
          onClick={handleNextStep}
          disabled={isProjectLoading || isSavingDraft}
        >
          Next step
        </Button>
      );
    }

    if (isLastStep || !isPreviewDisabled) {
      previewButton = (
        <Button
          type="primary"
          onClick={onPreviewClick}
        >
          Preview
        </Button>
      );
    }

    const saveAsDraftButton = !isPartnerPage ? (
      <Button
        variant="secondary"
        htmlType="button"
        style={{ minWidth: 110 }}
        onClick={handleDraftSubmitOutsidePreviewModal}
        disabled={isProjectLoading}
        loading={isSavingDraft}
      >
        Save as draft
      </Button>
    ) : null;

    return (
      <Space style={{ display: 'flex', justifyContent: 'end', columnGap: '48px' }}>
        {saveAsDraftButton}
        {nextStepButton}
        {previewButton}
      </Space>
    );
  }, [
    isPreviewDisabled,
    handleDraftSubmitOutsidePreviewModal,
    handleNextStep,
    isLastStep,
    isProjectLoading,
    isSavingDraft,
    onPreviewClick,
    isPartnerPage,
  ]);

  const companiesToShareWith = useMemo(() => {
    if (!formValues?.companyId) {
      return companies;
    }

    return companies?.filter((company) => company._id !== formValues.companyId);
  }, [companies, formValues.companyId]);

  const getProjectSurveyConfigForEditWTP = useCallback(() => {
    const wtpStepWithIntro = 'price.low';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return projectSurveyConfig?.steps?.reduce((acc, currentStep) => {
      const stepName = currentStep.step;
      if (WTP_EDIT_STEP_NAMES.includes(stepName)) {
        const textContent = currentStep.body ?? currentStep.categories?.general?.body;

        acc[stepName] = textContent.join('\n');

        if (stepName === wtpStepWithIntro) {
          const wtpIntroStepName = 'price.low.intro';
          acc[wtpIntroStepName] = currentStep?.intro;
        }
      }
      return acc;
    }, {});
  }, [projectSurveyConfig?.steps]);

  const onEditWTPContentModalClick = useCallback(() => {
    const contentToEdit = getProjectSurveyConfigForEditWTP();

    setWTPContentToEdit(contentToEdit);

    setIsEditWTPContentModalOpen(true);
  }, [getProjectSurveyConfigForEditWTP]);

  const onEditWTPContentSubmit = useCallback((values: WTPContentToEditType) => {
    setProjectSurveyConfig((prevState) => {
      if (!prevState) {
        return prevState;
      }

      return combineWTPEditsWithProjectSurveyConfig(values, prevState);
    });

    setWTPContentToEdit(undefined);
    setIsEditWTPContentModalOpen(false);
  }, []);

  const descriptionStepFormConfig = useMemo(() => {
    const projectFormConfig = getProjectFormConfig({ schemaVersion: 2 });
    const productConfig = projectFormConfig.product;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const descriptionConfig = productConfig.steps.find((stepConfig) => stepConfig.step === 'description');
    if (!descriptionConfig) {
      return {};
    }

    const {
      name,
      description,
      price,
      quantity,
    } = descriptionConfig.fields;

    return {
      name,
      description,
      price: {
        ...price,
        paymentModels: ADMIN_PAYMENT_MODELS
      },
      productQuantity: quantity,
    };
  }, []);

  const resetRegionStateHandler = useMemo(() => resetRegionState.bind(null, setFormValues), []);

  return (
    <Card
      actions={[
        <Button
          key="Previous step"
          variant="secondary"
          onClick={handlePreviousStep}
          disabled={step === 1}
        >
          Previous step
        </Button>,
        mainActionButton
      ]}
    >
      {isProjectLoading ? <Spinner /> : null}

      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        onValuesChange={onFormValuesChange}
        autoComplete="off"
        preserve
        disabled={isProjectLoading || isSavingDraft}
      >
        {step === 1 ? (
          <>
            <Form.Item
              label="Survey name"
              style={{
                width: '100%',
                marginBottom: 40
              }}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the survey name'
                }
              ]}
            >
              <Input placeholder="Enter survey name" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label="Client company"
              style={{
                width: '100%',
                marginBottom: 40
              }}
              name="companyId"
              rules={[
                {
                  required: true,
                  message: 'Please select the company'
                }
              ]}
            >
              <Select
                placeholder="Client"
                fieldNames={{
                  label: 'name',
                  value: '_id'
                }}
                loading={isCompaniesLoading}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                options={companies}
                showSearch
                filterOption={
                  (input, option) => {
                    if (typeof option?.name !== 'string') {
                      return false;
                    }
                    return option?.name.toLowerCase()
                      .includes(input.toLowerCase());
                  }
                }
              />
            </Form.Item>

            {
              !isPartnerPage && (
                <>
                  <Divider />

                  <Form.Item
                    name="sharedWith"
                    label="Share the survey findings with other companies"
                    style={{
                      width: '100%',
                      marginBottom: 40,
                    }}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Share with..."
                      fieldNames={{
                        label: 'name',
                        value: '_id',
                      }}
                      selectorMaxWidth="100%"
                      loading={isCompaniesLoading}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      options={companiesToShareWith}
                      allowClear
                      showSearch
                      filterOption={
                        (input, option) => {
                          if (typeof option?.name !== 'string') {
                            return false;
                          }
                          return option?.name.toLowerCase()
                            .includes(input.toLowerCase());
                        }
                      }
                    />
                  </Form.Item>
                </>
              )
            }
          </>
        ) : null}

        {step === 2 ? (
          <>
            <DescriptionStepFormItem
              cardTitle="Create A Project"
              {...descriptionStepFormConfig}
              displayMaxPrice
              isQuantityVisible
              // schemaVersion={2}
            />

            {!isPartnerPage ? (
              <AntDInput.Group>
                <Row gutter={100}>
                  <Col flex="50%">
                    <FormItem
                      label="(Optional) WTP price Prefix"
                      largeLabel
                      name={['item', 'price', 'wtpPrefix']}
                      tooltip={{
                        title: 'This will change the text that respondents see while answering WTP questions',
                        arrow: true,
                        placement: 'left',
                        overlayInnerStyle: { padding: '2px 16px' }
                      }}
                    >
                      <Input placeholder="Enter WTP price Prefix" />
                    </FormItem>
                  </Col>

                  <Col flex="50%">
                    <FormItem
                      label="(Optional) WTP price Postfix"
                      largeLabel
                      name={['item', 'price', 'wtpPostfix']}
                      tooltip={{
                        title: 'This will change the text that respondents see while answering WTP questions',
                        arrow: true,
                        placement: 'left',
                        overlayInnerStyle: { padding: '2px 16px' }
                      }}
                    >
                      <Input placeholder="Enter WTP price Postfix" />
                    </FormItem>
                  </Col>
                </Row>
              </AntDInput.Group>
            ) : null}
          </>
        ) : null}

        {
          step === 3 ? (
            <DynamicCustomProjectStepsForm initialValue={formValues.steps} />
          ) : null
        }

        {
          step === 4 ? (
            <>
              <FormItem
                label="What regions are you targeting"
                tooltip={{
                  title: 'Respondents will select out of the pool of regions',
                  arrow: true,
                  placement: 'left',
                  overlayInnerStyle: { padding: '2px 16px' }
                }}
                largeLabel
              >
                <TargetGroupCountryRegionsSelector
                  countries={CINTCountries}
                  resetRegionState={resetRegionStateHandler}
                  buttonText="Region targeting"
                  isRegionTypeRequired
                />

                <FormItem
                  label="Amount of Respondents"
                  largeLabel
                  name="respondentsLimit"
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      min: 1,
                      message: 'Please enter amount of respondents!'
                    }
                  ]}
                  errorMessageJustify="start"
                >
                  <InputNumber
                    placeholder="Respondents count"
                    type="number"
                    style={{ minWidth: 200 }}
                    step={1}
                    min={1}
                  />
                </FormItem>

                <FormItem
                  label="What language are you targeting"
                  largeLabel
                  name="surveyLanguage"
                  tooltip={{
                    title: 'What language are you targeting',
                    arrow: true,
                    placement: 'left',
                    overlayInnerStyle: { padding: '2px 16px' }
                  }}
                  initialValue="en"
                >
                  <TargetGroupLanguageSelect i18n={i18n} t={t} />
                </FormItem>
              </FormItem>
            </>
          ) : null
        }
      </Form>

      <PreviewProjectSurveyModal
        open={isPreviewModalOpen}
        handleClose={toggleIsPreviewModalOpen}
        handleSubmit={handleCreateProjectSubmit}
        handleDraft={handleDraftSubmit}
        isPartnerPage={isPartnerPage}
      >
        {isProjectSurveyConfigLoading ? (
          <Spinner />
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 12
                }}
              >
                <Typography.Text>Own WTP and intro?</Typography.Text>
                <Switch
                  checked={isEnableWTPEdit}
                  onChange={(checked) => {
                    setIsEnableWTPEdit(checked);
                    if (!checked) {
                      // TODO: Alert about edited content removal
                      setWTPContentToEdit(undefined);
                    }
                  }}
                />
              </div>

              <Button
                variant="secondary"
                disabled={!isEnableWTPEdit}
                onClick={onEditWTPContentModalClick}
              >
                Edit WTP
              </Button>
            </div>
            <RenderProjectSurveyPreview
              project={formValues as Project}
              projectSurveyConfig={projectSurveyConfig ?? previewSurveyConfig}
              isUseProjectSurveyConfigSteps
              isAdminPanel
            />
          </>
        )}
      </PreviewProjectSurveyModal>

      {WTPContentToEdit ? (
        <EditWTPContentModal
          WTPContentToEdit={WTPContentToEdit}
          open={isEditWTPContentModalOpen}
          handleSubmit={onEditWTPContentSubmit}
          handleClose={() => {
            setIsEditWTPContentModalOpen(false);
            setWTPContentToEdit(undefined);
          }}
        />
      ) : null}
    </Card>
  );
};

export default AdminCreateCustomerProject;
