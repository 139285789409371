import { Project } from '../../../../types/project';
import { validateArrayField, validateSimpleField } from './formFieldsValidators';
import { GolfProjectTypeStepConfigType } from '../../../../constants/customProjectConfigs/golfProjectFormConfig';
import { ProjectTypeStepConfigType } from '../../../../constants/projectFormConfig';
import { ProjectV2TypeStepConfigType } from '../../../../constants/projectFormConfig_v2';


type ProjectFormStepNameType = (
  | ProjectTypeStepConfigType
  | ProjectV2TypeStepConfigType
  | GolfProjectTypeStepConfigType
)['step'];

interface ProjectFormValuesValidationRules {
  [value: ProjectFormStepNameType]: {
    min?: number;
    max?: number;
  };
}

export const validateDescriptionStepValues = (item?: Project['item']) => {
  if (!item) {
    return false;
  }

  const isItemNameValid = validateSimpleField(item, 'name', true);
  const isItemDescriptionValid = validateSimpleField(item, 'description');
  const isItemPriceAmountValid = validateSimpleField(item?.price, 'amount');
  const isItemPriceCurrencyValid = validateSimpleField(item?.price, 'currency');
  const isItemPricePaymentModelValid = validateSimpleField(item?.price, 'paymentModel');

  return (
    isItemNameValid
    && isItemDescriptionValid
    && isItemPriceAmountValid
    && isItemPriceCurrencyValid
    && isItemPricePaymentModelValid
  );
};

export const validateTargetGroup = (targetGroup?: Project['targetGroup']) => {
  if (!targetGroup) {
    return false;
  }
  const { age, geo, gender } = targetGroup;

  const minAge = age?.min;
  const maxAge = age?.max;
  const isMinAgeValid = minAge && minAge >= 18;
  const isMaxAgeValid = maxAge && maxAge <= 99;
  const isAgeRangeValid = minAge && maxAge && minAge < maxAge;

  const isAgeFieldValid = age && isMinAgeValid && isMaxAgeValid && isAgeRangeValid;

  const isGeoCountryValid = !!geo?.country;

  return !!gender && isAgeFieldValid && isGeoCountryValid;
};

export const validateCustomerSurveyTargetGroup = (
  targetGroup?: Project['targetGroup'],
  respondentsLimit?: Project['respondentsLimit'],
) => Boolean(
  targetGroup?.geo?.country
  && targetGroup.geo.regionType
  && respondentsLimit
);

export const validateAddProjectForm = (formValues: Project, validationRules?: ProjectFormValuesValidationRules) => {
  const { schemaVersion } = formValues;

  const isNameValid = validateSimpleField(formValues, 'name');
  const isTypeValid = validateSimpleField(formValues, 'type');

  let isAimValid = validateSimpleField(formValues, 'aim');
  if (schemaVersion === 2) {
    isAimValid = true;
  }

  const { item } = formValues;

  const isDescriptionValid = validateDescriptionStepValues(item);

  // const isIndustryValid = validateArrayField(item, 'industry', 2);
  const isCategoryValid = validateSimpleField(item, 'category');

  const isTargetGroupValid = formValues.customRespondentsPool
    ? validateCustomerSurveyTargetGroup(formValues?.targetGroup, formValues.respondentsLimit)
    : validateTargetGroup(formValues?.targetGroup);

  if (validationRules) {
    const validationEntries = Object.entries(validationRules);
    const fieldsValidationResult = validationEntries.map(([stepName, rules]) => {
      let min = 2;
      let max = 4;

      if ('min' in rules && typeof rules.min === 'number') {
        min = rules.min;
      }
      if ('max' in rules && typeof rules.max === 'number') {
        max = rules.max;
      }

      return validateArrayField(
        item,
        stepName as keyof typeof item,
        min,
        max
      );
    });

    const areFieldsValid = fieldsValidationResult.every(Boolean);

    return isNameValid
      && isTypeValid
      && isAimValid
      && isDescriptionValid
      // && isIndustryValid
      && isCategoryValid
      && isTargetGroupValid
      && areFieldsValid;
  }

  const isFeaturesValid = validateArrayField(item, 'features', 2, 4);
  const isUniqueFeaturesValid = validateArrayField(item, 'uniqueFeatures', 2, 4);
  const isDisadvantagesValid = validateArrayField(item, 'disadvantages', 2, 4);
  const isRetailLocationsValid = validateArrayField(item, 'retailLocations', 2, 4);

  let isRetailPeriodsValid = true;
  if (item?.retailPeriods && item?.retailPeriods.length) {
    isRetailPeriodsValid = validateArrayField(item, 'retailPeriods', 2, 4);
  }

  let isCompetitorsValid = true;
  if (item?.competitors && item?.competitors.length) {
    isCompetitorsValid = validateArrayField(item, 'competitors', 2, 4);
  }
  const isUsageValid = validateArrayField(item, 'usage', 2, 4);

  return (
    isNameValid
    && isTypeValid
    && isAimValid
    && isDescriptionValid
    // && isIndustryValid
    && isCategoryValid
    && isFeaturesValid
    && isUniqueFeaturesValid
    && isDisadvantagesValid
    && isRetailLocationsValid
    && isRetailPeriodsValid
    && isCompetitorsValid
    && isUsageValid
    && isTargetGroupValid
  );
};
