import type { FC } from 'react';
import { ButtonProps } from '../Button/Button.types';
import { StyledIconButton } from './IconButton.styles';


type IconButtonProps = ButtonProps;

export const IconButton: FC<IconButtonProps> = (props) => (
  <StyledIconButton {...props} />
);
