import axios from 'axios';
import { CINTCountry, ProfilingQuestion } from '../types/cint';
import { Project } from '../types/project';


export const getCINTCountries = async (): Promise<CINTCountry[]> => {
  const url = '/cint/countries';

  return axios.get(url);
};

export const getCINTSurveyFeasibilityEstimate = async (project: Project):
Promise<{ feasibility: number }> => {
  const url = '/cint/survey/feasibility-estimate';

  return axios.post(url, project);
};

export const adminGetCINTSurveyFeasibilityEstimate = async (project: Project):
Promise<{ feasibility: number }> => {
  const url = '/admin/cint/survey/feasibility-estimate';

  return axios.post(url, project);
};

export const adminGetProfessionalRoleProfilingQuestion = async (countryId: number):
Promise<{ professionalRoleQuestion: ProfilingQuestion; industrySectorQuestion: ProfilingQuestion }> => {
  const url = '/admin/cint/profiling-questions';

  const params = {
    countryId
  };

  return axios.get(url, { params });
};

export const getProfilingQuestionsPublic = async (countryId: number): Promise<{
  incomeProfilingQuestion: ProfilingQuestion
}> => {
  const url = '/cint/profiling-questions/public';

  const params = { countryId };

  return axios.get(url, { params });
};

