import type { FC } from 'react';
import { ProjectModeSelectorBase } from './ProjectModeSelectorBase';
import type { ProjectWizardDefinition } from './types';
import { useProjectModes } from './hooks/useProjectModes';
import { ProjectType } from '../../../enums/project-type';


export interface ProjectModeSelectProps {
  onModeSelect: (mode: Partial<ProjectWizardDefinition>) => void;
}

export const ProjectModeSelector: Record<Capitalize<ProjectType>, FC<ProjectModeSelectProps>> = {
  User: ({ onModeSelect }: ProjectModeSelectProps) => {
    const modes = useProjectModes(ProjectType.User, onModeSelect);

    return (
      <ProjectModeSelectorBase modes={modes} />
    );
  },
  Admin: ({ onModeSelect }: ProjectModeSelectProps) => {
    const modes = useProjectModes(ProjectType.Admin, onModeSelect);

    return (
      <ProjectModeSelectorBase modes={modes} />
    );
  }
};
