// spell-checking library
import axios, { AxiosRequestHeaders } from 'axios';


const externalApiAxios = axios.create();

interface TextGearsCorrectResponse {
  status: boolean;
  response: {
    corrected: string;
  }
}
export const textGearsSpellCheckWithAutoCorrect = async (text: string, language = 'en-US')
: Promise<TextGearsCorrectResponse | undefined> => {
  const url = 'https://api.textgears.com/correct';

  const requestConfig = {
    params: {
      text,
      language,
      key: process.env.REACT_APP_TEXT_GEARS_API_KEY,
    },
    transformRequest: (data: unknown, headers: AxiosRequestHeaders) => {
      if ('Authorization' in headers) {
        // eslint-disable-next-line no-param-reassign
        delete headers.Authorization;
      }

      return data;
    }
  };

  try {
    const { data } = await externalApiAxios.get(url, requestConfig);

    return data;
  } catch (err) {
    console.log('err', err);
    return;
  }
};
