import type { FC } from 'react';
import { FormItem } from '../../../../molecules/FormItem';


interface ConfirmCategoryStepFormItemProps {
  label?: string;
  subTitle?: string;
}

export const ConfirmCategoryStepFormItem: FC<ConfirmCategoryStepFormItemProps> = ({
  label,
  subTitle
}) => (
  <FormItem
    label={label}
    tooltip={{
      title: label,
      arrow: true,
      placement: 'left',
    }}
    subTitle={subTitle}
    largeLabel
    hideInputControl
  />
);
