import type { FC } from 'react';
import { FormItemProps, Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useMemo } from 'react';
import { CheckboxGroupWithCustomFieldsFormField } from '../../../CheckboxGroupWithCustomFieldsFormField';


interface RetailLocationsStepFormItemProps extends FormItemProps {
  cardTitle: string;
  label?: string;
  subTitle?: string;
  tooltip: string;
  placeholders?: string[];
  options?: { label: string; value: string }[];
  selectedValues?: string[];
}

export const RetailLocationsStepFormItem: FC<RetailLocationsStepFormItemProps> = ({
  cardTitle,
  label,
  subTitle,
  tooltip,
  placeholders,
  options,
  selectedValues,
  ...rest
}) => {
  const { Title } = Typography;
  const renderMarkdown = useMemo(
    () => <ReactMarkdown rehypePlugins={[rehypeRaw]}>{tooltip}</ReactMarkdown>,
    [tooltip]
  );

  return (
    <>
      <Title level={3}>{cardTitle}</Title>
      <CheckboxGroupWithCustomFieldsFormField
        name={['item', 'retailLocations']}
        label={label}
        options={options}
        selectedValues={selectedValues}
        subTitle={subTitle}
        tooltip={{
          title: renderMarkdown,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        rules={[{
          required: true,
          type: 'array',
          min: 2,
          max: 4,
          message: 'Please select from 2 to 4 locations!',
        }]}
        largeLabel
        customFieldsCount={4}
        errorMessageJustify="start"
        placeholders={placeholders}
        {...rest}
      />
    </>
  );
};
