/* eslint-disable max-len */
export interface Industry {
  value: string;
  label: string;
  id?: number;
}

export const INDUSTRIES: Industry[] = [
  { value: 'home', label: 'Home', id: 9 },
  { value: 'pets', label: 'Pets', id: 12 },
  { value: 'healthcare', label: 'Healthcare', id: 48 },
  { value: 'sport_and_recreation', label: 'Sports, Recreation', id: 19 },
  { value: 'fashion_clothing', label: 'Fashion, Clothing', id: 29 },
  { value: 'health_nutrition', label: 'Health, Nutrition', id: 14 },
  { value: 'travel_hospitality_and_tourism', label: 'Travel, Hospitality, Tourism', id: 16 },
  { value: 'entertainment', label: 'Entertainment', id: 36 },
  { value: 'news_media_publishing', label: 'News, Media, Publishing', id: 18 },
  { value: 'internet_ecommerce', label: 'Internet, E-commerce', id: 25 },
  { value: 'shopping_and_retail', label: 'Shopping, Retail', id: 41 },
  { value: 'food', label: 'Food, Groceries', id: 1 },
];

export const productConfig = {
  type: 'product',
  steps: [
    {
      step: 'aim',
      title: 'What is the aim of your project?',
      options: [
        { label: 'Launching a new product to the market', value: 'launch_new_product' },
        {
          label: 'Find out if I should change from a once per unit payment to a subscription model',
          value: 'change_payment_model',
        },
        { label: 'Launching my product in a new market', value: 'launch_in_new_market' },
        { label: 'Adjust my current prices', value: 'adjust_price' },
      ],
    },
    {
      step: 'description',
      cardTitle: 'Tell us about your product',
      fields: {
        name: {
          title: 'What kind product are you selling?',
          subTitle: 'Use the generic name using articles a/an or in plural - no brand name. \n\n *By default your first letter will be forced in lower case - this is to ensure that the survey looks good*.',
          placeholder: 'For example: “running shoes”, “a vegan leather bag” or “a laundry machine”',
          tooltip: `<div>
                        <p>Make sure your product type is accurately described.</p>
                        <ul style="padding-left: 15px">
                          <li>Do <b>not</b> use any brand terms in the 'product type' input field.</li>
                          <li>Try and be a bit more descriptive, don't say 'shoes' but rather 'running shoes'</li>
                          <li>Make sure you use: <b>plural /a /an</b></li>
                        </ul>
                    </div>`,
        },
        description: {
          title: 'Describe what’s unique with your product',
          subTitle: 'Using 2 sentences, no brand name',
          placeholder: 'For example: “a unique design of a luxury watch. The watch is coated in diamonds and is gold plated.”',
          tooltip: `<div>
                      <p>In this field, we would like you to describe your product. A maximum of two sentences can be used in 
                      describing your product, and these should capture its uniqueness.</p> 
                      <p><b>Don't use</b> possessive language such as mine or our. Instead use terms like this or these.</p>
                    </div>`,
        },
        quantity: {
          title: '(Optional) What is the quantity of your product?',
          tooltip: "This is not relevant for all products. But if you want your potential customers to know the exact quantity of your product this will give better results. For example, 3 ounces of perfume would have a different price than 4.2 ounces, so it's sometimes important your customers know how much they are asked to pay. (But if you sell sneakers this is of course not applicable to you and you can ignore this question and move on)"
        },
        price: {
          title: 'What is the intended price of the product?',
          paymentModels: [
            { label: 'Per unit sold', value: 'per_unit_sold' },
            { label: 'Per Month', value: 'per_month' },
            { label: 'Per Year', value: 'per_year' },
          ],
          tooltip: "Here you should fill out the current pricing model, if you don't have a current pricing model you fill out your intended pricing model.",
        },
        usageFrequency: {
          title: 'Find out how often people would use your service or product:',
          tooltip: 'With this field activated you will get insights on how people often your prospective customers use you product or service. This question is optional as it isn’t suitable for everyone. We suggest you toggle it on and review how it looks in the preview section. If it doesn\'t look right you can always edit it off from there.',
        },
      },
    },
    // {
    //   step: 'industry',
    //   cardTitle: 'Tell us about your product',
    //   title: 'What industry is your product related to?',
    //   subTitle: 'Select at least 2, the more you select the better',
    //   options: INDUSTRIES,
    // },
    {
      step: 'category',
      cardTitle: 'Tell us about your product',
      title: 'Which option would best describe your product?',
      options: [
        {
          label: 'A product that most of the people will not afford to buy (with high design and brand identifications e.g. Rolex watches). This is considered *a premium/luxury product*.',
          value: 'premium',
        },
        {
          label: 'A product that has a long lifespan of at least 3 years and that you use often (e.g. refrigerators, computers and other electronics, sport accessory). This is considered *a durable product*.',
          value: 'durable',
        },
        {
          label: 'A product that you use for special occasions and not many people are familiar with this (e.g. vegan leather handbag, shoes for nurses). This is considered *a niche product*.',
          value: 'niche',
        },
        {
          label: 'A product that you buy often and you use it in regular basis (e.g. regular shoes, clothes). This is considered *a convenience product*.',
          value: 'convenience',
        },
        {
          label: 'A product that you would eat. This is considered *food*.',
          value: 'food',
        },
      ],
    },
    {
      step: 'confirmCategory',
      categories: {
        premium: {
          title: 'Is it correct that your product can be considered a premium product?',
          subTitle: 'This means your are not targeting a large section of the population. But rather a smaller high-income group.',
        },
        durable: {
          title: 'Is it correct that your product can be considered a durable product?',
          subTitle: 'This means your product has a long life span, which is a good that does not quickly wear out or, more specifically, one that yields utility over time rather than being completely consumed in one use',
        },
        niche: {
          title: 'Is it correct that your product can be considered a niche product?',
          subTitle: 'This means your product is not targeting a large market share, but rather a smaller carefully selected part of the market.The product features aimed at satisfying specific market needs.',
        },
        convenience: {
          title: 'Is it correct that your product can be considered a convenience product?',
          subTitle: 'This means your product has a short life span, convenience products are goods consumers buy frequently and with little effort in their decision-making. This includes food, magazines, tissues and much more.',
        },
        food: {
          title: 'Is it correct that your product can be considered a food?',
          subTitle: 'This includes all variants of foods, but not drinks',
        },
      },
    },
    {
      step: 'features',
      cardTitle: 'Tell us about your product',
      categories: {
        premium: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your product:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It’s handcrafted or made using artisanal methods', value: 'handcrafted' },
            { label: 'It’s from a well trusted brand', value: 'trusted_brand' },
            { label: 'It’s exclusive', value: 'exclusive' },
            {
              label: 'It’s only available to people of a certain status',
              value: 'for_people_of_certain_status'
            },
            { label: 'It’s gold coated', value: 'gold_coated' },
            { label: 'It’s diamond encrusted', value: 'diamond_encrusted' },
            { label: 'It’s genuine leather', value: 'genuine_leather' },
            { label: 'It’s made in North America', value: 'made_in_north_america' },
            { label: 'It’s made in Europe', value: 'made_in_europe' },
            { label: 'It has a strong brand recognition', value: 'strong_brand_recognition' },
            { label: 'It has a high end design', value: 'high_end_design' },
            { label: 'It has a high quality feel', value: 'high_quality' },
            { label: 'It’s sustainably produced', value: 'sustainably_produced' },
            { label: 'It has an excellent functionality', value: 'excellent_functionality' },
          ],
        },
        durable: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your product:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It’s unbreakable', value: 'unbreakable' },
            { label: 'It has a long battery life', value: 'long_battery_life' },
            { label: 'It won’t rot', value: 'no_rot' },
            { label: 'It’s comfortable', value: 'comfortable' },
            { label: 'It’s sustainably sourced', value: 'sustainably_sourced' },
            { label: 'It’s from a well known brand', value: 'known_brand' },
            { label: 'It has a superior customer experience', value: 'customer_experience' },
            { label: 'It’s made in North America', value: 'made_in_north_america' },
            { label: 'It’s made in Europe', value: 'made_in_europe' },
            { label: 'It has a lifespan of more than 5 years', value: 'lifespan_of_more_than_5_years' },
            { label: 'It weighs less than 10 pounds', value: 'weighs_less_than_10_pounds' },
            { label: 'It has a high quality feel', value: 'high_quality' },
            { label: 'It’s sustainably produced', value: 'sustainably_produced' },
            { label: 'It has an excellent functionality', value: 'excellent_functionality' },
          ],
        },
        niche: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your product:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It has a distinctive design', value: 'distinctive_design' },
            { label: 'It has a unique design', value: 'unique_design' },
            { label: 'It’s not mass produced', value: 'not_mass_produced' },
            { label: 'It’s made with hemp', value: 'made_with_hemp' },
            { label: 'It’s sustainably sourced', value: 'sustainably_sourced' },
            { label: 'It’s organic', value: 'organic' },
            { label: 'It’s made to be used in the wilderness', value: 'used_in_wilderness' },
            { label: 'It’s made in North America', value: 'made_in_north_america' },
            { label: 'It’s vegan', value: 'vegan' },
            { label: 'It has a pleasant smell', value: 'pleasant_smell' },
            { label: 'It’s hard to come by', value: 'hard_to_come_by' },
            { label: 'It’s custom-made', value: 'custom_made' },
            { label: 'It’s handmade', value: 'hand_made' },
          ],
        },
        convenience: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your product:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It’s double layered', value: 'double_layered' },
            { label: 'It’s from a well known brand', value: 'known_brand' },
            { label: 'It’s comfortable', value: 'comfortable' },
            { label: 'It’s sustainably sourced', value: 'sustainably_sourced' },
            { label: 'It’s organic', value: 'organic' },
            { label: 'It has a superior customer experience', value: 'customer_experience' },
            { label: 'It’s made in North America', value: 'made_in_north_america' },
            { label: 'It’s vegan', value: 'vegan' },
            { label: 'It has a pleasant smell', value: 'pleasant_smell' },
            { label: 'It is easily accessible ', value: 'accessible' },
            { label: 'It contains a high level of fluoride ', value: 'high_level_of_fluoride' },
            { label: 'It’s healthy', value: 'healthy' },
            { label: 'It’s easy to read', value: 'easy_to_read' },
          ],
        },
        food: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your food:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It’s from a well known brand', value: 'known_brand' },
            { label: 'It’s tasty', value: 'tasty' },
            { label: 'It’s ethically sourced meat', value: 'ethically_sourced_meat' },
            { label: 'It’s no-sugar', value: 'no_sugar' },
            { label: 'It’s sustainably sourced', value: 'sustainably_sourced' },
            { label: 'It’s organic', value: 'organic' },
            { label: 'It’s produced in North America', value: 'produced_in_north_america' },
            { label: 'It’s vegan', value: 'vegan' },
            { label: 'It has a pleasant smell', value: 'pleasant_smell' },
            { label: 'It has a low sodium level', value: 'low_sodium_level' },
            { label: 'It’s gluten-free', value: 'gluten_free' },
            { label: 'It’s healthy', value: 'healthy' },
            { label: 'It’s non-processed', value: 'non_processed' },
          ],
        },
      },
      tooltip: `<div>
                  <p>The attributes you choose here will be put in contrast towards each other. That means you can
                    select "it's blue" and another value "it's green", in the result you would get insights on which
                    one of the alternatives your customer are willing to pay more for</p>
                  <p>This means that you can learn what features and/or attributes truly drives the value of your
                    product. The insights are especially useful in product development and marketing purposes.</p>
                </div>`,
      placeholders: ["It's ...", 'It has ...', 'It is made of ...', "It's ..."],
    },
    {
      step: 'uniqueFeatures',
      cardTitle: 'Tell us about your company',
      categories: {
        premium: {
          title: 'In the spaces below, select 2-4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Strong brand ambassadors', value: 'strong_brand_ambassadors' },
            { label: 'Great reputation', value: 'great_reputation' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'The brand has a strong luxury feel', value: 'brand_strong_luxury_feel' },
            { label: 'The company has a high status', value: 'company_high_status' },
            { label: 'The company conveys prestige', value: 'company_conveys_prestige' },
            { label: 'The brand signals superiority', value: 'brand_signals_superiority' },
            { label: 'The brand is authentic', value: 'brand_authentic' },
            { label: 'Buying from this brand is an investment', value: 'buying_investment' },
            { label: 'There is a strong loyalty to the brand', value: 'brand_strong_loyalty' },
            {
              label: 'It’s a great experience buying from the brand',
              value: 'brand_great_experience_buying'
            },
          ],
        },
        durable: {
          title: 'In the spaces below, select 2-4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'The company has a great reputation', value: 'company_great_reputation' },
            { label: 'It’s a strong brand', value: 'strong_brand' },
            { label: 'Great customer service', value: 'great_customer_service' },
            { label: 'Strong brand loyalty', value: 'strong_brand_loyalty' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'Environmentally sustainable company', value: 'environmentally_sustainable_company' },
            { label: 'The company is famous for their sleek designs', value: 'famous_sleek_designs' },
            { label: 'Prominent brand ambassadors', value: 'prominent_brand_ambassadors' },
            { label: 'Strong brand presence', value: 'strong_brand_presence' },
            { label: 'Brand is known for a superior quality', value: 'superior_quality' },
          ],
        },
        niche: {
          title: 'In the spaces below, select 2-4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Strong brand ambassadors', value: 'strong_brand_ambassadors' },
            { label: 'Great reputation', value: 'great_reputation' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'The brand has a strong luxury feel', value: 'brand_strong_luxury_feel' },
            { label: 'The company has a high status', value: 'company_high_status' },
            { label: 'The company conveys prestige', value: 'company_conveys_prestige' },
            { label: 'The brand signals superiority', value: 'brand_signals_superiority' },
            { label: 'The brand is authentic', value: 'brand_authentic' },
            { label: 'Buying from this brand is an investment', value: 'buying_investment' },
            { label: 'There is a strong loyalty to the brand', value: 'brand_strong_loyalty' },
            {
              label: 'It’s a great experience buying from the brand',
              value: 'brand_great_experience_buying'
            },
            {
              label: 'The brand is not well-known to the general public',
              value: 'brand_not_well_known_to_general_public',
            },
          ],
        },
        convenience: {
          title: 'In the spaces below, select 2-4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'The company has a great reputation', value: 'company_great_reputation' },
            { label: 'It’s a strong brand', value: 'strong_brand' },
            { label: 'Great customer service', value: 'great_customer_service' },
            { label: 'Strong brand loyalty', value: 'strong_brand_loyalty' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'Environmentally sustainable company', value: 'environmentally_sustainable_company' },
            { label: 'The company is famous for their sleek designs', value: 'famous_sleek_designs' },
            { label: 'Prominent brand ambassadors', value: 'prominent_brand_ambassadors' },
            { label: 'Strong brand presence', value: 'strong_brand_presence' },
            { label: 'Brand is known for a superior quality', value: 'superior_quality' },
          ],
        },
        food: {
          title: 'In the spaces below, select 2-4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Strong brand ambassadors', value: 'strong_brand_ambassadors' },
            { label: 'Great reputation', value: 'great_reputation' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'The brand has a strong luxury feel', value: 'brand_strong_luxury_feel' },
            { label: 'The company has a high status', value: 'company_high_status' },
            { label: 'The company conveys prestige', value: 'company_conveys_prestige' },
            { label: 'The brand signals superiority', value: 'brand_signals_superiority' },
            { label: 'The brand is authentic', value: 'brand_authentic' },
            { label: 'Buying from this brand is an investment', value: 'buying_investment' },
            { label: 'There is a strong loyalty to the brand', value: 'brand_strong_loyalty' },
            {
              label: 'It’s a great experience buying from the brand',
              value: 'brand_great_experience_buying'
            },
          ],
        },
      },
      tooltip: 'This question is related to your company and what your customers and potential customers value in a company selling your product.',
      placeholders: ['The brand is ...', 'The company has ...', 'The company is ...', 'The brand has ...'],
    },
    {
      step: 'disadvantages',
      cardTitle: 'Tell us about your product',
      categories: {
        premium: {
          title: 'In the spaces below, list 4 reasons why someone would decide NOT to buy your product',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand quality', value: 'no_brand_quality_trust' },
            { label: 'It’s too complicated to use', value: 'too_complicated_to_use' },
            { label: 'It’s perceived as too cheap', value: 'perceived_too_cheap' },
            { label: 'The design is inferior', value: 'inferior_design' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'No trust in the quality', value: 'no_quality_trust' },
          ],
        },
        durable: {
          title: 'In the spaces below, select 4 reasons why someone would hesitate to buy your product:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand quality', value: 'no_brand_quality_trust' },
            { label: 'It’s too complicated to use', value: 'too_complicated_to_use' },
            { label: 'It’s perceived as too cheap', value: 'perceived_too_cheap' },
            { label: 'The design is inferior', value: 'inferior_design' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'No trust in the quality', value: 'no_quality_trust' },
          ],
        },
        niche: {
          title: 'In the spaces below, select 4 reasons why someone would hesitate to buy your product:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand quality', value: 'no_brand_quality_trust' },
            { label: 'It’s too complicated to use', value: 'too_complicated_to_use' },
            { label: 'It’s perceived as too cheap', value: 'perceived_too_cheap' },
            { label: 'The design is inferior', value: 'inferior_design' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'No trust in the quality', value: 'no_quality_trust' },
          ],
        },
        convenience: {
          title: 'In the spaces below, select 4 reasons why someone would hesitate to buy your product:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand quality', value: 'no_brand_quality_trust' },
            { label: 'It’s too complicated to use', value: 'too_complicated_to_use' },
            { label: 'It’s perceived as too cheap', value: 'perceived_too_cheap' },
            { label: 'The design is inferior', value: 'inferior_design' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'No trust in the quality', value: 'no_quality_trust' },
          ],
        },
        food: {
          title: 'In the spaces below, list 4 reasons why someone would decide NOT to buy your food',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand quality', value: 'no_brand_quality_trust' },
            { label: 'It’s too complicated to use', value: 'too_complicated_to_use' },
            { label: 'It’s perceived as too cheap', value: 'perceived_too_cheap' },
            { label: 'The design is inferior', value: 'inferior_design' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'No trust in the quality', value: 'no_quality_trust' },
          ],
        },
      },
      tooltip: 'These attributes will be used to explore what your makes your customers hesitate when it comes to purchasing your or your competitors product.',
      placeholders: ["It's ...", 'It has ...', 'It is made of ...', "It's ..."],
    },
    {
      step: 'retailLocations',
      cardTitle: 'Tell us about where you sell or plan to sell your product',
      categories: {
        premium: {
          title: 'In the spaces below, select 4 locations where you will be selling your product',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Online direct from the manufacturer’s website', value: 'online_manufacturer_website' },
            {
              label: 'Online from generic online retailers (Amazon, eBay, etc.)',
              value: 'online_generic_retailers'
            },
            { label: 'Department store (Macy’s, Nordstrom, J. C. Penney, etc.)', value: 'department_store' },
            { label: 'Speciality local retailer', value: 'speciality_local_retailer' },
            { label: 'Manufacturer’s own store(s)', value: 'manufacturer_store' },
            { label: 'Thrift store like Goodwill', value: 'thrift_store' },
            { label: 'Resale site such as Poshmark', value: 'resale_site' },
            { label: 'Social network such as Facebook', value: 'social_network' },
          ],
        },
        durable: {
          title: 'In the spaces below, select 4 locations where you will be selling your product',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Online direct from the manufacturer’s website', value: 'online_manufacturer_website' },
            {
              label: 'Online from generic online retailers (Amazon, eBay, etc.)',
              value: 'online_generic_retailers'
            },
            { label: 'Department store (Macy’s, Nordstrom, J. C. Penney, etc.)', value: 'department_store' },
            { label: 'Speciality local retailer', value: 'speciality_local_retailer' },
            { label: 'Manufacturer’s own store(s)', value: 'manufacturer_store' },
            { label: 'Thrift store like Goodwill', value: 'thrift_store' },
            { label: 'Resale site such as Poshmark', value: 'resale_site' },
            { label: 'Social network such as Facebook', value: 'social_network' },
          ],
        },
        niche: {
          title: 'In the spaces below, select 4 locations where you will be selling your product',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Online direct from the manufacturer’s website', value: 'online_manufacturer_website' },
            {
              label: 'Online from generic online retailers (Amazon, eBay, etc.)',
              value: 'online_generic_retailers'
            },
            { label: 'Department store (Macy’s, Nordstrom, J. C. Penney, etc.)', value: 'department_store' },
            { label: 'Speciality local retailer', value: 'speciality_local_retailer' },
            { label: 'Manufacturer’s own store(s)', value: 'manufacturer_store' },
            { label: 'Thrift store like Goodwill', value: 'thrift_store' },
            { label: 'Resale site such as Poshmark', value: 'resale_site' },
            { label: 'Social network such as Facebook', value: 'social_network' },
          ],
        },
        convenience: {
          title: 'In the spaces below, select 4 locations where you will be selling your product',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Online direct from the manufacturer’s website', value: 'online_manufacturer_website' },
            {
              label: 'Online from generic online retailers (Amazon, eBay, etc.)',
              value: 'online_generic_retailers'
            },
            { label: 'Department store (Macy’s, Nordstrom, J. C. Penney, etc.)', value: 'department_store' },
            { label: 'Speciality local retailer', value: 'speciality_local_retailer' },
            { label: 'Manufacturer’s own store(s)', value: 'manufacturer_store' },
            { label: 'Thrift store like Goodwill', value: 'thrift_store' },
            { label: 'Resale site such as Poshmark', value: 'resale_site' },
            { label: 'Social network such as Facebook', value: 'social_network' },
          ],
        },
        food: {
          title: 'In the spaces below, select 4 locations where you will be selling your food',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Online direct from the manufacturer’s website', value: 'online_manufacturer_website' },
            {
              label: 'Online from generic online retailers (Amazon, eBay, etc.)',
              value: 'online_generic_retailers'
            },
            { label: 'Department store (Wholefoods)', value: 'department_store' },
            { label: 'Restaurant', value: 'restaurant' },
            { label: 'Manufacturer’s own store(s)', value: 'manufacturer_store' },
            { label: 'Cafés', value: 'cafes' },
            { label: 'Resale site such as Poshmark', value: 'resale_site' },
            { label: 'Social network such as Facebook', value: 'social_network' },
          ],
        },
      },
      tooltip: `<div>
        <p>Select different vending sites that are relevant to your company and or service. You may use specific 
        brand names here, such as  <a target="_blank"  href="https://www.amazon.com/">Amazon.com</a>, but you should not use your own brand name.</p>
        <p>If you are a nail salon maybe you want to know if you want to focus on high end streets or malls, or perhaps compare different kinds of malls.</p>
        <p>In contrast, if you are a cloud base service, suitable options could be: social media platforms, blogs, influencers or web shop.</p>
        <p>Follow the prompts to create the selling points that best correlates with your service.</p>
      </div>`,
      placeholders: ["It's ...", 'It has ...', 'It is made of ...', "It's ..."],
    },
    {
      step: 'competitors',
      cardTitle: 'Tell us about your brand and your competition',
      title: 'You & your competition - in the space below, insert your brand and from 1 to 3 of your most important competitors you would like to benchmark your brand against.',
      subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
      tooltip: `<div>
        <p>By setting these values you will get to know the pricing power of your brand. But you will also get the
          insight of your competitors pricing power.</p>
        <p>This would mean that for example, if you are Nike you could see how much more or less you can charge as
          compared to Adidas for white sneakers. </p>
      </div>`,
      placeholders: [
        'Your brand ...',
        'Your #1 competitors brand ...',
        'Your #2 competitors brand ...',
        'Your #3 competitors brand ...',
      ],
    },
    {
      step: 'usage',
      cardTitle: 'Tell us about your intended customers',
      categories: {
        premium: {
          title: 'When would your customers use the product?',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At parties & events', value: 'parties_events' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'When sleeping', value: 'sleeping' },
            { label: 'When cooking', value: 'cooking' },
            { label: 'When driving', value: 'driving' },
            { label: 'To commute', value: 'commute' },
            { label: 'At sea', value: 'sea' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
            { label: 'When dressing up', value: 'dressing_up' },
            { label: 'When getting ready for an event', value: 'getting_ready_for_event' },
          ],
        },
        durable: {
          title: 'When would your customers use the product?',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At parties & events', value: 'parties_events' },
            { label: 'To the office', value: 'to_office' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'When sleeping', value: 'sleeping' },
            { label: 'When cooking', value: 'cooking' },
            { label: 'When driving', value: 'driving' },
            { label: 'To commute', value: 'commute' },
            { label: 'At sea', value: 'sea' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
            { label: 'When dressing up', value: 'dressing_up' },
            { label: 'When cleaning', value: 'cleaning' },
          ],
        },
        niche: {
          title: 'When would your customers use the product?',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At parties & events', value: 'parties_events' },
            { label: 'To the office', value: 'to_office' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'When sleeping', value: 'sleeping' },
            { label: 'When cooking', value: 'cooking' },
            { label: 'When driving', value: 'driving' },
            { label: 'To commute', value: 'commute' },
            { label: 'At sea', value: 'sea' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
            { label: 'When dressing up', value: 'dressing_up' },
            { label: 'When cleaning', value: 'cleaning' },
          ],
        },
        convenience: {
          title: 'When would your customers use the product?',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At parties & events', value: 'parties_events' },
            { label: 'To the office', value: 'to_office' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'When sleeping', value: 'sleeping' },
            { label: 'When cooking', value: 'cooking' },
            { label: 'When driving', value: 'driving' },
            { label: 'To commute', value: 'commute' },
            { label: 'At sea', value: 'sea' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
            { label: 'When dressing up', value: 'dressing_up' },
            { label: 'When cleaning', value: 'cleaning' },
          ],
        },
        food: {
          title: 'When would your customers eat your food',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At parties & events', value: 'parties_events' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'As a snack', value: 'snack' },
            { label: 'At the office', value: 'office' },
            { label: 'At the cinema', value: 'cinema' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
          ],
        },
      },
      tooltip: `<div>
            <p>In this section we want to see when your customers would use your product. </p>
            <p>If your product is glasses this could be options such as "At the office", "Infront of the
              computer", "While reading" and "In everyday life". </p>
            <p>Sometimes your product is made for a given specific purpose such as running shoes or a golf club.
              If this is the case, you can think about different terrains, special events such as competition,
              if it is for daily exercise. </p>
            <p>Or you could use this opportunity to find out what kind of behaviour drives your customers, such as
              "whilst golfing with business partners", "Whilst exercising", "Whilst training to improve my game"
              and "To have fun" etc. </p>
          </div>`,
      placeholders: ['When ...', 'At ...', 'Whilst ...', 'To ...'],
    },
    {
      step: 'targetGroup',
      cardTitle: 'Tell us about your intended customers',
      title: 'Tell us more about your targeted customer',
      subTitle: 'Who are you selling to, and who you want to be selling to',
      tooltip: {
        gender: '<div><p>In this section we want to see who your intended customers are. Select the age and gender of who you want to answer what they are willing to pay for your product.</p><p>If you select from 25+ this means that will not get any responses from people below the age of 25.</p></div>',
        regions: '<div><p>In this section we want to know your targeted country and or regions. The broader you are the more options you will have on filtering once you get the results. This means that you can get great insights based on a geographical and regional level, which can allow you to focus sales efforts there.</p><p>However, if you are limited to a geographical region and cannot sell to certain parts of a country you should limit to the areas which you can service.</p></div>',
      },
    },
  ],
};

const serviceConfig = {
  type: 'service',
  steps: [
    {
      step: 'aim',
      title: 'What is the aim of your project?',
      options: [
        { label: 'Launching a new service', value: 'launch_new_service' },
        {
          label: 'Find out if I should change from a once per unit payment to a subscription model',
          value: 'change_payment_model',
        },
        { label: 'Launching my service in a new market', value: 'launch_in_new_market' },
        { label: 'Adjust my current prices', value: 'adjust_price' },
        { label: 'Better understand my customer demographics', value: 'investigate_customer_demographics' },
      ],
    },
    {
      step: 'description',
      cardTitle: 'Tell us about your service',
      fields: {
        name: {
          title: 'What kind of service are you providing?',
          subTitle: 'Use the generic name using articles a/an or in plural - no brand name. \n\n *By default your first letter will be forced in lower case - this is to ensure that the survey looks good*.',
          placeholder: 'For example: “hair stylist”, “app for games” or “streaming service”',
          tooltip: `<div>
                        <p>Make sure your service type is accurately described.</p>
                        <ul style="padding-left: 15px">
                          <li>Do <b>not</b> use any brand terms in the 'service type' input field.</li>
                          <li>Try and be a bit more descriptive, don't say 'car wash' but rather 'An on-demand car wash service', something that describes why your service is different.</li>
                          <li>Make sure you use: <b>plural /a /an</b></li>
                        </ul>
                    </div>`,
        },
        description: {
          title: 'Describe what’s unique with your service',
          subTitle: 'Using 2 sentences, no brand name',
          placeholder: 'For example: “an on demand online entertainment source for TV shows, movies and other streaming media. These services provide an alternative.”',
          tooltip: `<div>
                        <p>In this field, we would like you to describe your service. A maximum of two sentences can be used in describing your service, and these should capture your service uniqueness.</p>
                        <p><b>Don't use</b> possessive language such as mine or our. Instead use terms like this or these.</p>
                    </div>`,
        },
        price: {
          title: 'What is the current price of your service?',
          paymentModels: [
            { label: 'Fixed', value: 'fixed' },
            { label: 'Per Hour', value: 'per_hour' },
            { label: 'Per week', value: 'per_week' },
            { label: 'Per Month', value: 'per_month' },
            { label: 'Per Year', value: 'per_year' },
          ],
          tooltip: 'Here you should fill out the current pricing model, if you don\'t have a current pricing model you fill out your intended pricing model. ',
        },
        usageFrequency: {
          title: 'Find out how often people would use your service or product:',
          tooltip: 'With this field activated you will get insights on how people often your prospective customers use you product or service. This question is optional as it isn’t suitable for everyone. We suggest you toggle it on and review how it looks in the preview section. If it doesn\'t look right you can always edit it off from there.',
        },
      },
    },
    // {
    //   step: 'industry',
    //   cardTitle: 'Tell us about your service',
    //   title: 'What industry is your service related to?',
    //   subTitle: 'Select at least 2, the more you select the better',
    //   options: INDUSTRIES,
    // },
    {
      step: 'category',
      title: 'Which option would best describe your service?',
      options: [
        {
          label: 'A service that many of the people will not afford to buy (with unique characteristics and brand identifications e.g. a pay per-view or beauty treatment. This is considered *a premium service*.',
          value: 'premium',
        },
        {
          label: 'A service that you use in regular basis (e.g. catering, gyms, cloud storage). This is considered *a frequent service*.',
          value: 'frequent',
        },
        {
          label: 'A service that you use for special occasions and not many people are familiar with this (e.g. coding classes for kids, eSport tournaments). This is considered *a niche service*.',
          value: 'niche',
        },
      ],
    },
    {
      step: 'confirmCategory',
      categories: {
        premium: {
          title: 'Is it correct that your service can be considered a premium service?',
          subTitle: 'This means your service us very price, and you are not targeting a large section of the population. But rather a smaller high-income group.',
        },
        frequent: {
          title: 'Is it correct that your service can be considered a frequent service?',
          subTitle: 'This means a service you would use often, sometimes daily or weekly (e.g. catering, gyms, cloud storage)',
        },
        niche: {
          title: 'Is it correct that your service can be considered a niche service?',
          subTitle: 'This means your service is not targeting a large section of the population. But rather a smaller group who shares specific interests.',
        },
      },
    },
    {
      step: 'features',
      categories: {
        premium: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your service:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It’s a personalized experience', value: 'personalized_experience' },
            { label: 'It offers technical excellence', value: 'offers_technical_excellence' },
            { label: 'It’s exclusive', value: 'exclusive' },
            {
              label: 'It’s only available to people of a certain status',
              value: 'for_people_of_certain_status'
            },
            { label: 'It’s invitation based', value: 'invitation_based' },
            { label: 'It provides superior support', value: 'superior_support' },
            { label: 'It provides excellent customer experience', value: 'excellent_customer_experience' },
            { label: 'It has a strong brand recognition', value: 'strong_brand_recognition' },
            { label: 'It has a high quality feel', value: 'high_quality_feel' },
            { label: 'Personalized attention', value: 'personalized_attention' },
            {
              label: 'Flexibility and customization to meet individual needs or preferences',
              value: 'individual_needs_or_preferences'
            },
            { label: 'The staff are professional', value: 'staff_are_professional' },
            { label: 'It has a high value', value: 'high_value' },
            { label: 'There is a strong brand identity', value: 'strong_brand_identity' },
          ],
        },
        frequent: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your service:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It’s a personalized experience', value: 'personalized_experience' },
            { label: 'It’s reliable', value: 'reliable' },
            { label: 'Trustworthy results ', value: 'trustworthy_results' },
            { label: 'The venue is easily accessible  ', value: 'easily_accessible_venue' },
            { label: 'It’s invitation based', value: 'invitation_based' },
            { label: 'It provides superior support', value: 'superior_support' },
            { label: 'It provides excellent customer experience', value: 'excellent_customer_experience' },
            { label: 'It has a strong brand recognition', value: 'strong_brand_recognition' },
            { label: 'It has a high quality feel', value: 'high_quality_feel' },
            { label: 'It has good opening hours', value: 'good_opening_hours' },
            { label: 'The venue is clean', value: 'venue_is_clean' },
            {
              label: 'It offers energy-efficient upgrades and installations',
              value: 'upgrades_and_installations'
            },
            {
              label: 'It has convenient and flexible scheduling options',
              value: 'flexible_scheduling_options'
            },
            { label: 'It has easy online booking', value: 'easy_online_booking' },
            { label: 'It offers a secure and easy payment solution', value: 'easy_payment_solution' },
            { label: 'It has membership packages for families', value: 'packages_for_families' },
            {
              label: 'There are favorable discounts for students, military personnel, and seniors',
              value: 'favorable_discounts_for'
            },
            { label: 'It offers loyalty programs for frequent customers', value: 'loyalty_programs_for' },
            { label: 'There are indoor training facilities', value: 'training_facilities' },
            { label: 'There are renting or leasing options', value: 'leasing_options' },
            { label: ' There are sustainable and eco-friendly options', value: 'eco_friendly' },
          ],
        },
        niche: {
          title: 'In the spaces below, select the 2-4 main features/attributes of your service:',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'It’s a personalized experience', value: 'personalized_experience' },
            { label: 'It offers technical excellence', value: 'offers_technical_excellence' },
            { label: 'It’s exclusive', value: 'exclusive' },
            {
              label: 'It’s only available to people of a certain status',
              value: 'for_people_of_certain_status'
            },
            { label: 'It’s invitation based', value: 'invitation_based' },
            { label: 'It provides superior support', value: 'superior_support' },
            { label: 'It provides excellent customer experience', value: 'excellent_customer_experience' },
            { label: 'It has a strong brand recognition', value: 'strong_brand_recognition' },
            { label: 'It has a high quality feel', value: 'high_quality_feel' },
            { label: 'It has superior function or performance', value: 'function_or_performance' },
            { label: 'It offers specialized knowledge and expertise', value: 'knowledge_and_expertise' },
            { label: 'It offers custom solutions', value: 'custom_solutions' },
            { label: 'It is environmentally sustainable', value: 'environmentally_sustainable' },
            { label: 'There are private classes', value: 'private_classes' },
          ],
        },
      },
      cardTitle: 'Tell us about your service',
      tooltip: `<div>
                  <p>The attributes you choose here will be put in contrast with each other. That means you can select 
                  <i>"it's convenient"</i> and another value <i>"it's reliable"</i>, as a result, you would get insights 
                  on which one of the alternatives your customer are willing to pay more for.</p>
                  <p>This means that you can learn what features and/or attributes truly drive the value of your service. 
                  The insights are especially useful in service development and marketing purposes.</p>
                </div>`,
      placeholders: ["It's ...", 'It has ...', 'The venue is ...', "It's ..."],
    },
    {
      step: 'uniqueFeatures',
      cardTitle: 'Tell us about your company',
      categories: {
        premium: {
          title: 'In the spaces below, select 4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Strong brand ambassadors', value: 'strong_brand_ambassadors' },
            { label: 'Great reputation', value: 'great_reputation' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'The brand has a strong luxury feel', value: 'brand_strong_luxury_feel' },
            { label: 'It has a luxurious venue', value: 'luxurious_venue' },
            { label: 'The company conveys prestige', value: 'company_conveys_prestige' },
            { label: 'The brand signals superiority', value: 'brand_signals_superiority' },
            { label: 'The brand is authentic', value: 'brand_authentic' },
            { label: 'It has an easy-to-use platform', value: 'easy_to_use_platform' },
            { label: 'There is a strong loyalty to the brand', value: 'brand_strong_loyalty' },
            { label: 'Fast and flexible to use', value: 'fast_and_flexible_use' },
            { label: 'The company feels exclusive', value: 'feels_exclusive' },
            {
              label: 'The company is known to offer more personalized experiences',
              value: 'more_personalized_experiences'
            },
            { label: 'The company has value-added services', value: 'value_added_services' },
          ],
        },
        frequent: {
          title: 'In the spaces below, select 4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Strong brand ambassadors', value: 'strong_brand_ambassadors' },
            { label: 'Great reputation', value: 'great_reputation' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'The brand is authentic', value: 'brand_authentic' },
            { label: 'It has an easy-to-use platform', value: 'easy_to_use_platform' },
            { label: 'There is a strong loyalty to the brand', value: 'brand_strong_loyalty' },
            { label: 'Fast and flexible to use', value: 'fast_and_flexible_use' },
            { label: 'The company is known for its high security', value: 'high_security' },
            { label: 'It has a great customer service', value: 'customer_service' },
            { label: 'The social mission behind the company', value: 'behind_the_company' },
            { label: 'Good reviews from past customers', value: 'past_customers' },
          ],
        },
        niche: {
          title: 'In the spaces below, select 4 reasons for why your company or brand is unique.',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Strong brand ambassadors', value: 'strong_brand_ambassadors' },
            { label: 'Great reputation', value: 'great_reputation' },
            { label: 'Social mission behind company selling the product', value: 'social_mission' },
            { label: 'The company conveys prestige', value: 'company_conveys_prestige' },
            { label: 'The brand signals superiority', value: 'brand_signals_superiority' },
            { label: 'The brand is authentic', value: 'brand_authentic' },
            { label: 'It has an easy-to-use platform', value: 'easy_to_use_platform' },
            { label: 'There is a strong loyalty to the brand', value: 'brand_strong_loyalty' },
            { label: 'Fast and flexible to use', value: 'fast_and_flexible_use' },
            { label: 'The company is known for its high security', value: 'high_security' },
            { label: 'It has a great customer service', value: 'customer_service' },
            { label: 'The social mission behind the company', value: 'behind_the_company' },
            { label: 'Good reviews from past customers', value: 'past_customers' },
          ],
        },
      },
      tooltip: 'This question is related to your company and what your customers and potential customers value in a company selling your service.',
      placeholders: ['The brand is ...', 'The company has ...', 'The company is ...', 'The brand has ...'],
    },
    {
      step: 'disadvantages',
      cardTitle: 'Tell us more about your service',
      tooltip: 'These attributes will be used to explore what your makes your customers hesitate when it comes to purchasing your or your competitors service.',
      categories: {
        premium: {
          title: 'In the spaces below, list 4 reasons why someone would decide NOT to buy your service',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand', value: 'no_brand_trust' },
            { label: 'It’s not convenient', value: 'not_convenient' },
            { label: 'It’s out of the way', value: 'out_of_the_way' },
            { label: 'The venue feels cheap', value: 'venue_feels_cheap' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'Too many choices available', value: 'choice_available' },
            {
              label: 'Perceive to be a high risk of it not meeting their expectations',
              value: 'meeting_their_expectations'
            },
            { label: 'There are unfavorable reviews', value: 'unfavorable_reviews' },
            { label: 'There are security concerns', value: 'security_concerns' },
          ],
        },
        frequent: {
          title: 'In the spaces below, list 4 reasons why someone would decide NOT to buy your service',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand', value: 'no_brand_trust' },
            { label: 'It’s not convenient', value: 'not_convenient' },
            { label: 'It’s out of the way', value: 'out_of_the_way' },
            { label: 'The venue feels cheap', value: 'venue_feels_cheap' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'Too many choices available', value: 'choice_available' },
          ],
        },
        niche: {
          title: 'In the spaces below, list 4 reasons why someone would decide NOT to buy your service',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'Don’t trust the brand', value: 'no_brand_trust' },
            { label: 'It’s not convenient', value: 'not_convenient' },
            { label: 'It’s out of the way', value: 'out_of_the_way' },
            { label: 'The venue feels cheap', value: 'venue_feels_cheap' },
            { label: 'It’s too expensive', value: 'too_expensive' },
            { label: 'Too many choices available', value: 'choice_available' },
          ],
        },
      },
      placeholders: ["It's ...", 'It has ...', 'It is made of ...', "It's ..."]
    },
    {
      step: 'retailLocations',
      cardTitle: 'Tell us about where you sell or plan to sell your service',
      categories: {
        premium: {
          title: 'In the spaces below, select 4 locations or where you will be selling your service',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At the mall', value: 'mall' },
            { label: 'On a high-end street', value: 'high_end_street' },
            { label: 'Online', value: 'online' },
            { label: 'Apple’s app store', value: 'app_store' },
            { label: 'Social network such as Facebook', value: 'social_network' },
            { label: 'In affluent neighborhoods', value: 'affluent_neighborhoods' },
            { label: 'In the downtown area', value: 'downtown_area' },
            { label: 'At tourist destinations', value: 'tourist_destinations' },
          ],
        },
        frequent: {
          title: 'In the spaces below, select 4 locations or where you will be selling your service',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At the mall', value: 'mall' },
            { label: 'On a high-end street', value: 'high_end_street' },
            { label: 'Online', value: 'online' },
            { label: 'Apple’s app store', value: 'app_store' },
            { label: 'Social network such as Facebook', value: 'social_network' },
            { label: 'In residential area', value: 'residential_area' },
            { label: 'In the downtown area', value: 'downtown_area' },
            { label: 'At tourist destinations', value: 'tourist_destinations' },
          ],
        },
        niche: {
          title: 'In the spaces below, select 4 locations or where you will be selling your service',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'At the mall', value: 'mall' },
            { label: 'On a high-end street', value: 'high_end_street' },
            { label: 'Online', value: 'online' },
            { label: 'Apple’s app store', value: 'app_store' },
            { label: 'Social network such as Facebook', value: 'social_network' },
            { label: 'In affluent neighborhoods', value: 'affluent_neighborhoods' },
            { label: 'In the downtown area', value: 'downtown_area' },
            { label: 'At tourist destinations', value: 'tourist_destinations' },
            { label: 'By referrals', value: 'referrals' },
          ],
        },
      },
      tooltip: `<div>
        <p>Select different vending sites that are relevant to your company and or service. You may use specific 
        brand names here, such as  <a target="_blank"  href="https://www.amazon.com/">Amazon.com</a>, but you should not use your own brand name.</p>
        <p>If you are a nail salon maybe you want to know if you want to focus on high-end streets or malls, or perhaps compare different kinds of malls.</p>
        <p>In contrast, if you are a cloud base service, suitable options could be social media platforms, blogs, influencers, or web shops.</p>
        <p>Follow the prompts to create the selling points that best correlate with your service.</p>
      </div>`,
    },
    {
      step: 'retailPeriods',
      title: 'At what times do you sell your service?',
      subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
      tooltip: 'For many services there is a difference in need depending on what day of the week it is or what hour of the day, etc. For example a cleaning service might charge higher on Thursdays and Fridays than on Mondays. And the underground in London famously charges a higher rate during peak hours than they do off peak.',
      options: [
        { label: 'On Weekends', value: 'weekends' },
        { label: 'On Weekdays', value: 'weekdays' },
        { label: 'On Fridays', value: 'fridays' },
        { label: 'On Holidays', value: 'holidays' },
        { label: 'During Summer', value: 'summer' },
        { label: 'In Evenings', value: 'evenings' },
        { label: 'During Rush Hour', value: 'rush_hour' },
      ],
      placeholders: [
        'On ...',
        'During ...',
        'In ...',
        'On ...',
      ],
    },
    {
      step: 'competitors',
      title: 'You & your competition - in the space below, insert your brand and from 1 to 3 of your most important competitors you would like to benchmark your brand against.',
      subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
      cardTitle: 'Tell us about your brand and your competition',
      tooltip: `<div>
        <p>By setting these values you will get to know the pricing power of your brand. But you will also get the
          insight of your competitors pricing power.</p>
        <p>This would mean that for example, if you are Nike you could see how much more or less you can charge as
          compared to Adidas for white sneakers. </p>
      </div>`,
      placeholders: [
        'Your brand ...',
        'Your #1 competitors brand ...',
        'Your #2 competitors brand ...',
        'Your #3 competitors brand ...',
      ],
    },
    {
      step: 'usage',
      cardTitle: 'Tell us about your intended customers',
      tooltip: `<div>
           <p>In this section we want to see when your customers would use your service.</p>
           <p>If your service is hair saloon this could be options such as "To get ready for a party", "To freshen up" and "To maintain look".</p>
           <p>Sometimes your service is made for a given specific purpose such as a home cleaning service or a streaming service. If this is the case, you can think about "Using it while alone", "To have a clean home for the weekend", "To clean before moving".</p>
           <p>Or you could use this opportunity to find out what kind of behaviour drives your customers, such as "While connecting with others", "While exercising", "While relaxing" and "To have fun" etc.</p>
       </div>`,
      categories: {
        premium: {
          title: 'When would your customers use your service?',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'To get pampered', value: 'get_pampered' },
            { label: 'For parties', value: 'parties' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When cooking', value: 'cooking' },
            { label: 'When driving', value: 'driving' },
            { label: 'To get maintenance', value: 'get_maintenance' },
            { label: 'To help with chores', value: 'chores' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
            { label: 'When dressing up', value: 'dressing_up' },
            { label: 'When getting ready for an event', value: 'getting_ready_for_event' },
            { label: 'When bored', value: 'bored' },
            { label: 'When help is needed', value: 'help_is_needed' },
            { label: 'To look prettier', value: 'look_prettier' },
            { label: 'To get entertained', value: 'entertained' },
            { label: 'To save time or increase convenience', value: 'increase_convenience' },
          ],
        },
        frequent: {
          title: 'When would your customers use your service?',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'To get pampered', value: 'get_pampered' },
            { label: 'For parties', value: 'parties' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When cooking', value: 'cooking' },
            { label: 'When driving', value: 'driving' },
            { label: 'To get maintenance', value: 'get_maintenance' },
            { label: 'To help with chores', value: 'chores' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
            { label: 'When dressing up', value: 'dressing_up' },
            { label: 'When getting ready for an event', value: 'getting_ready_for_event' },
            { label: 'To improve appearance', value: 'improve_appearance' },
            { label: 'To be part of a community', value: 'part_of_a_community' },
            { label: 'To reduce stress', value: 'reduce_stress' },
            { label: 'To collaborate with others', value: 'collaborate_with_others' },
            { label: 'To access files from any device', value: 'from_any_device' },
            { label: 'To get ongoing maintenance', value: 'ongoing_maintenance' },
            { label: 'To improve personal development or form better habits', value: 'form_better_habits' },
          ],
        },
        niche: {
          title: 'When would your customers use your service?',
          subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
          options: [
            { label: 'To get pampered', value: 'get_pampered' },
            { label: 'For parties', value: 'parties' },
            { label: 'Whilst working out', value: 'working_out' },
            { label: 'When relaxing', value: 'relaxing' },
            { label: 'When cooking', value: 'cooking' },
            { label: 'When driving', value: 'driving' },
            { label: 'To get maintenance', value: 'get_maintenance' },
            { label: 'To help with chores', value: 'chores' },
            { label: 'When watching television', value: 'watching_television' },
            { label: 'When playing games', value: 'playing_games' },
            { label: 'When dressing up', value: 'dressing_up' },
            { label: 'When getting ready for an event', value: 'getting_ready_for_event' },
            {
              label: 'When I want to be part of a community or network with similar values or interests',
              value: 'similar_values_or_interests'
            },
            { label: 'To collaborate with others', value: 'collaborate_with_others' },
            { label: 'To improve efficiency', value: 'improve_efficiency' },
            { label: 'To make an impact or change on the environment', value: 'change_on_the_environment' },
          ],
        },
      },
      placeholders: ['When ...', 'At ...', 'Whilst ...', 'To ...'],
    },
    {
      step: 'targetGroup',
      title: 'Tell us more about your targeted customer',
      subTitle: 'Who are you selling to, and who you want to be selling to',
      tooltip: {
        gender: '<div><p>In this section we want to see when who your intended customers are. Select the age and gender of who you want to answer what they are willing to pay for your service. If you select from 25+ this means that will not get any responses from people below the age of 25.</p></div>',
        regions: '<div><p>In this section we want to know your targeted country and or regions. The broader you are the more options you will have on filtering once you get the results. This means that you can get great insights based on a geographical and regional level, which can allow you to focus sales efforts there.</p><p>However, if you are limited to a geographical region and cannot sell to certain parts of a country you should limit to the areas which you can service.</p></div>',
      },
    },
  ],
};

export const projectFormConfig = {
  product: productConfig,
  service: serviceConfig,
};

export type ProjectFormConfigType = typeof projectFormConfig;

export type ProjectTypeConfigType = typeof projectFormConfig['product' | 'service'];
export type ProjectTypeStepConfigType = ProjectTypeConfigType['steps'][number];
