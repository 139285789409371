import { Space } from 'antd';
import type { FC, ReactNode } from 'react';
import type { RibbonProps } from 'antd/es/badge/Ribbon';
import { Card } from '../../atoms/Card';
import {
  StyledProjectModeSelectorRibbon,
  StyledProjectModeSelectorText,
  StyledProjectModeSelectorTitle,
} from './ProjectModeSelector.styles';
import {
  ProjectModeSelectorCardButton,
  ProjectModeSelectorCardButtonProps,
} from './ProjectModeSelectorCardButton';


export interface ProjectModeSelectorCardProps {
  title: ReactNode;
  description: ReactNode;
  actions: Array<ProjectModeSelectorCardButtonProps>;
  ribbonProps?: RibbonProps;
}

export const ProjectModeSelectorCard: FC<ProjectModeSelectorCardProps> = ({
  title,
  description,
  actions,
  ribbonProps,
}) => {
  const card = (
    <Card width="380px" padding="24px">
      <Space direction="vertical" align="center" size="large">
        <StyledProjectModeSelectorTitle level={4}>
          {title}
        </StyledProjectModeSelectorTitle>

        <StyledProjectModeSelectorText>
          { description }
        </StyledProjectModeSelectorText>

        <Space direction="vertical" align="center" size="small">
          {
            actions.map(({ title: buttonTitle, buttonProps, tooltipProps }) => (
              <ProjectModeSelectorCardButton
                key={buttonTitle}
                title={buttonTitle}
                buttonProps={buttonProps}
                tooltipProps={tooltipProps}
              />
            ))
          }
        </Space>
      </Space>
    </Card>
  );

  if (!ribbonProps) {
    return card;
  }
  return (
    <StyledProjectModeSelectorRibbon {...ribbonProps}>
      { card }
    </StyledProjectModeSelectorRibbon>
  );
};
