import type { FC } from 'react';
import type { CardProps as AntDCardProps } from 'antd';
import { StyledCard } from './Card.styles';


interface CardProps extends AntDCardProps {
  width?: string;
  padding?: string
}

export const Card: FC<CardProps> = ({ width, padding = '46px 54px', ...rest }) => (
  <StyledCard width={width} padding={padding} {...rest} />
);
