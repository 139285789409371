import styled from 'styled-components';
import { Form } from 'antd';


export const StyledDynamicCustomProjectHorizontalFormItem = styled(Form.Item)`
  margin-bottom: 0;
  //margin-left: 200px;
  
  .ant-form-item-row {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    
    .ant-form-item-label {
      padding-bottom: 0;
      margin-right: 10px;
      overflow: visible;
    }
  }
`;
