import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import {
  Col, Row, Space, Typography
} from 'antd';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '../../../../molecules/RadioGroup';
import { FormItem } from '../../../../molecules/FormItem';
import { InputNumber } from '../../../../atoms/InputNumber';
import { Select } from '../../../../atoms/Select';
import type { CINTCountry } from '../../../../../types/cint';
import { appLangs } from '../../../../../locales';
import { TargetGroupCountryRegionsSelector } from '../../../TargetGroupCountryRegionsSelector';


interface TargetGroupStepFormItemProps {
  cardTitle?: string;
  label?: string;
  subTitle?: string;
  countries?: CINTCountry[];
  tooltip?: {
    gender: string,
    regions: string
  }
  resetRegionState?: (props?: { keepRegionType?: boolean }) => void;
  disableLanguageSelect?: boolean;
  onCountryValueChange?: (countryId: number) => void;
}

const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Both', value: 'both' },
];

export const TargetGroupStepFormItem: FC<TargetGroupStepFormItemProps> = ({
  cardTitle,
  label,
  subTitle,
  countries,
  tooltip,
  resetRegionState,
  disableLanguageSelect,
  onCountryValueChange,
}) => {
  const { i18n, t } = useTranslation();
  const { Title } = Typography;

  const renderMarkdown = useCallback(
    (tooltipText: string) => (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
      >
        {tooltipText}
      </ReactMarkdown>
    ),
    []
  );

  const handleLanguageDropdown = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const languageOptions = useMemo(() => {
    return appLangs.map((value) => ({ value, label: t(`language.${value}`) }));
  }, [t]);

  return (
    <>
      { cardTitle ? (
        <Title level={3}>{cardTitle}</Title>
      ) : null}
      {
        label || subTitle ? (
          <FormItem
            label={label}
            subTitle={subTitle}
            largeLabel
          />
        ) : null
      }

      <FormItem
        name={['targetGroup', 'gender']}
        label="What gender are you targeting?"
        rules={[{ required: true, message: 'Please select gender!', whitespace: true }]}
        tooltip={{
          title: tooltip?.gender && renderMarkdown(tooltip.gender),
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        largeLabel
      >
        <RadioGroup
          options={GENDER_OPTIONS}
        />
      </FormItem>

      <FormItem
        label="What age group are you targeting"
        largeLabel
      >
        <Space.Compact>
          <Row gutter={50}>
            <Col>
              <FormItem
                dependencies={[['targetGroup', 'age', 'max']]}
                name={['targetGroup', 'age', 'min']}
                validateTrigger="onBlur"
                rules={[
                  { required: true, type: 'number', message: 'Please enter "min" age!' },
                  { type: 'number', min: 18, message: 'Age must be greater than 18!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const maxAge = getFieldValue(['targetGroup', 'age', 'max']);
                      if (!value || !maxAge || maxAge > value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The min age must be less than max age!'));
                    },
                  }),
                ]}
                style={{ maxWidth: 100 }}
                errorMessageTextAlign="left"
              >
                <InputNumber
                  placeholder="From"
                  type="number"
                  style={{ minWidth: 100 }}
                  step={1}
                  min={18}
                />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                dependencies={[['targetGroup', 'age', 'min']]}
                name={['targetGroup', 'age', 'max']}
                validateTrigger="onBlur"
                rules={[
                  { required: true, type: 'number', message: 'Please enter "max" age!' },
                  { type: 'number', max: 99, message: 'Age must be less than 99!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const minAge = getFieldValue(['targetGroup', 'age', 'min']);

                      if (!value || !minAge || minAge < value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The max age must be greater than min age!'));
                    },
                  }),
                ]}
                style={{ maxWidth: 100 }}
                errorMessageTextAlign="left"
              >
                <InputNumber
                  placeholder="To"
                  type="number"
                  style={{ minWidth: 100 }}
                  step={1}
                  min={19}
                />
              </FormItem>
            </Col>
          </Row>
        </Space.Compact>
      </FormItem>

      <FormItem
        label="What regions are you targeting"
        tooltip={{
          title: tooltip?.regions && renderMarkdown(tooltip.regions),
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        largeLabel
      >
        <TargetGroupCountryRegionsSelector
          countries={countries}
          onCountryValueChange={onCountryValueChange}
          resetRegionState={resetRegionState}
        />
      </FormItem>

      <FormItem
        label="What language are you targeting"
        largeLabel
        name="surveyLanguage"
        tooltip={{
          title: 'What language are you targeting',
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        initialValue="en"
      >
        <Select
          style={{ width: 150 }}
          options={languageOptions}
          onChange={handleLanguageDropdown}
          disabled={disableLanguageSelect}
        />
      </FormItem>
    </>
  );
};
