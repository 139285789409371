import capitalize from 'lodash/capitalize';
import {
  Button,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import Icon, { CaretDownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import type { InterpolationInputProps } from './InterpolationInput';
import type { InterpolationVariable } from './types';


export interface VariablesMenuProps extends Pick<InterpolationInputProps, 'variables'> {
  onSelect: (v: InterpolationVariable) => void;
}

export const VariablesMenu = ({ variables, onSelect }: VariablesMenuProps) => {
  const items: MenuProps['items'] = variables.map(({
    category,
    children,
    disabled,
    excuse,
    Icon: CategoryIcon,
  }) => ({
    label: (
      <Tooltip title={disabled && excuse}>
        <Space>
          <Icon component={CategoryIcon} />
          {capitalize(category)}
        </Space>
      </Tooltip>
    ),
    disabled,
    key: category,
    children: children.map((variable) => ({
      label: (
        <Tooltip
          title={
          variable.disabled
            ? variable.excuse
            : variable.description
          }
        >
          {variable.display}
        </Tooltip>
      ),
      disabled: variable.disabled,
      key: variable.interpolation,
      onClick: () => onSelect(variable),
    })),
  }));

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button type="primary" icon={<CaretDownOutlined />}>
        Insert Variable
      </Button>
    </Dropdown>
  );
};
