import type { FC, ReactNode } from 'react';
import { StyledModalFooter } from './ModalFooter.styles';


interface ModalFooterProps {
  children: ReactNode;
}

export const ModalFooter: FC<ModalFooterProps> = ({ children }) => (
  <StyledModalFooter>
    {children}
  </StyledModalFooter>
);
