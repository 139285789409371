import { FC, useMemo } from 'react';
import {
  Badge,
  Dropdown,
  Form,
  Space,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Checkbox } from '../../../atoms/Checkbox';
import { StyledMoreOutlined } from '../../../pages/Projects/Projects.styles';
import { OptionInterface } from '../../../../hooks/useOptionsTranslation';
import { InputNumber } from '../../../atoms/InputNumber';
import type { InterpolationCategory } from '../../../atoms/InterpolationInput/types';
import { InterpolationInput } from '../../../atoms/InterpolationInput/InterpolationInput';


interface DynamicAnswerAlternativeFieldProps {
  // key: number;
  name: number;
  onRemoveClick: (index: number | number[]) => void;
  answerAlternativeIndex: number;
  formValue?: OptionInterface;
  multiselect?: boolean;
  enableResponsesLimit?: boolean;
  variables: InterpolationCategory[];
}

export const DynamicAnswerAlternativeField: FC<DynamicAnswerAlternativeFieldProps> = ({
  name,
  onRemoveClick,
  answerAlternativeIndex,
  formValue,
  multiselect,
  variables,
  enableResponsesLimit,
  ...rest
}) => {
  const dropdownItems = useMemo(() => {
    return [{
      key: 'disqualify',
      label: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div onClick={(e) => e.stopPropagation()}>
          <Form.Item noStyle name={[name, 'disqualify']} valuePropName="checked">
            <Checkbox style={{ width: '100%' }}>Disqualify?</Checkbox>
          </Form.Item>
        </div>
      ),
    },
    {
      key: 'skipTranslation',
      label: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div onClick={(e) => e.stopPropagation()}>
          <Form.Item noStyle name={[name, 'skipTranslation']} valuePropName="checked">
            <Checkbox style={{ width: '100%' }}>
              Skip translation?
            </Checkbox>
          </Form.Item>
        </div>
      ),
    },
    {
      key: 'deselectAll',
      disabled: !multiselect,
      label: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div onClick={(e) => e.stopPropagation()}>
          <Form.Item
            noStyle
            name={[name, 'deselectAll']}
            valuePropName="checked"
            tooltip={{
              title: 'Multiselect mode only',
              arrow: true,
              placement: 'left',
              overlayInnerStyle: { padding: '10px 16px' }
            }}
          >
            <Checkbox style={{ width: '100%' }} disabled={!multiselect}>Deselect other options?</Checkbox>
          </Form.Item>
        </div>
      ),
    }];
  }, [multiselect, name]);

  const selectedOptionsCount = useMemo(() => {
    let count = 0;
    const options = ['disqualify', 'skipTranslation', 'deselectAll'];

    options.forEach((option) => {
      if (formValue?.[option]) {
        count += 1;
      }
    });

    return count;
  }, [formValue]);

  return (
    <div
      style={{
        display: 'flex',
        gap: 20,
        alignItems: 'center',
      }}
      {...rest}
    >
      <Form.Item noStyle name={[name, 'label']}>
        <InterpolationInput
          placeholder={`Answer alternative #${answerAlternativeIndex + 1}`}
          variables={variables}
        />
      </Form.Item>

      { enableResponsesLimit ? (
        <Form.Item noStyle name={[name, 'limit']}>
          <InputNumber
            style={{ width: 120 }}
            placeholder="Limit"
            min={0}
          />
        </Form.Item>
      ) : null }

      <Badge count={selectedOptionsCount}>
        <Dropdown arrow menu={{ items: dropdownItems }} trigger={['click']} placement="bottomRight">
          <Space>
            <StyledMoreOutlined />
          </Space>
        </Dropdown>
      </Badge>

      <CloseOutlined
        style={{ color: '#FF1212', marginLeft: 30 }}
        onClick={() => {
          onRemoveClick(name);
        }}
      />
    </div>
  );
};
