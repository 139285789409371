/* eslint-disable max-len */

export const golfProjectConfig = {
  product: {
    type: 'product',
    steps: [
      {
        step: 'description',
        cardTitle: 'Tell us about your product',
        fields: {
          name: {
            title: 'What kind product are you selling?',
            subTitle: 'Use the generic name using articles a/an or in plural - no brand name. \n\n *By default your first letter will be forced in lower case - this is to ensure that the survey looks good*.',
            placeholder: 'For example: “golf balls” or “golf club”',
            tooltip: `<div>
                        <p>Make sure your product type is accurately described.</p>
                        <ul style="padding-left: 15px">
                          <li>Do <b>not</b> use any brand terms in the 'product type' input field.</li>
                          <li>Try and be a bit more descriptive, don't say 'shoes' but rather 'running shoes'</li>
                          <li>Make sure you use: <b>plural /a /an</b></li>
                        </ul>
                    </div>`,
          },
          description: {
            title: 'Describe what’s unique with your product',
            subTitle: 'Using 2 sentences, no brand name',
            placeholder: 'For example: “a unique design of a luxury watch. The watch is coated in diamonds and is gold plated.”',
            tooltip: `<div>
                      <p>In this field, we would like you to describe your product. A maximum of two sentences can be used in 
                      describing your product, and these should capture its uniqueness.</p> 
                      <p><b>Don't use</b> possessive language such as mine or our. Instead use terms like this or these.</p>
                    </div>`,
          },
          quantity: {
            title: '(Optional) What is the quantity of your product?',
            tooltip: "This is not relevant for all products. But if you want your potential customers to know the exact quantity of your product this will give better results. For example, 3 ounces of perfume would have a different price than 4.2 ounces, so it's sometimes important your customers know how much they are asked to pay. (But if you sell sneakers this is of course not applicable to you and you can ignore this question and move on)"
          },
          price: {
            title: 'What is the intended price of the product?',
            paymentModels: [
              { label: 'Per unit sold', value: 'per_unit_sold' },
              { label: 'Per Month', value: 'per_month' },
              { label: 'Per Year', value: 'per_year' },
            ],
            tooltip: "Here you should fill out the current pricing model, if you don't have a current pricing model you fill out your intended pricing model.",
          },
        // usageFrequency: {
        //   title: 'Find out how often people would use your service or product:',
        //   tooltip: 'With this field activated you will get insights on how people often your prospective customers use you product or service. This question is optional as it isn’t suitable for everyone. We suggest you toggle it on and review how it looks in the preview section. If it doesn\'t look right you can always edit it off from there.',
        // },
        },
      },

      {
        step: 'purchaseAmount',
        cardTitle: 'Tell us how many quantity you want to sell per year',
        min: 2,
        max: 7,
        customFieldsCount: 7,
        categories: {
          general: {
            title: 'We automatically add the option where they don\'t buy your product every year. \nIn the spaces below, select the 2-7 amount variations of your product:',
            subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
            options: [
              { label: 'I buy 1-2 dozen', value: '1-2-dozen' },
              { label: 'I buy more than 3 dozen', value: 'more-than-3-dozen' },
            ]
          }
        },
        tooltip: `<div>
                  <p>The attributes you choose here will be put in contrast towards each other. That means you can
                    select "it's blue" and another value "it's green", in the result you would get insights on which
                    one of the alternatives your customer are willing to pay more for</p>
                  <p>This means that you can learn what features and/or attributes truly drives the value of your
                    product. The insights are especially useful in product development and marketing purposes.</p>
                </div>`,
        placeholders: ["It's ...", 'It has ...', 'It is made of ...', "It's ..."],
      },

      {
        step: 'attribute1',
        cardTitle: 'Tell us about your product',
        min: 2,
        max: 7,
        customFieldsCount: 7,
        categories: {
          general: {
            title: 'In the spaces below, select the 2-7 main features/attributes of your product:',
            subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
            options: [
              { label: 'Brand name', value: 'brand_name' },
              { label: 'Feel', value: 'feel' },
              { label: 'Spin', value: 'spin' },
              { label: 'Distance', value: 'distance' },
              { label: 'Quality/Durability', value: 'quality_durability' },
              { label: 'Flight', value: 'flight' },
              { label: 'Price', value: 'price' },
            ]
          }
        },
        tooltip: `<div>
                  <p>The attributes you choose here will be put in contrast towards each other. That means you can
                    select "it's blue" and another value "it's green", in the result you would get insights on which
                    one of the alternatives your customer are willing to pay more for</p>
                  <p>This means that you can learn what features and/or attributes truly drives the value of your
                    product. The insights are especially useful in product development and marketing purposes.</p>
                </div>`,
        placeholders: ["It's ...", 'It has ...', 'It is made of ...', "It's ..."],
      },


      {
        step: 'attribute2',
        cardTitle: 'Tell us about your company',
        min: 2,
        max: 7,
        customFieldsCount: 7,
        categories: {
          general: {
            title: 'In the spaces below, select 2-7 reasons for why your product is unique.',
            subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
            options: [
              { label: 'Colour options', value: 'colour_options' },
              { label: 'Alignment Aid / Side stamps', value: 'alignment_aid_side_stamps' },
              { label: 'Ability', value: 'ability' },
            ]
          }
        },
        tooltip: 'This question is related to your company and what your customers and potential customers value in a company selling your product.',
        placeholders: ['The brand is ...', 'The company has ...', 'The company is ...', 'The brand has ...'],
      },
      {
        step: 'salesChannels',
        cardTitle: 'Tell us about where you sell or plan to sell your product',
        min: 2,
        max: 7,
        customFieldsCount: 7,
        categories: {
          general: {
            title: 'In the spaces below, select 2-7 locations where you will be selling your product',
            subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
            options: [
              { label: 'On Course Pro Shop', value: 'on_course_pro_shop' },
              { label: 'Off Course Golf Shop', value: 'off_course_golf_shop' },
              { label: 'Online from generic online retailers like Amazon', value: 'online_generic_retailers' },
              { label: 'Online from specialty golf online retailers', value: 'online_specialty_golf_retailers' },
              { label: 'Online direct from the Manufacturer', value: 'online_direct_manufacturer' },
            ]
          }
        },
        tooltip: `<div>
        <p>Select different vending sites that are relevant to your company and or service. You may use specific 
        brand names here, such as  <a target="_blank"  href="https://www.amazon.com/">Amazon.com</a>, but you should not use your own brand name.</p>
        <p>If you are a nail salon maybe you want to know if you want to focus on high end streets or malls, or perhaps compare different kinds of malls.</p>
        <p>In contrast, if you are a cloud base service, suitable options could be: social media platforms, blogs, influencers or web shop.</p>
        <p>Follow the prompts to create the selling points that best correlates with your service.</p>
      </div>`,
        placeholders: ["It's ...", 'It has ...', 'It is made of ...', "It's ..."],
      },
      // {
      //   step: 'competition',
      //   cardTitle: 'Tell us about your brand and your competition',
      //   min: 2,
      //   max: 7,
      //   customFieldsCount: 7,
      //   title: 'You & your competition - in the space below, insert your brand and from 1 to 6 of your most important competitors you would like to benchmark your brand against.',
      //   subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
      //   tooltip: `<div>
      //   <p>By setting these values you will get to know the pricing power of your brand. But you will also get the
      //     insight of your competitors pricing power.</p>
      //   <p>This would mean that for example, if you are Nike you could see how much more or less you can charge as
      //     compared to Adidas for white sneakers. </p>
      // </div>`,
      //   placeholders: [
      //     'Your brand ...',
      //     'Your #1 competitors brand ...',
      //     'Your #2 competitors brand ...',
      //     'Your #3 competitors brand ...',
      //   ],
      // },
      {
        step: 'productModels',
        cardTitle: 'Tell us about product models',
        min: 2,
        max: 7,
        customFieldsCount: 7,
        title: 'You & your competition - in the space below, insert your brand and from 1 to 6 of your most important competitors you would like to benchmark your brand against.',
        subTitle: 'If only some or none of the option suits you, you are free to create your own options, and/or mix.',
        tooltip: `<div>
        <p>By setting these values you will get to know the pricing power of your brand. But you will also get the
          insight of your competitors pricing power.</p>
        <p>This would mean that for example, if you are Nike you could see how much more or less you can charge as
          compared to Adidas for white sneakers. </p>
      </div>`,
        placeholders: [
          'Your product model #1 ...',
          'Your product model #2 ...',
          'Your #1 competitors product model ...',
          'Your #2 competitors product model ...',
          'Your #3 competitors product model ...',
        ],
      },
      {
        step: 'targetGroup',
        cardTitle: 'Tell us about your intended customers',
        title: 'Tell us more about your targeted customer',
        subTitle: 'Who are you selling to, and who you want to be selling to',
        tooltip: {
          gender: '<div><p>In this section we want to see who your intended customers are. Select the age and gender of who you want to answer what they are willing to pay for your product.</p><p>If you select from 25+ this means that will not get any responses from people below the age of 25.</p></div>',
          regions: '<div><p>In this section we want to know your targeted country and or regions. The broader you are the more options you will have on filtering once you get the results. This means that you can get great insights based on a geographical and regional level, which can allow you to focus sales efforts there.</p><p>However, if you are limited to a geographical region and cannot sell to certain parts of a country you should limit to the areas which you can service.</p></div>',
        },
      },
    ],
  }
};

export type GolfProjectFormConfigType = typeof golfProjectConfig;
export type GolfProjectProductConfigType = typeof golfProjectConfig['product'];

export type GolfProjectTypeStepConfigType = GolfProjectProductConfigType['steps'][number];
