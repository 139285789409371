import { useCallback } from 'react';
import { ForkOutlined, ProjectOutlined } from '@ant-design/icons';


export const useInterpolationVariables = () => useCallback(({ isForkDisabled = false }) => [
  {
    category: 'fork',
    Icon: ForkOutlined,
    disabled: isForkDisabled,
    excuse: 'Can only be used after the first forked question',
    children: [
      {
        display: 'Fork Answer',
        interpolation: '[fork.answer]',
        description: 'The answer for the last fork question',
      },
    ]
  },
  {
    category: 'project',
    Icon: ProjectOutlined,
    children: [
      {
        display: 'Item Name',
        interpolation: '[item.name]',
        description: 'Name of the project',
      },
    ]
  },
], []);
