import { FC, useMemo } from 'react';
import { Form } from 'antd';
import { Modal, ModalProps } from '../../atoms/Modal';
import { Button } from '../../atoms/Button';
import { FormItem } from '../../molecules/FormItem';
import { TextArea } from '../../atoms/TextArea';
import type { WTPContentToEditType } from '../../pages/AdminCreateProject/AdminCreateProject.types';


interface EditWTPContentModalProps extends ModalProps {
  handleSubmit: (values: WTPContentToEditType) => void;
  handleClose: () => void;
  WTPContentToEdit?: WTPContentToEditType;
}

export const EditWTPContentModal: FC<EditWTPContentModalProps> = ({
  open,
  handleSubmit,
  handleClose,
  WTPContentToEdit,
  ...rest
}) => {
  const [form] = Form.useForm();

  const onSubmit = (formValues: WTPContentToEditType) => {
    handleSubmit(formValues);
  };

  const actions = useMemo(() => (
    <>
      <Button
        variant="tertiary"
        style={{ width: 128 }}
        onClick={handleClose}
      >
        Cancel
      </Button>

      <Button
        type="primary"
        style={{ width: 128 }}
        onClick={form.submit}
      >
        Save
      </Button>
    </>
  ), [form.submit, handleClose]);

  return (
    <Modal
      title="Custom WTP"
      open={open}
      onCancel={handleClose}
      destroyOnClose
      actions={actions}
      width="60vw"
      {...rest}
    >
      <Form
        form={form}
        requiredMark={false}
        validateTrigger="onBlur"
        preserve={false}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={WTPContentToEdit}
        scrollToFirstError
      >
        <FormItem
          name="intro"
          label="Survey Intro"
          rules={[{ required: true, whitespace: true, message: 'Please enter Survey Intro!' }]}
        >
          <TextArea placeholder="Survey intro" rows={4} />
        </FormItem>

        <FormItem
          name="price.low.intro"
          label="WTP Intro"
          rules={[{ required: true, whitespace: true, message: 'Please enter wtp intro!' }]}
        >
          <TextArea placeholder="WTP intro" rows={4} />
        </FormItem>

        <FormItem
          name="price.low"
          label="WTP Price low"
          rules={[{ required: true, whitespace: true, message: 'Please enter low price wtp!' }]}
        >
          <TextArea placeholder="WTP Price low" rows={4} />
        </FormItem>

        <FormItem
          name="price.high"
          label="WTP Price high"
          rules={[{ required: true, whitespace: true, message: 'Please enter high price wtp!' }]}
        >
          <TextArea placeholder="WTP Price high" rows={4} />
        </FormItem>

        <FormItem
          name="price.midHigh"
          label="WTP Price midHigh"
          rules={[{ required: true, whitespace: true, message: 'Please enter midHigh price wtp!' }]}
        >
          <TextArea placeholder="WTP Price midHigh" rows={4} />
        </FormItem>

        <FormItem
          name="price.midLow"
          label="WTP Price midLow"
          rules={[{ required: true, whitespace: true, message: 'Please enter midLow price wtp!' }]}
        >
          <TextArea placeholder="WTP Price midLow" rows={4} />
        </FormItem>
      </Form>
    </Modal>
  );
};
