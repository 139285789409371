import axios from 'axios';
import type { AxiosRequestHeaders } from 'axios';


const externalApiAxios = axios.create();

export const uploadFileToS3SignedUrl = async (preSignedUrl: string, file: File):
Promise<string> => {
  const fileUrl = preSignedUrl.split('?')[0];

  const config = {
    headers: {
      'Content-Type': file.type,
    },
    transformRequest: (data: unknown, headers: AxiosRequestHeaders) => {
      if ('Authorization' in headers) {
        // eslint-disable-next-line no-param-reassign
        delete headers.Authorization;
      }

      return data;
    }
  };

  await externalApiAxios.put(preSignedUrl, file, config);

  return fileUrl;
};
