import axios from 'axios';
import { CategorySurveyConfig } from '../constants/surveyConfig';

// TODO: Create interface for titles and body only
export const getProjectSurveyConfig = (projectId: string): Promise<CategorySurveyConfig> => {
  const url = `/project-survey-config/${projectId}`;

  return axios.get(url);
};

export const createProjectSurveyConfig = (
  projectId: string,
  config: CategorySurveyConfig
): Promise<CategorySurveyConfig> => {
  const url = `/project-survey-config/${projectId}`;

  return axios.post(url, config);
};

export const updateProjectSurveyConfig = (projectId: string, config: CategorySurveyConfig) => {
  const url = `/project-survey-config/${projectId}`;

  return axios.patch(url, config);
};

export const deleteProjectSurveyConfig = (projectId: string) => {
  const url = `/project-survey-config/${projectId}`;

  return axios.delete(url);
};
