import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Popconfirm } from 'antd';
import { Modal, ModalProps } from '../../atoms/Modal';
import { Button } from '../../atoms/Button';
import useCheckTabletScreen from '../../../hooks/useTabletSize';


interface PreviewProjectSurveyModalProps extends ModalProps {
  handleSubmit: () => Promise<void>;
  handleClose: () => void;
  handleDraft?: () => void;
  isSubmitDisabled?: boolean;
  isPartnerPage?: boolean;
}

export const PreviewProjectSurveyModal: FC<PreviewProjectSurveyModalProps> = ({
  open,
  handleClose,
  handleSubmit,
  children,
  handleDraft,
  isSubmitDisabled,
  isPartnerPage
}) => {
  const isTablet = useCheckTabletScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);

  const handleDraftButton = useCallback(async () => {
    setIsDraftLoading(true);

    await handleDraft?.();

    setIsDraftLoading(false);
  }, [handleDraft]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);

    await handleSubmit();

    setIsLoading(false);
  }, [handleSubmit]);

  const actions = useMemo(() => {
    return (
      <>
        <Button
          variant="tertiary"
          style={{ width: 128 }}
          disabled={isLoading || isDraftLoading}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <div style={{ display: 'flex' }}>
          {handleDraft && !isPartnerPage ? (
            <Button
              variant="secondary"
              onClick={handleDraftButton}
              disabled={isDraftLoading}
              loading={isDraftLoading}
            >
              Save as draft
            </Button>
          ) : null}

          { !isPartnerPage ? (
            <Popconfirm
              title="You are about to submit your survey."
              description="Are you done with your survey description?
                Once you've submitted a survey you cannot make any changes."
              onConfirm={onSubmit}
              okText="Yes"
              cancelText="No"
              cancelButtonProps={{
                style: {
                  width: 60,
                  height: 36
                }
              }}
              okButtonProps={{
                style: {
                  width: 60,
                  height: 36,
                  margin: '20px 20px',
                },
              }}
            >
              <Button
                type="primary"
                loading={isLoading}
                disabled={isSubmitDisabled || isDraftLoading}
              >
                Submit & Go to dashboard
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="primary"
              loading={isLoading}
              disabled={isSubmitDisabled || isDraftLoading}
              onClick={onSubmit}
            >
              Submit for review
            </Button>
          )}
        </div>
      </>
    );
  }, [
    handleClose,
    handleDraft,
    handleDraftButton,
    isDraftLoading,
    isLoading,
    isPartnerPage,
    isSubmitDisabled,
    onSubmit
  ]);

  return (
    <Modal
      title="Preview survey"
      open={open}
      onCancel={handleClose}
      destroyOnClose
      width={isTablet ? '90vw' : '50vw'}
      bodyStyle={{ maxHeight: '70vh', overflowY: 'auto', paddingTop: 12 }}
      centered
      actions={actions}
      maskClosable={!isLoading || !isDraftLoading}
      closable={!isLoading || !isDraftLoading}
      keyboard={!isLoading || !isDraftLoading}
    >
      {children}
    </Modal>
  );
};
