import type { FC } from 'react';
import { FormItemProps, Typography } from 'antd';
import { Industry } from '../../../../../constants/projectFormConfig';
import { CheckboxGroupWithCustomFieldsFormField } from '../../../CheckboxGroupWithCustomFieldsFormField';


interface IndustryStepFormItemProps extends FormItemProps {
  cardTitle: string;
  label?: string;
  subTitle?: string;
  options?: Industry[];
}

export const IndustryStepFormItem: FC<IndustryStepFormItemProps> = ({
  cardTitle, label, subTitle, options, ...rest
}) => {
  const { Title } = Typography;

  return (
    <>
      <Title level={3}>{cardTitle}</Title>
      <CheckboxGroupWithCustomFieldsFormField
        name={['item', 'industry']}
        label={label}
        options={options}
        subTitle={subTitle}
        tooltip={{
          title: label,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '10px 16px' }
        }}
        rules={[{
          required: true, type: 'array', min: 2, message: 'Please select at least 2 industries!',
        }]}
        largeLabel
        errorMessageJustify="start"
        {...rest}
      />
    </>

  );
};
