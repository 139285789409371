import styled from 'styled-components';
import { Card } from 'antd';


export const StyledCard = styled(({ width, padding, ...rest }) => <Card {...rest} />)`
  && {
    background: ${({ theme }) => theme.currentModeConfig.contentBackground};
    box-shadow: ${({ theme }) => theme.shadow.mildShadow};
    width: ${({ width }) => width};
    padding: ${({ padding }) => padding};
    margin-bottom: 16px;
  }

  && .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;
