import { useMemo } from 'react';
import type { AdminProjectFormStep } from '../components/pages/AdminCreateProject/AdminCreateProject.types';


const FORK_QUESTIONS_LIMIT = 1;

export const useForkQuestion = (steps: AdminProjectFormStep[]) => {
  const forkQuestions = Array.from(steps.entries())
    .filter(([, { isFork } = { isFork: false }]) => isFork);

  const shouldIsForkBeBlocked = useMemo(() => {
    const isForkLimitReached = forkQuestions.length === FORK_QUESTIONS_LIMIT;
    const forkQuestionsIndexes = forkQuestions.map(([idx]) => idx);
    return (fieldIdx: number) => {
      return isForkLimitReached && !forkQuestionsIndexes.includes(Number(fieldIdx));
    };
  }, [forkQuestions]);
  const firstForkQuestionIdx = forkQuestions[0]?.[0];

  return { firstForkQuestionIdx, shouldIsForkBeBlocked };
};
