import styled from 'styled-components';
import { Collapse } from 'antd';
import { Button } from '../../atoms/Button';


export const StyledRegionCollapseTrigger = styled(({ active, ...props }) => <Button {...props} />)`
  && {
    background: ${({ theme, active }) => active && theme.color.greyscale.grey20};
    max-width: 100%;
    gap: 10px;
    padding-inline: 14px;
    height: 44px;
  }

  & > span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > svg {
    min-width: 24px;
  }
`;

export const StyledRegionSelectPopupContainer = styled.div`
  & .ant-select-dropdown {
    // NOTE: Adding more & doesn't work
    position: static !important;
    top: unset;

    box-shadow: none;
    border-top: 1px solid ${({ theme }) => theme.color.greyscale.grey30};
    border-radius: 0;
    padding-top: 20px;
  }

  && .ant-select-item, .ant-select-item-option-selected {
    font-weight: 400;
    border: 1px solid ${({ theme }) => theme.color.greyscale.grey20};
  }

  & > div {
    // NOTE: Adding more & doesn't work
    position: relative !important;
  }

  & .rc-virtual-list-holder-inner {
    // NOTE: Adding more & doesn't work
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 6px;
  }
`;

export const StyledTargetGroupStepFormItemRegionCollapse = styled(Collapse)`
  &&& {
    max-width: 500px;
    border: none;

    .ant-collapse-item {
      border: none;
    }

    .ant-collapse-header {
      display: none;
    }

    .ant-collapse-content {
      border: none;
    }

    .ant-collapse-content-active {
      border: 1px solid ${({ theme }) => theme.color.greyscale.grey40};
      border-radius: 2px;
    }
  }
`;
