import React, { useCallback, useMemo } from 'react';
import { createEditor, type Element } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { Form } from 'antd';
import omit from 'lodash/omit';
import {
  StyledEditableContainer,
  StyledInterpolationInputPlaceholder,
  StyledEditable,
} from './InterpolationInput.styles';
import { Variable } from './Variable';
import { VariablesMenu } from './VariablesMenu';
import { getInitialValue, withInterpolation } from './utils';
import { NodeType } from './enums';
import type { InterpolationCategory, InterpolationVariable } from './types';


export interface InterpolationInputProps {
  variables: Array<InterpolationCategory>;
  placeholder: string;
  onChange?: (v: string) => void;
  value?: string;
}

export const InterpolationInput: React.FC<InterpolationInputProps> = ({
  variables,
  placeholder,
  onChange,
  value,
}) => {
  const { status } = Form.Item.useStatus();
  const isInvalid = status === 'error';
  const editor = useMemo(() => withInterpolation(withHistory(withReact(createEditor()))), []);
  // eslint-disable-next-line
  const initialValue = useMemo(() => getInitialValue(variables, value), []);

  const insertNode = useCallback(({
    interpolation,
    description,
    display,
  }: InterpolationVariable) => editor.insertNodes([
    {
      type: NodeType.Interpolation,
      display,
      description,
      children: [{ text: interpolation }],
    },
    {
      text: '',
    },
  ] as Array<Element>), [editor]);

  const handleOnValueChange = useCallback(
    () => onChange?.(editor.string([0], { voids: true })),
    [editor, onChange],
  );

  return (
    <StyledEditableContainer>
      <Slate
        editor={editor}
        initialValue={initialValue}
        onValueChange={handleOnValueChange}
      >
        <StyledEditable
          $invalid={isInvalid}
          placeholder={placeholder}
          renderPlaceholder={({ children, attributes }) => (
            <StyledInterpolationInputPlaceholder {...omit(attributes, 'style.top')}>
              {children}
            </StyledInterpolationInputPlaceholder>
          )}
          renderElement={({ element, children, attributes }) => {
            switch (element.type) {
              case NodeType.Interpolation:
                return (
                  <Variable
                    element={element}
                    attributes={attributes}
                  >
                    {children}
                  </Variable>
                );
              default:
                return (
                  <span {...attributes}>
                    {children}
                  </span>
                );
            }
          }}
        />
        <VariablesMenu variables={variables} onSelect={insertNode} />
      </Slate>
    </StyledEditableContainer>
  );
};
