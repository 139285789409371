import styled from 'styled-components';
import { Button } from '../Button';


export const StyledIconButton = styled(Button)`
  &&& {
    padding: 5px;
    min-height: 30px;
    height: 30px;
    min-width: 30px;
    width: 30px;
  }
`;
