import styled from 'styled-components';
import { Badge, Typography } from 'antd';


export const StyledProjectModeSelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 40px;
`;

export const StyledProjectModeSelectorTitle = styled(Typography.Title)`
  font-family: Inter, sans-serif;
  text-align: center;
`;

export const StyledProjectModeSelectorText = styled(Typography.Text)`
  font-family: Inter, sans-serif;
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
`;

export const StyledProjectModeSelectorRibbon = styled(Badge.Ribbon)`
  margin-top: -8px;
`;
