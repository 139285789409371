import { FC } from 'react';
import { CheckboxGroupProps as AntDCheckboxGroupProps } from 'antd/lib/checkbox';
import { StyledCheckboxGroup } from './CheckboxGroup.styles';


interface CheckboxGroupProps extends AntDCheckboxGroupProps {
  selectedValues?: string[];
}

export const CheckboxGroup: FC<CheckboxGroupProps> = ({ options, selectedValues, ...rest }) => (
  <StyledCheckboxGroup options={options} {...rest} />
);
