import type {
  Dispatch, FC, ReactNode, SetStateAction,
} from 'react';
import {
  createContext, useCallback, useContext, useEffect, useState
} from 'react';
import {
  AuthCredential,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  linkWithRedirect,
  reauthenticateWithCredential,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithEmailLink,
  // signInWithRedirect,
  signOut,
  unlink,
  updatePassword,
  signInAnonymously,
  signInWithPopup,
  updateEmail
} from 'firebase/auth';
import type { User, UserCredential } from 'firebase/auth';
import Cookies from 'js-cookie';
import { auth, googleAuthProvider } from '../firebase';
import { IUser } from '../types/user.types';
import { getSelf } from '../api/user';
import { sendEmailVerificationEmail, sendInviteEmail, sendPasswordResetEmail } from '../api/email-sender';
import { SubscriptionPlanQuota } from '../types/stripe';
import { getAvailableSubscriptionPlanQuota } from '../api/stripe';


interface AuthContextValue {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
  refreshFirebaseUserState: () => void;
  firebaseUser: User | null;
  isUserLoading?: boolean;
  setIsUserLoading: Dispatch<SetStateAction<boolean>>;
  signUpWithEmailAndPassword: ({ email, password }: { email: string; password: string }) => Promise<UserCredential>;
  signInWithEmailAndPassword: ({ email, password }: { email: string; password: string }) => Promise<UserCredential>;
  sendEmailSignInInvite: (emailOptions: { name: string; email: string; redirectTo?: string }) => Promise<void>;
  signInByEmailLink: ({ email, currentUrl }: { email: string; currentUrl: string }) => Promise<UserCredential>;
  signInWithGoogle: () => Promise<UserCredential>;
  logout: () => void;
  reauthenticateWithEmailCredential: (credential: AuthCredential) => Promise<UserCredential | undefined>;
  updateUserPassword: (password: string, user?: User | null) => Promise<void>;
  updateUserEmail: (email: string, user?: User | null) => Promise<void>;
  linkGoogleSignIn: (user?: User | null) => Promise<void>;
  forgotPassword: (options: { email: string; redirectTo?: string }) => Promise<void>;
  linkEmailAndPasswordSignIn: ({
    user,
    email,
    password,
  }: {
    user?: User | null;
    email: string;
    password: string;
  }) => Promise<UserCredential | void>;
  sendEmailVerification: (redirectTo?: string) => Promise<void>;
  subscriptionPlanQuota?: SubscriptionPlanQuota;
  isSubscriptionPlanQuotaLoading?: boolean;
  fetchSubscriptionPlanQuota: () => Promise<SubscriptionPlanQuota | undefined>;
  signInAnonymousUser: () => Promise<void>;
  signInWithCustomToken: (token: string) => Promise<UserCredential | undefined>;
}

const AuthContext = createContext({} as AuthContextValue);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  // const [company, setCompany] = useState<ICompany | null>(null);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const [subscriptionPlanQuota, setSubscriptionPlanQuota] = useState<SubscriptionPlanQuota>();
  const [isSubscriptionPlanQuotaLoading, setIsSubscriptionPlanQuotaLoading] = useState(false);

  // Note: If the access token expires, a new one will be passed here
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (_firebaseUser) => {
      setIsUserLoading(true);
      setFirebaseUser(_firebaseUser);
      if (_firebaseUser) {
        try {
          const accessToken = await _firebaseUser.getIdToken();
          Cookies.set('auth-token', accessToken);

          const self = await getSelf();
          setUser(self);
        } catch (err) {
          console.debug('err', err);
        }
      }
      setIsUserLoading(false);
    });

    return () => unsubscribe();
  }, []);
  // Email and password methods
  const signUpWithEmailAndPassword = async ({
    email,
    password
  }: { email: string; password: string }) => createUserWithEmailAndPassword(auth, email, password);

  const signInWithEmailAndPassword = async ({
    email,
    password
  }: { email: string; password: string }) => firebaseSignInWithEmailAndPassword(
    auth,
    email,
    password
  );

  const signInWithCustomToken = async (token: string) => firebaseSignInWithCustomToken(auth, token);

  const sendEmailVerification = async (redirectTo = '/login') => {
    return sendEmailVerificationEmail(redirectTo);
  };

  const refreshFirebaseUserState = () => {
    const currentFirebaseUser = auth.currentUser;

    if (currentFirebaseUser) {
      setFirebaseUser({ ...currentFirebaseUser });
    }
  };

  const sendEmailSignInInvite = async ({
    name,
    email,
    redirectTo = '/invitation-registration'
  }: { name: string; email: string; redirectTo?: string }) => {
    return sendInviteEmail({
      name,
      email,
      redirectTo
    });
  };

  const signInByEmailLink = async ({
    email,
    currentUrl
  }: { email: string; currentUrl: string }) => signInWithEmailLink(auth, email, currentUrl);

  const linkGoogleSignIn = async (_user: User | null = firebaseUser) => {
    if (_user) {
      await linkWithRedirect(_user, googleAuthProvider);
    }
  };

  const forgotPassword = ({
    email,
    redirectTo = '/login'
  }: { email: string; redirectTo?: string }) => {
    return sendPasswordResetEmail(email, redirectTo);
  };

  const linkEmailAndPasswordSignIn = async ({
    user: _user = firebaseUser,
    email,
    password,
  }: {
    user?: User | null;
    email: string;
    password: string;
  }) => {
    if (_user) {
      try {
        await unlink(_user, 'password');
      } catch (err) {
        console.log('err', err);
      }

      const credential = EmailAuthProvider.credential(email, password);

      return linkWithCredential(_user, credential);
    }

    return;
  };

  const reauthenticateWithEmailCredential = async (
    credential: AuthCredential,
    _user: User | null = firebaseUser
  ) => {
    if (_user) {
      // TODO: Reset cookies?
      return reauthenticateWithCredential(_user, credential);
    }

    return undefined;
  };

  const updateUserPassword = async (password: string, _user: User | null = firebaseUser) => {
    if (_user) {
      // TODO: Reset cookies?
      await updatePassword(_user, password);
    }
  };

  const updateUserEmail = async (email: string, _user: User | null = firebaseUser) => {
    if (_user) {
      await updateEmail(_user, email);
    }
  };

  const signInAnonymousUser = async () => {
    await signInAnonymously(auth);
  };

  // Google methods
  const signInWithGoogle = async () => signInWithPopup(auth, googleAuthProvider);

  const logout = () => {
    Cookies.remove('auth-token');
    setFirebaseUser(null);
    setUser(null);
    return signOut(auth);
  };

  const fetchSubscriptionPlanQuota = useCallback(async () => {
    setIsSubscriptionPlanQuotaLoading(true);
    try {
      const quota = await getAvailableSubscriptionPlanQuota();

      setSubscriptionPlanQuota(quota);

      return quota;
    } catch (err) {
      console.log('err', err);
      return;
    } finally {
      setIsSubscriptionPlanQuotaLoading(false);
    }
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: AuthContextValue = {
    user,
    setUser,
    refreshFirebaseUserState,
    firebaseUser,
    isUserLoading,
    setIsUserLoading,
    signUpWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithGoogle,
    sendEmailSignInInvite,
    signInByEmailLink,
    logout,
    reauthenticateWithEmailCredential,
    updateUserPassword,
    updateUserEmail,
    linkGoogleSignIn,
    forgotPassword,
    linkEmailAndPasswordSignIn,
    sendEmailVerification,
    subscriptionPlanQuota,
    isSubscriptionPlanQuotaLoading,
    fetchSubscriptionPlanQuota,
    signInAnonymousUser,
    // Admin
    signInWithCustomToken
  };

  // const contextValue: AuthContextValue = useMemo(() => ({
  //   user,
  //   setUser,
  //   firebaseUser,
  //   isUserLoading,
  //   signUpWithEmailAndPassword,
  //   signInWithEmailAndPassword,
  //   signInWithGoogle,
  //   sendEmailSignInInvite,
  //   signInByEmailLink,
  //   logout,
  //   reauthenticateWithEmailCredential,
  //   updateUserPassword,
  //   linkGoogleSignIn,
  //   linkEmailAndPasswordSignIn,
  // }), [
  //   user,
  //   firebaseUser,
  //   isUserLoading,
  //   signUpWithEmailAndPassword,
  //   signInWithEmailAndPassword,
  //   signInWithGoogle,
  //   sendEmailSignInInvite,
  //   signInByEmailLink,
  //   logout,
  //   reauthenticateWithEmailCredential,
  //   updateUserPassword,
  //   linkGoogleSignIn,
  //   linkEmailAndPasswordSignIn,
  // ]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
