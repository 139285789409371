import { FC, /* useCallback, useEffect, useState */ } from 'react';
import {
  Col, Divider, /* Input as AntDInput, */ Row, Space
} from 'antd';
import { TargetGroupStepFormItem } from '../../../../organisms/AddProjectForm/steps';
import { FormItem } from '../../../../molecules/FormItem';
import { InputNumber } from '../../../../atoms/InputNumber';
import { Select } from '../../../../atoms/Select';
import type { CINTCountry, ProfilingQuestion } from '../../../../../types/cint';
import {
  SettingsFormItem
} from '../../../../organisms/AddProjectForm/steps/TypeStepFormItem/SettingsFormItem/SettingsFormItem';


interface AdminTargetGroupStepFormItemProps {
  CINTCountries?: CINTCountry[];
  resetRegionState?: (props?: { keepRegionType?: boolean }) => void;
  fetchProfessionalRoleProfilingQuestion: (countryId: number) => Promise<void>;
  suggestedRespondentsLimit: number;
  isPartnerPage?: boolean;
  isProfilingQuestionLoading: boolean;
  professionalRoleProfilingQuestion?: {
    professionalRoleQuestion: ProfilingQuestion;
    industrySectorQuestion: ProfilingQuestion
  };
  cardTitle?: string;
  label?: string;
  subTitle?: string;
}

export const AdminTargetGroupStepFormItem: FC<AdminTargetGroupStepFormItemProps> = ({
  CINTCountries,
  resetRegionState,
  fetchProfessionalRoleProfilingQuestion,
  suggestedRespondentsLimit,
  isPartnerPage,
  isProfilingQuestionLoading,
  professionalRoleProfilingQuestion,
  cardTitle,
  label,
  subTitle
}) => {
  return (
    <>
      <TargetGroupStepFormItem
        tooltip={{ gender: '', regions: '' }}
        cardTitle={cardTitle}
        label={label}
        subTitle={subTitle}
        countries={CINTCountries}
        resetRegionState={resetRegionState}
        onCountryValueChange={fetchProfessionalRoleProfilingQuestion}
      />

      <Row gutter={100}>
        <Col flex="50%">
          <SettingsFormItem />
        </Col>
      </Row>

      <Row gutter={100}>
        <Col flex="50%">
          <FormItem
            label="Amount of Respondents"
            largeLabel
            name="respondentsLimit"
            tooltip={{
              title: `The recommended amount is based on
                     your maximum amount of response alternative for a question`,
              arrow: true,
              placement: 'left',
              overlayInnerStyle: { padding: '2px 16px' }
            }}
            help={suggestedRespondentsLimit ? (
              <>
                Suggested count:
                {' '}
                <b>{suggestedRespondentsLimit}</b>
              </>
            ) : null}
          >
            <InputNumber
              placeholder="Respondents count"
              type="number"
              style={{ minWidth: 200 }}
              step={1}
              min={1}
            />
          </FormItem>
        </Col>

        <Col flex="50%">
          {!isPartnerPage ? (
            <FormItem
              label="Custom CPI"
              largeLabel
              name="cpi"
              tooltip={{
                title: 'Customize the survey CPI',
                arrow: true,
                placement: 'left',
                overlayInnerStyle: { padding: '2px 16px' }
              }}
              help={(
                <>
                  Default CPI:
                  {' '}
                  <b>SEK 25</b>
                </>
                    )}
            >
              <InputNumber
                placeholder="Custom CPI"
                type="number"
                style={{ minWidth: 200 }}
                step={1}
                min={1}
              />
            </FormItem>
          ) : null}
        </Col>
      </Row>

      <Divider plain>For B2B projects</Divider>

      <Space.Compact>
        <Row gutter={100}>
          <Col flex="50%">
            <FormItem
              label="What professional roles are you targeting?"
              largeLabel
              name="professionalRolesVariableIds"
              tooltip={{
                title: 'What professional roles are you targeting?',
                arrow: true,
                placement: 'left',
                overlayInnerStyle: { padding: '2px 16px' }
              }}
            >
              <Select
                mode="multiple"
                placeholder="Select professional roles"
                fieldNames={{
                  label: 'name',
                  value: 'id'
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                options={professionalRoleProfilingQuestion?.professionalRoleQuestion?.variables ?? []}
                style={{ minWidth: 400 }}
                selectorMaxWidth="100%"
                loading={isProfilingQuestionLoading}
                disabled={!professionalRoleProfilingQuestion}
                showSearch
                filterOption={
                        (input, option) => {
                          if (typeof option?.name !== 'string') {
                            return false;
                          }
                          return option?.name.toLowerCase()
                            .includes(input.toLowerCase());
                        }
                      }
              />
            </FormItem>
          </Col>

          <Col flex="50%">
            <FormItem
              label="What industry sectors are you targeting?"
              largeLabel
              name="industrySectorVariableIds"
              tooltip={{
                title: 'What industry sectors are you targeting?',
                arrow: true,
                placement: 'left',
                overlayInnerStyle: { padding: '2px 16px' }
              }}
            >
              <Select
                mode="multiple"
                placeholder="Select industry sectors"
                fieldNames={{
                  label: 'name',
                  value: 'id'
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                options={professionalRoleProfilingQuestion?.industrySectorQuestion?.variables ?? []}
                style={{ minWidth: 400 }}
                selectorMaxWidth="100%"
                loading={isProfilingQuestionLoading}
                disabled={!professionalRoleProfilingQuestion}
                showSearch
                filterOption={
                    (input, option) => {
                      if (typeof option?.name !== 'string') {
                        return false;
                      }
                      return option?.name.toLowerCase()
                        .includes(input.toLowerCase());
                    }
                  }
              />
            </FormItem>
          </Col>
        </Row>
      </Space.Compact>
    </>
  );
};
