import { FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  Col, Row, Space, Switch, Typography,
} from 'antd';
// import { GrammarlyEditor } from 'helpers/GrammarlyEditor';
import { Input } from '../../../../atoms/Input';
import { FormItem } from '../../../../molecules/FormItem';
import { TextArea } from '../../../../atoms/TextArea';
import { Select } from '../../../../atoms/Select';
import { InputNumber } from '../../../../atoms/InputNumber';
import { lowerCaseFirstLetter } from '../../../../../helpers/lowerCaseFirstLetter';
import { CURRENCIES_OPTIONS, QUANTITY_OPTIONS } from './constants';


interface FieldConfig {
  title: string;
  subTitle?: string;
  placeholder?: string;
  tooltip: string;
}

interface DescriptionStepFormItemProps {
  cardTitle: string,
  name?: FieldConfig;
  description?: FieldConfig;
  usageFrequency?: FieldConfig;
  productQuantity?: FieldConfig;
  isQuantityVisible?: boolean;
  price?: FieldConfig & {
    paymentModels?: { label: string; value: string }[];
  };
  displayMaxPrice?: boolean;
  // schemaVersion: Project['schemaVersion'];
}

export const DescriptionStepFormItem: FC<DescriptionStepFormItemProps> = ({
  cardTitle,
  name,
  description,
  price,
  usageFrequency,
  productQuantity,
  isQuantityVisible,
  displayMaxPrice,
  // schemaVersion
}) => {
  const { Title } = Typography;

  const paymentModelsOptions = useMemo(() => price?.paymentModels?.map((paymentModel) => ({
    label: paymentModel.label,
    value: JSON.stringify(paymentModel),
  })), [price?.paymentModels]);

  const renderNameMarkdown = useMemo(
    () => <ReactMarkdown rehypePlugins={[rehypeRaw]}>{name ? name.tooltip : ''}</ReactMarkdown>,
    [name]
  );

  const renderDescriptionMarkdown = useMemo(
    () => <ReactMarkdown rehypePlugins={[rehypeRaw]}>{description ? description.tooltip : ''}</ReactMarkdown>,
    [description]
  );

  // const shouldRenderAimSwitch = schemaVersion === 2;

  return (
    <>
      <Title level={3}>{cardTitle}</Title>
      {/* <GrammarlyEditor> */}
      <FormItem
        name={['item', 'name']}
        label={name?.title}
        rules={[{ required: true, whitespace: true, message: 'Please enter item name!' }]}
        tooltip={{
          title: renderNameMarkdown,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        subTitle={name?.subTitle}
        largeLabel
        normalize={(value) => lowerCaseFirstLetter(value)}
      >
        <Input placeholder={name?.placeholder} />
      </FormItem>
      {/* </GrammarlyEditor> */}

      {/* <GrammarlyEditor> */}
      <FormItem
        name={['item', 'description']}
        label={description?.title}
        rules={[{ required: true, whitespace: true, message: 'Please enter item description!' }]}
        tooltip={{
          title: renderDescriptionMarkdown,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        subTitle={description?.subTitle}
        largeLabel
        normalize={(value) => lowerCaseFirstLetter(value)}
      >
        <TextArea placeholder={description?.placeholder} rows={4} />
      </FormItem>
      {/* </GrammarlyEditor> */}

      <FormItem
        label={price?.title}
        tooltip={{
          title: <p>{price?.tooltip}</p>,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        largeLabel
      >
        <Space.Compact>
          <Row gutter={8}>
            <Col>
              <FormItem
                name={['item', 'price', 'amount']}
                rules={[{
                  required: true, type: 'number', min: 1, message: 'Please enter item price!',
                }]}
              >
                <InputNumber placeholder="Price" type="number" min={1} />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                name={['item', 'price', 'currency']}
                rules={[{ required: true, message: 'Please enter price currency!' }]}
              >
                <Select placeholder="Currency" options={CURRENCIES_OPTIONS} />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                name={['item', 'price', 'paymentModel']}
                rules={[{ required: true, message: 'Please enter payment model!' }]}
              >
                <Select placeholder="Payment model" options={paymentModelsOptions} />
              </FormItem>
            </Col>
          </Row>
        </Space.Compact>
      </FormItem>

      {displayMaxPrice ? (
        <FormItem
          name={['item', 'price', 'maxPrice']}
          label="(Optional) What is the max price for the survey?"
          tooltip={{
            title: (
              <p>
                This value will be used in disqualifying the respondents if they enter a price greater than this value
              </p>
            ),
            arrow: true,
            placement: 'left',
            overlayInnerStyle: { padding: '2px 16px' }
          }}
          largeLabel
        >
          <InputNumber placeholder="Max Price" type="number" min={1} style={{ width: 120 }} />
        </FormItem>
      ) : null}

      {isQuantityVisible ? (
        <FormItem
          label={productQuantity?.title}
          largeLabel
          tooltip={{
            title: <p>{productQuantity?.tooltip}</p>,
            arrow: true,
            placement: 'left',
            overlayInnerStyle: { padding: '2px 16px' }
          }}
        >
          <Space.Compact>
            <Row gutter={8}>
              <Col>
                <FormItem name={['item', 'quantity', 'amount']}>
                  <InputNumber placeholder="Quantity" type="number" min={1} style={{ width: 120 }} />
                </FormItem>
              </Col>
              <Col>
                <FormItem name={['item', 'quantity', 'measurement']}>
                  <Select placeholder="Measurement" options={QUANTITY_OPTIONS} />
                </FormItem>
              </Col>
            </Row>
          </Space.Compact>
        </FormItem>
      ) : null}

      {usageFrequency ? (
        <FormItem
          name={['item', 'usageFrequency']}
          label={usageFrequency?.title}
          tooltip={{
            title: <p>{usageFrequency?.tooltip}</p>,
            arrow: true,
            placement: 'left',
            overlayInnerStyle: { padding: '2px 16px' }
          }}
          valuePropName="checked"
          largeLabel
        >
          <Switch />
        </FormItem>
      ) : null}

      {/* {shouldRenderAimSwitch ? ( */}
      {/*   <FormItem */}
      {/*     name="aim" */}
      {/*     label="Find out if I should change from a once per unit payment to a subscription model" */}
      {/*     normalize={(isChecked) => { */}
      {/*       if (isChecked) { */}
      {/*         return 'change_payment_model'; */}
      {/*       } */}
      {/*       return undefined; */}
      {/*     }} */}
      {/*     valuePropName="checked" */}
      {/*     largeLabel */}
      {/*   > */}
      {/*     <Switch /> */}
      {/*   </FormItem> */}
      {/* ) : null} */}

    </>
  );
};
