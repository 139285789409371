import styled from 'styled-components';
import { Checkbox } from 'antd';


export const StyledFilterCheckbox = styled(Checkbox)`
  && {
    display: inline-flex;
    flex-direction: row;
    margin-inline-start: 0;
    
    .ant-checkbox {
      &-inner {
        border-radius: 2px;
        border-color: #7B7B7C;
        background: transparent;
        
        &:after {
          border-color: #7B7B7C;
        }
      }

      &:after {
        display: none;
      }
    }

    &:hover {
      && .ant-checkbox-inner {
        background: transparent;
        border-color: #7B7B7C;
      }
    }
    
    &:after {
      display: none;
    }
  }
`;
