import styled from 'styled-components';
import { InputNumber } from 'antd';


export const StyledInputNumber = styled(InputNumber)`
  && {
    height: 44px;
    padding: 6px 10px;
    font-weight: 400;
    font-size: 14px;
    box-shadow: none;
    background: transparent;
    border-color: ${({ theme }) => theme.currentModeConfig.inputBorderColor};

    & .ant-input-number-input {
      font-family: Inter, sans-serif;
    }

    &:hover {
      cursor: text;
    }

    &&:focus-within, &&:focus {
      border-color: ${({ theme }) => theme.color.primary.blue60};
      caret-color: ${({ theme }) => theme.color.primary.blue80};
      box-shadow: none;
    }

    &&:focus-within svg {
      fill: ${({ theme }) => theme.color.primary.blue80};
    }
  }
`;
