import type { FC } from 'react';
import {
  StyledProjectModeSelectorContainer,
} from './ProjectModeSelector.styles';
import {
  ProjectModeSelectorCard,
  type ProjectModeSelectorCardProps,
} from './ProjectModeSelectorCard';


export interface ProjectWizardModeSelectorProps {
  modes: Array<WithKey<ProjectModeSelectorCardProps>>;
}

export const ProjectModeSelectorBase: FC<ProjectWizardModeSelectorProps> = ({ modes }) => (
  <StyledProjectModeSelectorContainer>
    {
      modes.map((mode) => (
        <ProjectModeSelectorCard
          key={mode.key}
          title={mode.title}
          description={mode.description}
          actions={mode.actions}
          ribbonProps={mode.ribbonProps}
        />
      ))
    }
  </StyledProjectModeSelectorContainer>
);
