import { FeedbackFish } from '@feedback-fish/react';
import { useAuth } from '../../../contexts/AuthContext';
import { Button } from '../../atoms/Button';


export const FeedbackButton = () => {
  const { user } = useAuth();
  const projectId = process.env.REACT_APP_FBFISH_PROJECT_ID;

  if (!user || !projectId) return null;

  return (
    <FeedbackFish projectId={projectId} userId={user.email}>
      <Button
        variant="primary"
        style={{ alignSelf: 'start' }}
      >
        Give us feedback
      </Button>
    </FeedbackFish>
  );
};
