import type { FC } from 'react';
import { RadioGroup } from '../../../../molecules/RadioGroup';
import { FormItem } from '../../../../molecules/FormItem';


interface AimStepFormItemProps {
  label?: string;
  options?: { label: string; value: string | number; }[];
}

export const AimStepFormItem: FC<AimStepFormItemProps> = ({ label, options }) => (
  <FormItem
    name="aim"
    label={label}
    rules={[{ required: true, whitespace: true }]}
    tooltip={{
      title: label,
      arrow: true,
      placement: 'left',
      overlayInnerStyle: { padding: '10px 16px' }
    }}
    largeLabel
  >
    <RadioGroup
      options={options}
    />
  </FormItem>
);
