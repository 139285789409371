export const CURRENCIES_OPTIONS = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EURO' },
  { value: 'NOK', label: 'NOK' },
  { value: 'SEK', label: 'SEK' },
  { value: 'DKK', label: 'DKK' },
  { value: 'AUD', label: 'AUD' },
  { value: 'GBP', label: 'GBP' },
];

export const QUANTITY_OPTIONS = [
  { value: 'pounds', label: 'Pounds (lbs)' },
  { value: 'ounces', label: 'Ounces (oz)' },
  { value: 'kilograms', label: 'Kilograms (kg)' },
  { value: 'grams', label: 'Grams (g)' },
  { value: 'feet', label: 'Feet (ft)' },
  { value: 'yards', label: 'Yards (yd)' },
  { value: 'miles', label: 'Miles (mi)' },
  { value: 'meters', label: 'Meters (m)' },
  { value: 'centimeters', label: 'Centimeters (cm)' },
  { value: 'kilometers', label: 'Kilometers (km)' },
  { value: 'fluid_ounces', label: 'Fluid ounces (fl oz)' },
  { value: 'cups', label: 'Cups (c)' },
  { value: 'pints', label: 'Pints (pt)' },
  { value: 'quarts', label: 'Quarts (qt)' },
  { value: 'gallons', label: 'Gallons (gal)' },
  { value: 'milliliters', label: 'Milliliters (ml)' },
  { value: 'centiliter', label: 'Centiliter (cl)' },
  { value: 'liters', label: 'Liters (l)' },
  { value: 'pair', label: 'A pair' },
  { value: 'dozen', label: 'A dozen' },
  { value: 'two_pack', label: '2 pack' },
  { value: 'four_pack', label: '4 pack' },
  { value: 'pack', label: 'pack' },
  { value: '%', label: '%' },
];
