import type { FC } from 'react';
import { type RenderElementProps, useFocused, useSelected } from 'slate-react';
import { Tooltip } from 'antd';
import { StyledInterpolationTag } from './InterpolationInput.styles';


export const Variable: FC<RenderElementProps> = ({ attributes, children, element }) => {
  const isFocused = useFocused();
  const isSelected = useSelected();

  return (
    <Tooltip title={element.description}>
      <StyledInterpolationTag
        {...attributes}
        color="cyan"
        $selected={isFocused && isSelected}
        contentEditable={false}
      >
        {children}
        {element.display}
      </StyledInterpolationTag>
    </Tooltip>
  );
};
