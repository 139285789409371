import styled from 'styled-components';
import { Checkbox } from 'antd';


export const StyledCheckboxGroup = styled(Checkbox.Group)`
  flex-direction: column;
  gap: 12px;

  && .ant-checkbox-group-item {
    margin: 0;
  }
`;
