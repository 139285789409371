import { useMemo } from 'react';
import type { FC } from 'react';
import { Radio, RadioGroupProps as AntDRadioGroupProps } from 'antd';
import { StyledRadioGroup } from './RadioGroup.styles';


interface RadioGroupProps extends AntDRadioGroupProps {
  optionLabelKey?: string;
}

export const RadioGroup: FC<RadioGroupProps> = ({
  options,
  optionLabelKey, // 'label'
  ...rest
}) => {
  const renderOptions = useMemo(() => options?.map((option) => {
    if (typeof option === 'object' && typeof option.value === 'string') {
      let { label } = option;
      if (optionLabelKey && optionLabelKey in option && typeof option[optionLabelKey] === 'string') {
        label = option[optionLabelKey];
      }

      return <Radio key={option.value} value={option.value}>{label}</Radio>;
    }
    return null;
  }), [optionLabelKey, options]);

  return (
    <StyledRadioGroup {...rest}>
      {renderOptions}
    </StyledRadioGroup>
  );
};
