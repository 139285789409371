import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';
import { Card, Row } from 'antd';
import { Tag } from '../../atoms/Tag';


export const StyledCard = styled(Card)`
  background: ${({ theme }) => theme.currentModeConfig.contentBackground};
`;

export const StyledPageTitle = styled.h1`
  margin-top: 0;
  font-size: 20px;
`;

export const StyledFilterBySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
`;

export const StyledMoreOutlined = styled(MoreOutlined)`
  && {
    transform: rotate(90deg);
    font-size: 32px;
    cursor: pointer;
  }
`;

export const StyledStatusTag = styled(Tag)`
  && {
    pointer-events: none;
    text-transform: capitalize;
    margin: 0;
  }
`;

export const StyledBottomContent = styled(Row)`
  margin-top: 48px;
`;

export const StyledBottomContentTitle = styled.div`
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 4px;
`;

export const StyledBottomContentDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  width: 50%;
  margin-bottom: 28px;
`;

export const StyledBottomCard = styled(({ darkCard, ...res }) => <Card {...res} />)`
  height: 100%;

  ${({ darkCard }) => darkCard && `
    background: #0A2540;
    
    .ant-card-body {
        & > div {
            color: white;
        }
    }
  `}
`;
