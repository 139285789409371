import i18n from 'i18next';
// eslint-disable-line no-eval
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { appLangs, translations } from './locales';


const resourceEntries = appLangs.map((key) => [key, { translation: translations[key] }]);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: Object.fromEntries(resourceEntries),
    debug: false,
    fallbackLng: 'en',
    nsSeparator: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
