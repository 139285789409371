import type { FC } from 'react';
import { Input } from '../../../../atoms/Input';
import { FormItem } from '../../../../molecules/FormItem';
// import { GrammarlyEditor } from '../../../../../helpers/GrammarlyEditor';


export const NameStepFormItem: FC = () => (
  // <GrammarlyEditor>
  <FormItem
    name="name"
    label="Survey name"
    rules={[
      { required: true, whitespace: true, message: 'Please enter survey name!' },
      { max: 100, message: 'The survey name must be less than 100 characters!' }
    ]}
    tooltip={{
      title: (
        <>
          <p>
            This name is used for internal purposes.
            Name the project in such a way that you and your team
            would have an easy time navigating and understanding what the survey is about.
            Useful suggestions are:
          </p>
          <ul style={{ paddingLeft: 15 }}>
            <li>Product or service name</li>
            <li>Targeted country or regions</li>
            <li>Year</li>
            <li>Season</li>
            <li>Purpose</li>
          </ul>
        </>),
      arrow: true,
      placement: 'left',
      overlayInnerStyle: { padding: '2px 16px' }
    }}
    largeLabel
  >
    <Input
      placeholder="E.g Air Max running Shoes Oct 2022 in US"
      maxLength={100}
      showCount
    />
  </FormItem>
  // </GrammarlyEditor>
);
