import styled from 'styled-components';
import { Editable } from 'slate-react';
import { Tag } from 'antd';


export const StyledEditableContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledEditable = styled(Editable)<{ $invalid: boolean }>`
  line-height: 25px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: Inter, sans-serif;
  display: inline-block;
  border: 1px solid ${({ theme, $invalid }) => (
    $invalid ? theme.color.states.red : theme.color.greyscale.grey30
  )};
  border-radius: 4px;
  transition: all 0.3s;
  padding: 12px 16px;
  width: 100%;
  background: rgb(255, 255, 255);
  text-align: left;
  
  &:hover {
  border-color: ${({ $invalid, theme }) => (
    $invalid ? theme.color.tertiary.reddishPink : theme.color.greyscale.grey10
  )};
  border-inline-end-width: 1px;
  }
  
  &:focus-visible {
  border-color: ${({ $invalid, theme }) => (
    $invalid ? theme.color.states.red : theme.color.primary.blue60
  )};
  caret-color: ${({ theme }) => theme.color.primary.blue80};
  box-shadow: ${({ $invalid }) => (
    $invalid ? '0 0 0 2px rgba(255, 38, 5, 0.06)' : 'none'
  )};
  outline: none;
  }
`;

export const StyledInterpolationTag = styled(Tag)<{ $selected: boolean }>`
  margin: 0 2px;
  outline: ${({ $selected }) => ($selected ? 'cyan 1px solid' : 'unset')};
`;

export const StyledInterpolationInputPlaceholder = styled.span`
  top: 50%;
  transform: translateY(-50%);
`;
