import { FC, useMemo } from 'react';
import { Typography } from 'antd';
import { RadioGroup } from '../../../../molecules/RadioGroup';
import { FormItem } from '../../../../molecules/FormItem';
import { highlightSpecialPhrase } from '../../../../../helpers/previewFormatters';


interface CategoryStepFormItemProps {
  cardTitle: string;
  label?: string;
  options?: { label: string; value: string; }[];
}

export const CategoryStepFormItem: FC<CategoryStepFormItemProps> = ({ cardTitle, label = '', options }) => {
  const { Title } = Typography;

  const enhancedOptions = useMemo(() => options?.map(({ label: optionLabel, value }) => {
    const normalizerOptions = {
      string: optionLabel,
      typographyProps: { style: { fontWeight: 700 } },
    };

    return {
      label: highlightSpecialPhrase(normalizerOptions),
      value,
    };
  }), [options]);

  return (
    <>
      <Title level={3}>{cardTitle}</Title>
      <FormItem
        name={['item', 'category']}
        label={label}
        rules={[{ required: true }]}
        tooltip={{
          title: label,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '10px 16px' }
        }}
        largeLabel
      >
        <RadioGroup
          options={enhancedOptions}
        />
      </FormItem>
    </>
  );
};
