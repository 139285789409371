import {
  useCallback, useEffect, useMemo, useState, createRef
} from 'react';
import type { FC, ChangeEvent } from 'react';
import debounce from 'lodash/debounce';
import { Form, Input, InputRef } from 'antd';
import type { StoreValue } from 'antd/es/form/interface';
import { FormItem } from '../../molecules/FormItem';
import { CheckboxGroup } from '../../molecules/CheckboxGroup';
import type { FormItemProps } from '../../molecules/FormItem/FormItem.types';
// import { GrammarlyEditor } from '../../../helpers/GrammarlyEditor';
import { forceDispatchFormUpdateEvent } from '../../../helpers/forceDispatchFormUpdateEvent';
import { restoreInputFocus } from './CheckboxGroupWithCustomFieldsFormField.helpers';
import { OptionType } from '../../../types/option.type';


interface CheckboxGroupWithCustomFieldsFormFieldProps extends FormItemProps {
  label?: string;
  subTitle?: string;
  options?: { label: string; value: string }[];
  selectedValues?: string[];
  customFieldsCount?: number;
  placeholders?: string[];
}


export const CheckboxGroupWithCustomFieldsFormField:
FC<CheckboxGroupWithCustomFieldsFormFieldProps> = ({
  label,
  subTitle,
  options,
  selectedValues,
  customFieldsCount,
  placeholders,
  name,
  ...rest
}) => {
  const form = Form.useFormInstance();

  const [customFieldsState, setCustomFieldsState] = useState<Record<string, string>>({});
  const [newCustomFieldsState, setNewCustomFieldsState] = useState<Record<string, string>>({});

  const normalizedOptions = useMemo(() => {
    if (!options) {
      return [];
    }

    return options.map((option) => ({
      label: option.label,
      value: JSON.stringify(option),
    }));
  }, [options]);

  useEffect(() => {
    const initialValues = selectedValues?.filter((option) => {
      if (option) {
        let value;
        try {
          value = JSON.parse(option)?.value;
        } catch (err) {
          value = option;
        }
        return value.startsWith('customField_');
      }

      return false;
    });

    const initialValuesRecord: Record<string, string> = {};

    initialValues?.forEach((initialValue: string) => {
      let optionLabel = '';
      let value: keyof typeof initialValuesRecord;
      try {
        const parsedOption: { value: string; label: string; } = JSON.parse(initialValue);
        value = parsedOption.value;
        optionLabel = parsedOption.label;
      } catch (err) {
        value = initialValue;
      }

      if (value) {
        initialValuesRecord[value] = optionLabel;
      }
    });

    setCustomFieldsState(initialValuesRecord);
  }, [selectedValues]);

  const reservedArray = useMemo(() => {
    if (customFieldsCount && customFieldsCount > 0) {
      const reservedMap = Array(customFieldsCount).fill({
        name: '',
        value: '',
      });

      return reservedMap.map((field, index) => {
        const inputName = `customField_${index}*`;

        const fieldValue = customFieldsState[inputName];

        const value = inputName;
        const optionLabel = fieldValue || '';

        let optionValue;
        if (value && optionLabel) {
          try {
            const option = {
              value: value || '',
              label: optionLabel || '',
            };
            optionValue = JSON.stringify(option);
          } catch (err) {
            optionValue = '';
          }
        }

        return {
          name: value,
          value: optionLabel || '',
          optionValue,
        };
      });
    }
    return [];
  }, [customFieldsCount, customFieldsState]);

  const customInputRefs = useMemo(() => {
    if (customFieldsCount && customFieldsCount > 0) {
      const reservedMap = Array(customFieldsCount).fill(null);

      return reservedMap.map(() => createRef<InputRef>());
    }

    return;
  }, [customFieldsCount]);

  const onCustomFieldInputChange = useCallback((index: number, event: ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value: inputValue } = event.target;

    if (name) {
      const formFieldValue: string[] = form.getFieldValue(name);
      const currentCustomFieldFormValueIndex = formFieldValue?.findIndex((value) => {
        return value.includes(inputName);
      });

      if (formFieldValue && currentCustomFieldFormValueIndex !== -1) {
        const currentFieldFormValue = formFieldValue?.[currentCustomFieldFormValueIndex];

        try {
          const currentFieldValueObject: OptionType = JSON.parse(currentFieldFormValue);
          currentFieldValueObject.label = inputValue;
          formFieldValue[currentCustomFieldFormValueIndex] = JSON.stringify(currentFieldValueObject);

          // Hack to dispatch Ant design's Form onValuesChange programmatically
          forceDispatchFormUpdateEvent({ form, namePath: name, value: formFieldValue });

          // After force dispatching form update the input is re-rendered and loses focus
          restoreInputFocus({ customInputRefs, index, inputName });
        } catch (err) {
          console.log('onCustomFieldInputChange err', err);
        }
      }
    }

    setNewCustomFieldsState((prevState) => ({ ...prevState, [inputName]: inputValue }));
  }, [form, customInputRefs, name]);

  const onCustomFieldInputChangeDebounced = useMemo(() => {
    return debounce(onCustomFieldInputChange, 700);
  }, [onCustomFieldInputChange]);

  const renderCustomFieldInputs = useMemo(() => {
    return reservedArray.map(({ name: inputName = '', value = '', optionValue = '' }, index) => {
      const inputRef = customInputRefs?.[index];

      return {
        key: inputName,
        value: optionValue || inputName,
        label: (
          // <GrammarlyEditor>
          <Input
            ref={inputRef}
            onChange={(e) => onCustomFieldInputChangeDebounced(index, e)}
            name={inputName}
            defaultValue={value}
            placeholder={placeholders?.[index] || ''}
            style={{ width: 450 }}
          />
          // </GrammarlyEditor>
        ),
      };
    });
  }, [customInputRefs, onCustomFieldInputChangeDebounced, placeholders, reservedArray]);

  const customOptions = useMemo(() => {
    if (!normalizedOptions) {
      return;
    }

    if (customFieldsCount && customFieldsCount > 0) {
      return [...renderCustomFieldInputs, ...normalizedOptions];
    }

    return normalizedOptions;
  }, [customFieldsCount, normalizedOptions, renderCustomFieldInputs]);

  const normalizeFormItemValue = useCallback((value: StoreValue) => {
    const customFields = value.filter((item: string) => item.startsWith('customField_'));
    const prevFields = value.filter((item: string) => !item.startsWith('customField_'));

    const normalizedCustomFields: string[] = [];

    customFields.forEach((customField: string) => {
      const optionLabel = newCustomFieldsState[customField];
      if (optionLabel) {
        const option = {
          value: customField,
          label: newCustomFieldsState[customField],
        };
        normalizedCustomFields.push(JSON.stringify(option));
      }
    });

    return [...normalizedCustomFields, ...prevFields];
  }, [newCustomFieldsState]);

  return (
    <FormItem
      label={label}
      subTitle={subTitle}
      normalize={normalizeFormItemValue}
      validateStatus="success"
      name={name}
      {...rest}
    >
      <CheckboxGroup
        options={customOptions}
        selectedValues={selectedValues}
      />
    </FormItem>
  );
};
