import type { FC } from 'react';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import { StyledSelect } from './Select.styles';
import type { SelectProps } from './Select.types';


export const Select: FC<SelectProps> = ({ size = 'large', loading, ...rest }) => (
  <StyledSelect
    size={size}
    suffixIcon={!loading ? <ChevronDownIcon /> : undefined}
    loading={loading}
    {...rest}
  />
);
