import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { deeplTranslate } from '../helpers/deeplTranslate';


export interface OptionInterface {
  label: string;
  translatedLabel?: string;
  value: string;
  disqualify?: boolean;
  skipTranslation?: boolean;
}


export const useOptionsTranslation = () => {
  const { i18n, t } = useTranslation();

  const renderOptionsWithTranslate = useCallback(async (
    options: OptionInterface[],
    optionKey = 'label',
    prefix?: string
  ):Promise<OptionInterface[]> => {
    const optionsPromises: Promise<OptionInterface>[] = options.map((option: OptionInterface) => {
      return new Promise((resolve) => {
        let translationKey = option[optionKey];

        if (prefix) {
          translationKey = `${prefix}.${option[optionKey]}`;
        }

        if (option.value.startsWith('customField')) {
          if (i18n.language === 'en') {
            resolve(option);
            return;
          }

          if (option.skipTranslation) {
            const { translatedLabel, ...restOptionFields } = option;
            resolve(restOptionFields);
            return;
          }

          deeplTranslate(option.label, i18n.language).then((translatedCustomField) => {
            const translatedLabel = translatedCustomField?.translations[0].text;
            resolve({ ...option, label: translatedLabel || option.label });
          });
        } else {
          resolve({ ...option, label: t(translationKey) });
        }
      });
    });

    return Promise.all(optionsPromises);
  }, [i18n.language, t]);

  return {
    renderOptionsWithTranslate
  };
};
