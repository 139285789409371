export const lowerCaseFirstLetter = (string: string): string => {
  const firstLetterUppercaseRegExp = /^[A-Z]{1}/;

  const trimmedString = string.trim();

  const isStringUppercase = firstLetterUppercaseRegExp.test(trimmedString);

  if (!isStringUppercase) {
    return string;
  }

  const lowerCasedFirstLetter = trimmedString[0].toLowerCase();

  return `${lowerCasedFirstLetter}${trimmedString.slice(1)}`;
};
