import {
  Dispatch, MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect
} from 'react';
import type { i18n } from 'i18next';
import { deeplTranslate } from '../helpers/deeplTranslate';
import type { AdminProjectForm } from '../components/pages/AdminCreateProject/AdminCreateProject.types';
import { Project } from '../types/project';
import { compareObjectsWithDiff } from '../helpers/compareObjectsWithDiff';
import { ProjectForm } from '../components/organisms/AddProjectForm/AddProjectForm.types';
import type { CategorySurveyConfig } from '../constants/surveyConfig';


interface UseProjectFormTranslationsType {
  formValues: AdminProjectForm;
  setFormValues: Dispatch<SetStateAction<AdminProjectForm>>;
  constructProjectSurveyConfig: (
    project: AdminProjectForm,
    skipOptionsPopulation?: boolean,
    shouldSkipQuestionTitlesTranslation?: boolean
  ) => Promise<CategorySurveyConfig | undefined>;
  i18n: i18n;
  onLanguageChange?: (language: string) => void;
}

export const useProjectFormTranslations = ({
  formValues,
  setFormValues,
  constructProjectSurveyConfig,
  i18n,
  onLanguageChange,
}: UseProjectFormTranslationsType) => {
  const translateProjectItem = useCallback(async () => {
    const projectItem = formValues.item;
    if (!projectItem) {
      return;
    }

    const { name, description } = projectItem;

    if (!name || !description) {
      return;
    }

    if (i18n.language === 'en' || formValues.surveyLanguage === i18n.language) {
      return {
        name,
        description
      };
    }

    const translatedNamePromise = deeplTranslate(name, i18n.language);
    const translatedDescriptionPromise = deeplTranslate(description, i18n.language);

    const [
      nameResponse,
      descriptionResponse
    ] = await Promise.all([translatedNamePromise, translatedDescriptionPromise]);

    return {
      name: nameResponse?.translations[0].text || name,
      description: descriptionResponse?.translations[0].text || description
    };
  }, [formValues.item, formValues.surveyLanguage, i18n.language]);

  const translateProjectValuesAndConstructSurveyConfig = useCallback(async ({
    shouldSkipQuestionTitlesTranslation
  }: {
    shouldSkipQuestionTitlesTranslation?: boolean
  } = {}) => {
    try {
      const translatedItemValues = await translateProjectItem();
      if (!translatedItemValues) {
        return;
      }

      const projectFields = {
        ...formValues,
        item: {
          ...formValues.item,
          ...translatedItemValues
        } as Project['item']
      };

      return await constructProjectSurveyConfig(projectFields, false, shouldSkipQuestionTitlesTranslation);
    } catch (message) {
      console.debug(message);
      return;
    }
  }, [constructProjectSurveyConfig, formValues, translateProjectItem]);

  useEffect(() => {
    const languageListenerCallback = (language: string) => {
      onLanguageChange?.(language);

      setFormValues((prevState) => {
        if (prevState.surveyLanguage === language) {
          return prevState;
        }


        return {
          ...prevState,
          surveyLanguage: language
        };
      });

      translateProjectValuesAndConstructSurveyConfig();
    };

    i18n.on('languageChanged', languageListenerCallback);

    return () => {
      i18n.off('languageChanged', languageListenerCallback);
    };
  }, [i18n, onLanguageChange, setFormValues, translateProjectValuesAndConstructSurveyConfig]);

  const composeSurveyOnPreviewModalOpen = useCallback(async (
    cachedProjectValuesRef: MutableRefObject<Partial<ProjectForm | null>>,
    projectSurveyConfig?: CategorySurveyConfig
  ) => {
    const cachedProjectValues = cachedProjectValuesRef.current;
    const shouldCheckDiff = cachedProjectValues && projectSurveyConfig;
    let shouldConstructConfig = true;
    let config: CategorySurveyConfig | undefined;
    let shouldSkipQuestionTitlesTranslation = false;

    if (shouldCheckDiff) {
      const diff = compareObjectsWithDiff(cachedProjectValues, formValues) as Partial<ProjectForm>;
      // Note: Run spell checking only if core values changed
      const isItemFieldInDiff = 'item' in diff && diff.item;
      const isStepsFieldInDiff = 'steps' in diff;
      // Note: calculate titles only if item name or description changed
      shouldSkipQuestionTitlesTranslation = !isItemFieldInDiff;

      if (diff.item) {
        const isNameChanged = 'name' in diff.item;
        const isDescriptionChanged = 'description' in diff.item;

        shouldSkipQuestionTitlesTranslation = !isNameChanged && !isDescriptionChanged;
      }


      shouldConstructConfig = !!(isItemFieldInDiff || isStepsFieldInDiff);
    }

    if (shouldConstructConfig) {
      config = await translateProjectValuesAndConstructSurveyConfig({ shouldSkipQuestionTitlesTranslation });
    }

    // eslint-disable-next-line no-param-reassign
    cachedProjectValuesRef.current = formValues;

    return config;
  }, [formValues, translateProjectValuesAndConstructSurveyConfig]);

  return {
    composeSurveyOnPreviewModalOpen
  };
};
