import isEqual from 'lodash/isEqual';
import transform from 'lodash/transform';
import isObject from 'lodash/isObject';


export const compareObjectsWithDiff = <T extends object,>(base: T, compare: T) => {
  function changes(baseObject: T, compareObject: T) {
    return transform(compareObject, (result, value, key) => {
      if (!isEqual(value, baseObject[key])) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        result[key] = (isObject(value) && isObject(baseObject[key])) ? changes(value, baseObject[key]) : value;
      }
    });
  }
  return changes(compare, base);
};
