import type { SetStateAction } from 'react';
import { AdminProjectForm, WTPContentToEditType } from './AdminCreateCustomerProject.types';
import { CategorySurveyConfig } from '../../../constants/surveyConfig';
import { PartialGeoType } from '../../organisms/AddProjectForm/AddProjectForm.types';
import { initialFormValues as standardProjectInitialFormValues } from '../AdminCreateProject/AdminCreateProject.helper';


export const combineWTPEditsWithProjectSurveyConfig = (
  wtpEdits: WTPContentToEditType,
  projectSurveyConfig: CategorySurveyConfig
) => {
  const wtpStepWithIntro = 'price.low';

  const steps = projectSurveyConfig.steps.map((currentStep) => {
    if (currentStep.step in wtpEdits) {
      if (currentStep?.categories?.general) {
        const parsedBody = wtpEdits[currentStep.step].split('\n').filter(Boolean);
        const modifiedStep = {
          ...currentStep,
          categories: {
            ...currentStep.categories,
            general: {
              ...currentStep.categories.general,
              body: parsedBody
            }
          }
        };

        if (currentStep.step === wtpStepWithIntro) {
          const wtpIntroStepName = 'price.low.intro';
          let newIntro: string[] | string = wtpEdits[wtpIntroStepName];
          if (newIntro.includes('\n')) {
            newIntro = newIntro.split('\n').filter(Boolean);
          }

          return {
            ...modifiedStep,
            intro: newIntro
          };
        }

        return modifiedStep;
      }

      const parsedBody = wtpEdits[currentStep.step].split('\n').filter(Boolean);

      return {
        ...currentStep,
        body: parsedBody
      };
    }

    return currentStep;
  });

  return {
    ...projectSurveyConfig,
    steps
  };
};

export const resetRegionState = (
  setFormValues: (value: SetStateAction<AdminProjectForm>) => void,
  { keepRegionType }: { keepRegionType?: boolean } = {}
) => {
  setFormValues((prevState) => {
    if (!prevState.targetGroup) {
      return prevState;
    }

    const geo: PartialGeoType = {
      country: prevState.targetGroup.geo.country,
    };

    if (keepRegionType) {
      geo.regionType = prevState.targetGroup?.geo.regionType;
    }

    return {
      ...prevState,
      targetGroup: {
        ...prevState.targetGroup,
        geo: { ...geo },
      },
    };
  });
};

export const initialFormValues: AdminProjectForm = {
  ...standardProjectInitialFormValues,
  customRespondentsPool: true
};

export const ADMIN_PAYMENT_MODELS = [
  { label: 'Per unit sold', value: 'per_unit_sold' },
  { label: 'Fixed', value: 'fixed' },
  { label: 'Per Hour', value: 'per_hour' },
  { label: 'Per week', value: 'per_week' },
  { label: 'Per Month', value: 'per_month' },
  { label: 'Per Year', value: 'per_year' },
];

export const WTP_EDIT_STEP_NAMES = ['intro', 'price.low', 'price.high', 'price.midHigh', 'price.midLow'];
