import { TFunction } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import {
  CategorySurveyConfig,
  CategorySurveyStepConfigType,
  paymentMethodStepConfig,
  usageFrequencyStepConfig,
  customProfilersStepConfig
} from '../../../constants/surveyConfig';
import type { ProjectForm } from '../AddProjectForm/AddProjectForm.types';
import { populateObjectValue, populateRegexValuesInString } from '../../../helpers/previewFormatters';
import { textGearsSpellCheckWithAutoCorrect } from '../../../helpers/spellCheckStringWithAutoCorrect';
import { ProjectSurveyFormSuggestion } from '../../../types/survey';
import { OptionType } from '../../../types/option.type';
import { getSurveyConfig } from '../../../helpers/getSurveyConfig';
import { OptionInterface } from '../../../hooks/useOptionsTranslation';
import type { ProductCategory, Project, ServiceCategory } from '../../../types/project';
import { getProject } from '../../../api/project';
import { getProjectFormConfig } from '../../../helpers/getProjectFormConfig';


const populateProjectItemValue = (project: ProjectForm, string: string, keepMarkers?: boolean) => {
  if (!string) {
    return string;
  }
  return populateRegexValuesInString({
    string,
    object: project,
    keepMarkers
  });
};

const translateAndPopulateStringValue = async ({
  t,
  project,
  string,
  afterTranslate,
  isPreview,
  skipSpellcheck
}: {
  string: string;
  project: ProjectForm;
  afterTranslate?: (value: string) => string;
  isPreview?: boolean;
  t: TFunction<'translation', undefined, 'translation'>;
  skipSpellcheck?: boolean;
}) => {
  // if (!string?.startsWith('surveyConfig')) {
  //   return string;
  // }

  let result = string;

  try {
    if (result?.startsWith('surveyConfig')) {
      result = t(result);
    }
    // result = t(result);

    if (afterTranslate) {
      result = afterTranslate(result);
    }
    const hasMarkedItemValues = result?.includes('[item.name]') || result?.includes('[item.description]');

    const shouldSpellCheck = project.item?.name && project.item?.description && hasMarkedItemValues;

    if (shouldSpellCheck) {
      const isProduction = process.env.REACT_APP_STAGE === 'prod';

      const populatedString = populateProjectItemValue(project, result, isPreview);

      if (!skipSpellcheck && isProduction) {
        const spellCheckResult = await textGearsSpellCheckWithAutoCorrect(populatedString);
        const correctedText = spellCheckResult?.response?.corrected;

        if (correctedText) {
          return correctedText;
        }
      }
      return populatedString;
    }
  } catch (err) {
    console.log('title translation err: ', err);
    console.log('translation key: ', result);
  }

  return result;
};
const afterBodyItemTranslate = (config: { formattedQuantity: string, searchValue?: string }, bodyItem: string) => {
  const { formattedQuantity, searchValue = '[item.name]' } = config;
  // const searchString = searchValue ?? '[item.name]';

  return bodyItem.replace(searchValue, formattedQuantity);
};

const composeQuantityString = ({
  t,
  quantityAmount,
  measurement,
  useGolfConfig,
  itemNameAnchor = '[item.name]'
}: {
  t: TFunction<'translation', undefined, 'translation'>;
  quantityAmount: number;
  measurement: string;
  useGolfConfig?: boolean;
  itemNameAnchor?: string
}) => {
  let translatedMeasurement = measurement;

  try {
    const translationKey = `surveyConfig.quantity.${measurement}`;
    translatedMeasurement = t(translationKey);
  } catch (err) {
    console.debug('composeQuantityString err: ', err);
  }

  if (useGolfConfig) {
    return `${quantityAmount} ${translatedMeasurement} ${itemNameAnchor}`;
  }

  return `[item.name] ${quantityAmount} ${translatedMeasurement}`;
};

export const getTranslatedProjectConfigSteps = async ({
  project,
  t,
  skipSpellcheck,
  useGolfConfig,
  defaultSurveyConfig,
  translateCustomFields,
  translateCustomStepTitle,
  skipWTPQuestions,
  wtpStepNames,
  skipOptionsPopulation,
  shouldSkipQuestionTitlesTranslation,
  previousSurveyConfig,
}: {
  project: ProjectForm;
  t: TFunction<'translation', undefined, 'translation'>;
  skipSpellcheck?: boolean;
  useGolfConfig?: boolean;
  defaultSurveyConfig?: CategorySurveyConfig;
  translateCustomFields?: (options: OptionInterface[], forceTranslate?: boolean) => Promise<OptionInterface[]>;
  translateCustomStepTitle?: (questionTitle: string) => Promise<string | undefined>;
  skipWTPQuestions?: boolean;
  wtpStepNames?: string[];
  skipOptionsPopulation?: boolean;
  shouldSkipQuestionTitlesTranslation?: boolean;
  previousSurveyConfig?: CategorySurveyConfig;
})
: Promise<CategorySurveyConfig | undefined> => {
  const projectType = project.type;
  const projectItemCategory = project.item?.category;

  if (!projectType || !projectItemCategory) {
    return;
  }
  let config: CategorySurveyConfig | null = null;

  if (defaultSurveyConfig) {
    config = cloneDeep(defaultSurveyConfig);
  }

  if (!config) {
    const surveyConfig = getSurveyConfig(useGolfConfig);

    config = { ...surveyConfig[projectType] };
  }

  if (!config) {
    return;
  }

  if (project.aim === 'change_payment_model') {
    const paymentMethodStep = paymentMethodStepConfig[projectType] as CategorySurveyStepConfigType;

    const placementIndex = config.steps.findIndex((stepConfig) => stepConfig.step.startsWith('price'));

    const newSteps = [...config.steps];
    newSteps.splice(placementIndex, 0, paymentMethodStep);
    config.steps = newSteps as CategorySurveyConfig['steps'];
  }

  if (project.item?.usageFrequency) {
    const isInterestedStepIndex = config.steps.findIndex((stepConfig) => stepConfig.step === 'isInterested');
    const usageFrequencyStep = usageFrequencyStepConfig[projectType] as CategorySurveyStepConfigType;

    const newSteps = [...config.steps];

    newSteps.splice(isInterestedStepIndex + 1, 0, usageFrequencyStep);

    config.steps = newSteps as CategorySurveyConfig['steps'];
  }

  if (project.customRespondentsPool && project.item?.income) {
    const incomeStep = cloneDeep(customProfilersStepConfig[projectType]) as CategorySurveyStepConfigType;

    config.steps = [...config.steps, incomeStep] as CategorySurveyConfig['steps'];
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const stepsPromises = config.steps.map(async (configStep: typeof config.steps[number]) => {
    const newStepConfig = { ...configStep };
    const stepName = newStepConfig.step;

    if (skipWTPQuestions && wtpStepNames?.includes(stepName)) {
      return newStepConfig;
    }

    const quantity = project?.item?.quantity;
    const hasQuantityFilled = !!(quantity?.amount && quantity?.measurement);

    // step.body
    if ('body' in newStepConfig && newStepConfig.body) {
      let onTranslate: undefined | ((value: string) => string);
      if (hasQuantityFilled && stepName.startsWith('price')) {
        const formattedQuantity = composeQuantityString({
          t,
          quantityAmount: quantity?.amount,
          measurement: quantity?.measurement,
          useGolfConfig,
        });

        onTranslate = afterBodyItemTranslate.bind(null, { formattedQuantity });
      }

      const stepBodyPromises = newStepConfig.body.map((bodyItem: string) => {
        return translateAndPopulateStringValue({
          project,
          t,
          string: bodyItem,
          afterTranslate: onTranslate,
          skipSpellcheck
        });
      });
      const stepBody = stepBodyPromises && await Promise.all(stepBodyPromises);

      if (stepBody) {
        newStepConfig.body = stepBody;
      }
    }

    // step.categories
    if ('categories' in newStepConfig && newStepConfig.categories) {
      const categoryConfig = newStepConfig.categories[projectItemCategory];
      let stepTitle: string | null = categoryConfig.title;
      let translatedStepTitle: string | undefined;

      let categoryBody: string[] | null = null;

      // use title from previous survey config
      if (previousSurveyConfig && shouldSkipQuestionTitlesTranslation && 'title' in categoryConfig) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const previousSurveyConfigCurrentStep = previousSurveyConfig.steps.find(
          (stepConfig) => stepConfig.step === stepName
        );
        const prevSurveyConfigCategoryConfig = previousSurveyConfigCurrentStep.categories?.[projectItemCategory];
        if ('title' in prevSurveyConfigCategoryConfig) {
          stepTitle = prevSurveyConfigCategoryConfig.title;
        }
      }

      // step.categories[category].title
      if ('title' in categoryConfig && !shouldSkipQuestionTitlesTranslation) {
        const isCustomStep = stepName.startsWith('customStep');
        const isIncomeProfilersStep = stepName === 'income';
        const hasIncomeField = Boolean(project.customRespondentsPool && project.item?.income);
        const shouldForceTranslate = Boolean(isIncomeProfilersStep && hasIncomeField);

        const questionTitle = categoryConfig.title ?? '';

        // NOTE: product path, one of the steps might not have the category, need to check
        stepTitle = await translateAndPopulateStringValue({
          t,
          project,
          string: questionTitle,
          skipSpellcheck
        });

        if (defaultSurveyConfig && (isCustomStep || shouldForceTranslate)) {
          if (translateCustomStepTitle) {
            // translate custom step title
            translatedStepTitle = stepTitle && await translateCustomStepTitle?.(stepTitle);
          }

          if (translateCustomFields) {
            const isOptionPopulatable = isStringProjectValuePopulatable(categoryConfig.options);
            if (isOptionPopulatable && shouldForceTranslate) {
              const populatedOptions = populateObjectValue(categoryConfig.options, project);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const isOptionsStringsArray = Array.isArray(populatedOptions) && populatedOptions?.every(
                (option) => typeof option === 'string'
              );

              if (isOptionsStringsArray) {
                try {
                  categoryConfig.options = populatedOptions.map((option) => JSON.parse(option));
                } catch (err) {
                  console.debug('Error parsing options: ', err);
                }
              } else {
                // Handle empty competitors options
                categoryConfig.options = populatedOptions ?? [];
              }
            }

            // translate custom step custom fields options
            categoryConfig.options = await translateCustomFields(categoryConfig.options, shouldForceTranslate);
          }
        }
      }

      // step.categories[category].body
      if ('body' in categoryConfig) {
        let onTranslate: undefined | ((value: string) => string);
        if (hasQuantityFilled && stepName.startsWith('price')) {
          const formattedQuantity = composeQuantityString({
            t,
            quantityAmount: quantity?.amount,
            measurement: quantity?.measurement,
            useGolfConfig
          });

          onTranslate = afterBodyItemTranslate.bind(null, { formattedQuantity });
        }

        const categoryBodyPromises = categoryConfig.body?.map((bodyItem: string) => {
          return translateAndPopulateStringValue({
            project,
            t,
            string: bodyItem,
            afterTranslate: onTranslate,
            skipSpellcheck
          });
        });

        categoryBody = categoryBodyPromises && await Promise.all(categoryBodyPromises);
      }

      const newCategoryConfig: Partial<typeof categoryConfig> = { ...categoryConfig };

      if (stepTitle) {
        newCategoryConfig.title = stepTitle;
      }
      if (translatedStepTitle) {
        newCategoryConfig.translatedTitle = translatedStepTitle;
      }
      if (categoryBody) {
        newCategoryConfig.body = categoryBody;
      }

      if ('options' in categoryConfig) {
        const isOptionPopulatable = isStringProjectValuePopulatable(categoryConfig.options);

        newCategoryConfig.options = categoryConfig.options;

        if (isOptionPopulatable && !skipOptionsPopulation) {
          const populatedOptions = populateObjectValue(categoryConfig.options, project);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const isOptionsStringsArray = Array.isArray(populatedOptions) && populatedOptions?.every(
            (option) => typeof option === 'string'
          );

          if (isOptionsStringsArray) {
            try {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              newCategoryConfig.options = populatedOptions.map(JSON.parse);
            } catch (err) {
              console.debug('Error parsing options: ', err);
            }
          } else {
            // Handle empty competitors options
            newCategoryConfig.options = populatedOptions ?? [];
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newStepConfig.categories = {
        [projectItemCategory]: newCategoryConfig
      };
    }

    // step.intro
    if ('intro' in newStepConfig && newStepConfig.intro) {
      let onTranslate: undefined | ((value: string) => string);
      if (hasQuantityFilled) {
        let itemNameAnchor = '[item.name]';

        if (useGolfConfig && stepName === 'price.low') {
          itemNameAnchor = `new ${itemNameAnchor}`;
        }

        const formattedQuantity = composeQuantityString({
          t,
          quantityAmount: quantity?.amount,
          measurement: quantity?.measurement,
          useGolfConfig,
          itemNameAnchor
        });

        onTranslate = afterBodyItemTranslate.bind(null, { formattedQuantity, searchValue: itemNameAnchor });
      }

      newStepConfig.intro = await translateAndPopulateStringValue({
        project,
        t,
        string: newStepConfig.intro,
        afterTranslate: onTranslate,
        skipSpellcheck
      });
    }

    return newStepConfig;
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  config.steps = await Promise.all(stepsPromises);

  return config;
};

export const constructProjectQuestionsConfig = (surveyTitlesConfig: CategorySurveyConfig) => {
  const { /* type, */ steps: projectSurveyConfigSteps } = surveyTitlesConfig;

  // const blacklistedSteps = ['competitor'];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return projectSurveyConfigSteps.reduce((accumulator, stepConfig) => {
    // if (blacklistedSteps.includes(stepConfig.step)) {
    //   return accumulator;
    // }
    if (!('categories' in stepConfig)) {
      return accumulator;
    }
    const [, categoryValues] = Object.entries(stepConfig.categories)[0];

    const hasCategoriesObject = categoryValues && typeof categoryValues === 'object';
    if (!hasCategoriesObject) {
      return accumulator;
    }

    const isOptionPopulatable = 'options' in categoryValues
      && typeof categoryValues.options === 'string'
      && categoryValues?.options.startsWith('[item.');

    if (isOptionPopulatable) {
      const questionTitle = 'title' in categoryValues ? categoryValues.title : '';
      const titleShort = 'titleShort' in categoryValues ? categoryValues.titleShort : '';

      accumulator[stepConfig.step] = {
        titleShort,
        title: questionTitle,
        options: []
      };
    }

    return accumulator;
  }, {});
};
export const normalizeAIProjectSuggestionDataStructure = (
  projectFormConfigSteps: string[],
  projectConfigSuggestion: ProjectSurveyFormSuggestion
) => {
  const entries = Object.entries(projectConfigSuggestion);

  return entries.reduce((accumulator, currentEntry) => {
    const [stepName, stepValue] = currentEntry;

    const stepPluralName = `${stepName}s`;
    const isPluralNameInSequence = projectFormConfigSteps.includes(stepPluralName);

    let parsedOptions: (OptionType | string)[] = stepValue.options;

    try {
      parsedOptions = parsedOptions.map((option) => JSON.stringify(option));
    } catch (err) {
      console.log('err', err);
    }

    if (isPluralNameInSequence) {
      accumulator[stepPluralName] = parsedOptions;
      return accumulator;
    }

    const isNameInSequence = projectFormConfigSteps.includes(stepName);
    if (isNameInSequence) {
      accumulator[stepName] = parsedOptions;
      return accumulator;
    }

    return accumulator;
  }, {});
};

// Remove unsupported options in formValues from old schema
const normalizeProjectItemSchemaV2 = (normalizedProject: Project): Project['item'] => {
  const schemaVersion = 2;
  const productType = normalizedProject.type;
  const currentProjectConfig = getProjectFormConfig({ schemaVersion });

  const categoryConfig = currentProjectConfig[productType];
  const itemEntries = Object.entries(normalizedProject.item);

  return itemEntries.reduce((accumulator, [currentKey, currentValue]) => {
    if (!Array.isArray(currentValue)) {
      accumulator[currentKey] = currentValue;
      return accumulator;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentStepProjectConfig = categoryConfig.steps.find((currentStep) => currentStep.step === currentKey);

    const hasCategoriesField = 'categories' in currentStepProjectConfig;
    if (!hasCategoriesField) {
      accumulator[currentKey] = currentValue;
      return accumulator;
    }

    const currentProjectItemCategoryConfig = currentStepProjectConfig.categories[normalizedProject.item.category];
    const projectItemCategoryOptionValues: string[] = currentProjectItemCategoryConfig.options.map((option) => {
      return option.value;
    });

    const parsedOptions = currentValue.map((currentElement) => {
      try {
        return JSON.parse(currentElement);
      } catch (err) {
        console.debug('err', err);
        return currentElement;
      }
    });
    const filteredSelectedOptions = parsedOptions.filter((currentOption) => {
      const isCustomField = currentOption.value.startsWith('customField_');
      if (isCustomField) {
        return true;
      }

      return projectItemCategoryOptionValues.includes(currentOption.value);
    });

    accumulator[currentKey] = filteredSelectedOptions.map((option) => {
      try {
        return JSON.stringify(option);
      } catch (err) {
        console.debug('err', err);
        return option;
      }
    });

    return accumulator;
  }, {} as Project['item']);
};

const normalizeProjectSchemaVersion2 = (projectObject: Project) => {
  const SCHEMA_VERSION = 2;
  const normalizedProject = { ...projectObject };
  if (normalizedProject.aim !== 'change_payment_model') {
    normalizedProject.aim = undefined;
  }

  normalizedProject.schemaVersion = SCHEMA_VERSION;

  if ('item' in normalizedProject) {
    if (normalizedProject.type === 'product') {
      const isCategoryFood = normalizedProject.item.category !== 'food';
      if (isCategoryFood) {
        normalizedProject.item.category = 'general';
      }
    }

    if (normalizedProject.type === 'service') {
      normalizedProject.item.category = 'general';
    }

    normalizedProject.item = normalizeProjectItemSchemaV2(normalizedProject);
  }

  return normalizedProject;
};

const normalizeProjectVersion = (project: Project) => (
  project.schemaVersion === 2
    ? project
    : normalizeProjectSchemaVersion2(project)
);

export const getProcessedProject = async (projectId: string): Promise<Partial<Project>> => {
  const project = await getProject(projectId);
  return normalizeProjectVersion(project);
};

export const getProcessedProjectForDuplication = async (projectId: string): Promise<Partial<Project>> => {
  const project = await getProject(projectId);
  const normalizedProject = normalizeProjectVersion(project);
  return {
    ...omit(normalizedProject, [
      'hasUserAcknowledgedPayment',
      'stripeInvoiceId',
      'hostedInvoiceUrl',
      'CINTSurveyId',
    ]),
    name: `${normalizedProject.name}-copy`,
  };
};

export const getIsServiceCategory = (category?: ServiceCategory | ProductCategory): category is ServiceCategory => {
  if (!category) return false;

  const serviceCategories = ['general'];
  return serviceCategories.includes(category);
};

export const getIsProductCategory = (category?: ServiceCategory | ProductCategory): category is ProductCategory => {
  if (!category) return false;

  const productCategories = ['general', 'food', 'drink'];
  return productCategories.includes(category);
};

const isStringProjectValuePopulatable = (string?: string) => typeof string === 'string' && string.startsWith('[item.');
