import {
  FC,
  Fragment,
  useCallback,
  useState,
} from 'react';
import {
  Collapse,
  Divider,
  Form,
  Modal,
  Space,
  Switch,
  Upload,
  UploadFile,
} from 'antd';
import {
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  UpOutlined
} from '@ant-design/icons';
import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';
import { IconButton } from '../../atoms/IconButton';
import { DynamicAnswerAlternativeField } from './DynamicAnswerAlternativeField/DynamicAnswerAlternativeField';
import { StyledDynamicCustomProjectHorizontalFormItem } from './DynamicCustomProjectStepsForm.styles';
import { InputNumber } from '../../atoms/InputNumber';
import type { AdminProjectForm, AdminProjectFormStep } from '../../pages/AdminCreateProject/AdminCreateProject.types';
import { InterpolationInput } from '../../atoms/InterpolationInput/InterpolationInput';
import { useInterpolationVariables } from '../../../hooks/useInterpolationVariables';
import { useForkQuestion } from '../../../hooks/useForkQuestion';
import { FormItem } from '../../molecules/FormItem';


interface DynamicCustomProjectStepsFormProps {
  initialValue: AdminProjectForm['steps'];
  formListName?: string;
}

const getBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});


export const DynamicCustomProjectStepsForm: FC<DynamicCustomProjectStepsFormProps> = ({
  initialValue,
  formListName = 'steps',
}) => {
  const form = Form.useFormInstance();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const steps: NonNullable<AdminProjectForm['steps']> = form.getFieldsValue().steps ?? [];
  const { firstForkQuestionIdx, shouldIsForkBeBlocked } = useForkQuestion(steps);
  const getVariables = useInterpolationVariables();

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as File);
    }

    const preview = (file.url || file.preview) ?? '';

    setPreviewImage(preview);
    setPreviewOpen(true);
  };

  const handleClosePreviewModal = () => {
    setPreviewImage('');
    setPreviewOpen(false);
  };

  const normalizeFile = (e: unknown) => {
    if (!e) {
      return;
    }

    if (Array.isArray(e)) {
      return e;
    }

    if (typeof e === 'object' && 'fileList' in e) {
      return e.fileList;
    }

    return e;
  };

  const getCollapseQuestionTitle = useCallback((questionIndex: number) => {
    const formValue = form.getFieldValue([formListName, questionIndex]) as AdminProjectFormStep;

    return formValue?.titleShort || `Survey question ${questionIndex + 1}`;
  }, [form, formListName]);

  const getIsForkSwitchHandler = useCallback((questionIndex: number) => (
    () => form.resetFields([
      ['steps', questionIndex, 'multiselect'],
      ['steps', questionIndex, 'maxSelected'],
    ])
  ), [form]);

  const getIsQuestionFork = useCallback((questionIndex: number): boolean => (
    form.getFieldValue(['steps', questionIndex, 'isFork'])
  ), [form]);


  return (
    <>
      <Form.List name={formListName} initialValue={initialValue}>
        {(fields, {
          add,
          remove,
          move
        }) => (
          <>
            <h2>Create survey questions</h2>
            {fields.map(({
              key,
              name,
              ...restField
            }, index) => (
              <Fragment key={`step-${key}`}>
                <Divider plain>
                  Survey question
                  &nbsp;
                  {index + 1}
                </Divider>

                <div
                  key={`step-${key}`}
                  style={{
                    display: 'flex',
                    gap: 44,
                    marginBottom: 44
                  }}
                >
                  <Collapse style={{ width: '100%', height: '100%' }}>
                    {/* <Collapse style={{ width: '100%', height: '100%' }} defaultActiveKey="1"> */}
                    <Collapse.Panel key="1" header={getCollapseQuestionTitle(name)}>
                      {/* TODO: Use [name, 'title'] to get the question title for the collapse */}
                      <Space direction="vertical" size="small" style={{ width: '100%' }}>
                        <Form.Item
                          {...restField}
                          label="Survey Question"
                          style={{ width: '100%' }}
                          name={[name, 'title']}
                          dependencies={fields.map(({ name: idx }) => [formListName, idx, 'isFork'])}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the Survey Question'
                            },
                          ]}
                        >
                          <InterpolationInput
                            placeholder="Enter Survey Question"
                            variables={getVariables({
                              isForkDisabled: !(name > firstForkQuestionIdx),
                            })}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label="Segment title (for segment chart)"
                          style={{ width: '100%' }}
                          name={[name, 'titleShort']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter the Segment Title'
                            },
                          ]}
                        >
                          <Input placeholder="Enter the Segment Title" style={{ width: '100%' }} />
                        </Form.Item>

                        <FormItem
                          htmlFor=""
                          label={(
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between'
                              }}
                            >
                              Answer alternatives

                              <StyledDynamicCustomProjectHorizontalFormItem
                                {...restField}
                                label="Survey&nbsp;Fork"
                                name={[name, 'isFork']}
                                valuePropName="checked"
                                hidden={shouldIsForkBeBlocked(name)}
                              >
                                <Switch onChange={getIsForkSwitchHandler(name)} />
                              </StyledDynamicCustomProjectHorizontalFormItem>

                              <StyledDynamicCustomProjectHorizontalFormItem
                                {...restField}
                                label="Quota&nbsp;on&nbsp;responses"
                                name={[name, 'enableResponsesLimit']}
                                valuePropName="checked"
                              >
                                <Switch />
                              </StyledDynamicCustomProjectHorizontalFormItem>

                              <StyledDynamicCustomProjectHorizontalFormItem
                                {...restField}
                                label="Multiselect"
                                name={[name, 'multiselect']}
                                valuePropName="checked"
                              >
                                <Switch disabled={getIsQuestionFork(name)} />
                              </StyledDynamicCustomProjectHorizontalFormItem>

                              <StyledDynamicCustomProjectHorizontalFormItem
                                label="Max&nbsp;selected"
                                name={[name, 'maxSelected']}
                                rules={[{ type: 'number', min: 2 }]}
                              >
                                <InputNumber
                                  disabled={getIsQuestionFork(name)}
                                  placeholder="Max selected"
                                  type="number"
                                  min={2}
                                />
                              </StyledDynamicCustomProjectHorizontalFormItem>
                            </div>
                        )}
                        >
                          <Form.List name={[name, 'options']}>
                            {(subFields, subOpt) => (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  rowGap: 16,
                                  // width: '100%',
                                  border: '1px dashed #D5D5DC',
                                  borderRadius: 4,
                                  padding: 30,
                                }}
                              >
                                {subFields.map((subField, answerAlternativeIndex) => (
                                  <DynamicAnswerAlternativeField
                                    key={`step-${key}-answer-alternative-${subField.key}`}
                                    name={subField.name}
                                    answerAlternativeIndex={answerAlternativeIndex}
                                    onRemoveClick={subOpt.remove}
                                    formValue={initialValue?.[name]?.options?.[subField.name]}
                                    multiselect={initialValue?.[name]?.multiselect}
                                    variables={getVariables({
                                      isForkDisabled: !(name > firstForkQuestionIdx),
                                    })}
                                    enableResponsesLimit={initialValue?.[name]?.enableResponsesLimit}
                                  />
                                ))}

                                <div style={{ display: 'flex', gap: 100 }}>
                                  <Button
                                    type="dashed"
                                    style={{ width: '50%' }}
                                    onClick={() => subOpt.add()}
                                  >
                                    + Add answer alternative
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Form.List>
                        </FormItem>

                        <Form.Item
                          {...restField}
                          label="Disable answer alternatives randomization"
                          style={{ width: '100%' }}
                          name={[name, 'skipOptionsShuffle']}
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label="Place the question after WTP questions?"
                          style={{ width: '100%' }}
                          name={[name, 'placeAfterWTP']}
                          valuePropName="checked"
                        >
                          <Switch />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          style={{ width: '100%' }}
                          name={[name, 'image', 'src']}
                          valuePropName="fileList"
                          getValueFromEvent={normalizeFile}
                        >
                          <Upload
                            accept="image/*"
                            beforeUpload={() => false}
                            listType="picture-card"
                            onPreview={handlePreview}
                            maxCount={1}
                          >
                            Click to upload an image
                          </Upload>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label="Image caption"
                          style={{ width: '100%' }}
                          name={[name, 'image', 'caption']}
                        >
                          <Input placeholder="Enter the Image caption" style={{ width: '100%' }} />
                        </Form.Item>
                      </Space>
                    </Collapse.Panel>
                  </Collapse>

                  <div style={{ height: 'fit-content' }}>
                    <Button
                      variant="secondary"
                      onClick={() => move(name, name - 1)}
                      disabled={name === 0}
                      style={{ marginBottom: 10 }}
                    >
                      <UpOutlined />
                    </Button>

                    <Button
                      variant="secondary"
                      onClick={() => move(name, name + 1)}
                      disabled={name === fields.length - 1}
                    >
                      <DownOutlined />
                    </Button>
                  </div>

                  <IconButton
                    danger
                    onClick={() => remove(name)}
                    shape="circle"
                  >
                    <MinusOutlined />
                  </IconButton>
                </div>
              </Fragment>
            ))}
            <Form.Item>
              <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Survey Question
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>


      <Modal
        open={previewOpen}
        title="Image preview"
        footer={null}
        onCancel={handleClosePreviewModal}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};
