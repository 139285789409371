// TODO: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateSimpleField = <T extends Record<string, string | any>, K extends keyof T>
  (object: T, key: K, trim?: boolean, maxLength?: number) => {
  let fieldValue = object?.[key];

  if (typeof fieldValue === 'string') {
    if (trim) {
      fieldValue = fieldValue.trim();
    }

    if (maxLength && fieldValue.length > maxLength) {
      return false;
    }
  }

  return !!fieldValue;
};

// TODO: Create interface
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateArrayField = <T extends Record<string, any | any[]>, K extends keyof T>
  (
    object: T,
    key: K,
    minLength: number,
    maxLength?: number
  ) => {
  // TODO: Create interface
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const arrayField: any[] = object?.[key];

  if (!arrayField) {
    return false;
  }

  const isMinLengthValid = arrayField.length >= minLength;
  let isMaxLengthValid = true;

  if (maxLength) {
    isMaxLengthValid = arrayField.length <= maxLength;
  }

  return Boolean(isMinLengthValid && isMaxLengthValid);
};

// export const validateArrayFieldStrictLength = <
//   // TODO: Create interface
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   T extends Record<string, any | any[]>,
//   K extends keyof T,
//   >
//   (object: T, key: K, length: number) => {
//   // TODO: Create interface
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   const arrayField: any[] = object?.[key];
//   return Boolean(arrayField && arrayField.length === length);
// };
