import { useMemo, useState } from 'react';
import { ProjectModeSelector } from '../../molecules/ProjectModeSelector';
import { ProjectWizardDefinition } from '../../molecules/ProjectModeSelector/types';
import AdminCreateProject from '../../pages/AdminCreateProject';
import AdminCreateCustomerProject from '../../pages/AdminCreateCustomerProject';
import { Card } from '../../atoms/Card';


export const AdminProjectWizard = () => {
  const [definition, setDefinition] = useState<Partial<ProjectWizardDefinition>>();

  const AdminCreateProjectVariant = useMemo(() => (
    definition?.type === 'standard'
      ? AdminCreateProject
      : AdminCreateCustomerProject
  ), [definition?.type]);

  return (
    <Card>
      {
        !definition
          ? (
            <ProjectModeSelector.Admin onModeSelect={setDefinition} />
          )
          : (
            <AdminCreateProjectVariant isPartnerPage />
          )
      }
    </Card>
  );
};
