import getNestedObjectValue from 'lodash/get';
import reactStringReplace from 'react-string-replace';
import { Typography } from 'antd';
import { ReactNode } from 'react';
import { TextProps } from 'antd/es/typography/Text';


export type HighlightType = 'danger' | 'success';

export const populateObjectValue = <T, >(
  string: string,
  object: T,
  defaultValue?: string
): T[keyof T] => {
  // Remove markers e.g. [test string]
  const key = string.slice(1, -1);
  return getNestedObjectValue(object, key, defaultValue) as T[keyof T];
};

// eslint-disable-next-line max-len
export const populateRegexValuesInString = <T, > ({
  string,
  object,
  regex = /\[.+?\]/g,
  keepMarkers
}: {
  string: string;
  object: T;
  regex?: RegExp;
  keepMarkers?: boolean;
}) => {
  return string.replaceAll(
    regex,
    (subString) => {
      const populatedValue = populateObjectValue(subString, object, subString) as string;

      if (keepMarkers) {
        return `[${populatedValue}]`;
      }

      return populatedValue;
    }
  );
};

export const highlightSpecialPhrase = ({ string, type, typographyProps = {} }
: { string: string, type?: HighlightType, typographyProps?: TextProps }) => {
  const regex = /(\*.+?\*)/g;

  return reactStringReplace(string, regex, (match, index) => {
    const key = match.slice(1, -1);

    return (
      <Typography.Text
        {...typographyProps}
        key={`${key}-${index}`}
        type={type}
      >
        {key}
      </Typography.Text>
    );
  });
};

export const populateAndWrapWithLinkSpecialPhrase = <T, >
  (string: string | ReactNode[], object: T, onClick?: (key: string) => void) => {
  const regex = /\[(?!fork\..*?])[^\]]+]/g;

  return reactStringReplace(string, regex, (match, index) => {
    let key = populateRegexValuesInString({
      string: match,
      object,
    });

    if (match === key) {
      key = match.slice(1, -1);
    }

    return onClick ? (
      <a
        key={`${key}-${index}`}
        href="/"
        onClick={(event) => {
          event.preventDefault();
          onClick(match.slice(1, -1));
        }}
      >
        {key}
      </a>
    ) : (
      <b key={`${key}-${index}`}>{key}</b>
    );
  });
};
