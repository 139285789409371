import {
  projectFormConfigV2,
  ProjectFormConfigV2Type, ProjectTypeConfigV2Type,
} from '../constants/projectFormConfig_v2';
import {
  projectFormConfig,
  ProjectFormConfigType,
  ProjectTypeConfigType,
} from '../constants/projectFormConfig';
import {
  golfProjectConfig,
  GolfProjectFormConfigType, GolfProjectProductConfigType,
} from '../constants/customProjectConfigs/golfProjectFormConfig';


export type FormConfigType =
  ProjectFormConfigType
  | ProjectFormConfigV2Type
  | GolfProjectFormConfigType;

export type ProductType =
  ProjectTypeConfigV2Type
  | ProjectTypeConfigType
  | GolfProjectProductConfigType;

interface GetProjectFormConfigArgs {
  schemaVersion?: number;
  useGolfConfig?: boolean;
}

export const getProjectFormConfig = (
  { schemaVersion, useGolfConfig }: GetProjectFormConfigArgs,
): FormConfigType => {
  if (useGolfConfig) {
    return golfProjectConfig;
  }

  if (schemaVersion === 2) {
    return projectFormConfigV2;
  }

  return projectFormConfig;
};
