import { FC, useMemo } from 'react';
import type { i18n, TFunction } from 'i18next';
import { Select } from '../../atoms/Select';
import { appLangs } from '../../../locales';
import { SelectProps } from '../../atoms/Select/Select.types';


interface TargetGroupLanguageSelectProps extends SelectProps {
  i18n: i18n;
  t: TFunction<'translation', undefined, 'translation'>;
}

export const TargetGroupLanguageSelect: FC<TargetGroupLanguageSelectProps> = ({
  i18n,
  t,
  ...props
}) => {
  const languageOptions = useMemo(() => {
    return appLangs.map((value) => ({ value, label: t(`language.${value}`) }));
  }, [t]);

  const handleLanguageDropdown = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Select
      style={{ width: 150 }}
      options={languageOptions}
      onSelect={handleLanguageDropdown}
      {...props}
    />
  );
};
