import { ReactNode } from 'react';
import { highlightSpecialPhrase } from '../previewFormatters';
import { StyledFormItemWithEditButtonWrapper } from './RenderProjectSurveyPreview.styles';
import { FormItem } from '../../components/molecules/FormItem';
import { RadioGroup } from '../../components/molecules/RadioGroup';
import { IconButton } from '../../components/atoms/IconButton';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg';
import { CheckboxGroup } from '../../components/molecules/CheckboxGroup';


export const composeBodySurveyFormItem = ({
  currentCategoryStepSpellCheckedConfig,
  currentStepSpellCheckedConfig,
  currentCategoryConfig,
  stepConfig,
  project,
  t,
  populateAndWrapWithLinkProjectValue
}) => {
  const spellcheckedBody = currentCategoryStepSpellCheckedConfig?.body || currentStepSpellCheckedConfig?.body;
  const bodySource = spellcheckedBody || currentCategoryConfig?.body || stepConfig.body;

  const productQuantity = project.item.quantity;
  const formattedQuantity = `${productQuantity?.amount} ${productQuantity?.measurement} [item.name]`;

  const body = bodySource?.map((bodyItem: string) => {
    let translatedBodyItem = bodyItem;

    if (bodyItem.startsWith('surveyConfig')) {
      translatedBodyItem = t(bodyItem);
    }

    if (productQuantity?.amount && productQuantity?.measurement) {
      translatedBodyItem = translatedBodyItem.replace('[item.name]', formattedQuantity);
    }

    const normalizerOptions = {
      string: translatedBodyItem,
      typographyProps: { style: { fontSize: 16 } },
    };
    const normalizedBodyItem = highlightSpecialPhrase(normalizerOptions);

    const result = populateAndWrapWithLinkProjectValue(normalizedBodyItem as ReactNode[]);

    return (
      <p
        key={`${stepConfig.step}-paragraph-${bodyItem}`}
        style={{
          fontWeight: 500,
          fontSize: 16,
          marginTop: 0,
          marginBottom: 8,
        }}
      >
        {result}
      </p>
    );
  });

  return (
    <li key={stepConfig.step}>
      {/* <li key={stepConfig.step} style={{ marginTop: 32 }}> */}
      {body}
    </li>
  );
};

export const composeCheckboxListSurveyFormItem = async ({
  options,
  multiselect,
  currentCategoryConfig,
  renderOptionsWithTranslate,
  project,
  stepConfig,
  currentItemCategory,
  currentCategoryStepSpellCheckedConfig,
  populateAndWrapWithLinkProjectValue,
  onVariableClick,
  t,
  skipOptionsTranslate,
  optionLabelKey = 'label'
}) => {
  if (!options
    || !Array.isArray(options)
    || (Array.isArray(options) && !options.length)
  ) {
    return null;
  }

  let parsedOptions: { label: string; value: string }[] = options;

  const shouldParseOptions = options.every((option) => typeof option === 'string');
  if (shouldParseOptions) {
    try {
      parsedOptions = options.map((option) => {
        return JSON.parse(option);
      }) as { label: string; value: string }[];
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  const defaultOptions = currentCategoryConfig?.defaultOptions;
  if (defaultOptions) {
    const optionsWithPopulatedValues = currentCategoryConfig.defaultOptions.map((option) => {
      return {
        ...option,
        label: populateAndWrapWithLinkProjectValue(option.label)
      };
    });
    parsedOptions.push(...optionsWithPopulatedValues);
  }

  let translatedOptions = parsedOptions;

  if (!skipOptionsTranslate) {
    translatedOptions = await renderOptionsWithTranslate(
      parsedOptions,
      'value',
      `surveyConfig.${project?.type}Steps.${stepConfig.step}.categories.${currentItemCategory}.options`
    );
  }
  const titleSource = currentCategoryStepSpellCheckedConfig?.translatedTitle
    || currentCategoryStepSpellCheckedConfig?.title;

  const title = titleSource || t(currentCategoryConfig?.title);
  const populatedTitle = populateAndWrapWithLinkProjectValue(title);

  return (
    <li key={stepConfig.step}>
      <StyledFormItemWithEditButtonWrapper>
        <FormItem
          label={populatedTitle}
          largeLabel
        >
          {multiselect ? (
            <CheckboxGroup
              options={translatedOptions}
            />
          ) : (
            <RadioGroup
              options={translatedOptions}
              optionLabelKey={optionLabelKey}
            />
          )}
        </FormItem>

        {onVariableClick ? (
          <IconButton
            variant="secondary"
            size="small"
            shape="circle"
            onClick={() => onVariableClick(stepConfig.step)}
          >
            <PencilIcon />
          </IconButton>
        ) : null}

      </StyledFormItemWithEditButtonWrapper>
    </li>
  );
};
