import { wait } from './wait';


export const callbackWithRetry = async <T> (
  callback: () => T | Promise<T>,
  {
    maxRetries,
    retryTimeout,
  } = {
    maxRetries: 2,
    retryTimeout: 1000,
  },
): Promise<T> => {
  try {
    return await callback();
  } catch (err) {
    if (maxRetries) {
      await wait(retryTimeout);
      return callbackWithRetry(callback, { maxRetries: maxRetries - 1, retryTimeout });
    }
    throw err;
  }
};

export const callbackWithExponentialBackoff = async <T> (
  callback: () => T | Promise<T>,
  checkCondition: (result: T) => boolean,
  {
    maxRetries,
    retryTimeout,
  } = {
    maxRetries: 2,
    retryTimeout: 1000,
  },
): Promise<T> => {
  try {
    const result = await callback();

    const shouldRetry = !result || !checkCondition(result);
    if (shouldRetry && maxRetries) {
      await wait(retryTimeout);
      return await callbackWithExponentialBackoff(
        callback,
        checkCondition,
        { maxRetries: maxRetries - 1, retryTimeout: retryTimeout * 2 }
      );
    }
    return result;
  } catch (err) {
    if (maxRetries) {
      await wait(retryTimeout);
      return callbackWithExponentialBackoff(
        callback,
        checkCondition,
        { maxRetries: maxRetries - 1, retryTimeout: retryTimeout * 2 }
      );
    }
    throw err;
  }
};
