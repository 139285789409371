import {
  FC,
  useCallback, useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Col,
  Collapse, Form,
  Row,
  Space
} from 'antd';
import {
  StyledRegionCollapseTrigger,
  StyledRegionSelectPopupContainer,
  StyledTargetGroupStepFormItemRegionCollapse
} from './TargetGroupCountryRegionsSelector.styles';
import { FormItem } from '../../molecules/FormItem';
import { Select } from '../../atoms/Select';
import type { CINTCountry, CINTRegionType } from '../../../types/cint';
import { ReactComponent as PlusCircleIcon } from '../../../assets/icons/plus-circle.svg';


interface TargetGroupCountrySelectorProps {
  countries?: CINTCountry[];
  onCountryValueChange?: (countryId: number) => void;
  resetRegionState?: (props?: { keepRegionType?: boolean }) => void;
  buttonText?: string;
  isRegionTypeRequired?: boolean;
}

export const TargetGroupCountryRegionsSelector: FC<TargetGroupCountrySelectorProps> = ({
  countries,
  onCountryValueChange,
  resetRegionState,
  buttonText,
  isRegionTypeRequired,
}) => {
  const form = Form.useFormInstance();

  const [country, setCountry] = useState<CINTCountry>();
  const [regionType, setRegionType] = useState<CINTRegionType | undefined>();
  const [regions, setRegions] = useState<{ value: number, label: string }[] | undefined>();

  const regionFormItemRef = useRef(null);

  const [activeKey, setActiveKey] = useState<string | undefined>(isRegionTypeRequired ? '1' : undefined);
  const triggerActiveKey = () => setActiveKey((prevState) => {
    if (!prevState) {
      return '1';
    }

    return;
  });

  useEffect(() => {
    const currentCountry = form.getFieldValue(['targetGroup', 'geo', 'country']);
    const currentRegionType = form.getFieldValue(['targetGroup', 'geo', 'regionType']);
    const currentRegions = form.getFieldValue(['targetGroup', 'geo', 'regions']);

    if (currentCountry) {
      const selectedCountry = countries?.find((item) => item.id === currentCountry.value);
      setCountry(selectedCountry);

      if (currentRegionType) {
        const selectedRegionType = selectedCountry?.regionTypes?.find(
          (item) => item.id === currentRegionType.value
        );
        setRegionType(selectedRegionType);

        if (currentRegions) {
          setRegions(currentRegions);
        }
      }
    }
  }, [countries, form]);

  const regionsTriggerText = useMemo(() => {
    const regionNames = regions?.map((region) => region.label);

    if (regionNames?.length) {
      return regionNames.join(', ');
    }

    return buttonText ?? 'Region targeting (optional)';
  }, [buttonText, regions]);

  const countryOptions = useMemo(() => countries?.map((item) => ({
    label: item.name,
    value: item.id,
  })), [countries]);

  const regionTypeOptions = useMemo(() => country?.regionTypes.map((regionTypeObject) => ({
    label: regionTypeObject.name,
    value: regionTypeObject.id,
  })), [country?.regionTypes]);

  const regionOptions = useMemo(() => regionType?.regions.map((region) => ({
    label: region.name,
    value: region.id,
  })), [regionType?.regions]);

  const onCountryChange = useCallback(({ value }: { value: number }) => {
    const selectedCountry = countries?.find((item) => item.id === value);

    onCountryValueChange?.(value);

    setCountry(selectedCountry);

    form.setFieldValue(['targetGroup', 'geo', 'regionType'], undefined);
    form.setFieldValue(['targetGroup', 'geo', 'regions'], undefined);

    setRegionType(undefined);
    setRegions(undefined);
    resetRegionState?.();
  }, [countries, form, resetRegionState, onCountryValueChange]);

  const onRegionTypeChange = useCallback((regionTypeObject: { value: number }) => {
    if (!regionTypeObject) {
      return;
    }

    const selectedRegionType = country?.regionTypes?.find(
      (item) => item.id === regionTypeObject.value
    );

    form.setFieldValue(['targetGroup', 'geo', 'regions'], undefined);

    setRegionType(selectedRegionType);
    setRegions(undefined);
    resetRegionState?.({ keepRegionType: true });
  }, [country?.regionTypes, form, resetRegionState]);

  const onRegionTypeClear = useCallback(() => {
    form.setFieldValue(['targetGroup', 'geo', 'regionType'], undefined);
    form.setFieldValue(['targetGroup', 'geo', 'regions'], undefined);

    setRegionType(undefined);
    setRegions(undefined);
    resetRegionState?.();
  }, [form, resetRegionState]);

  const onRegionsChange = useCallback((selectedRegions: { value: number, label: string }[]) => {
    setRegions(selectedRegions);
  }, []);

  return (
    <>
      <Space.Compact>
        <Row style={{ maxWidth: 500, gap: 16 }} wrap={false}>
          <Col>
            <FormItem
              name={['targetGroup', 'geo', 'country']}
              rules={[{ required: true, message: 'Please select Country!' }]}
            >
              <Select
                style={{ minWidth: 150 }}
                placeholder="Country"
                options={countryOptions}
                labelInValue
                onChange={onCountryChange}
                showSearch
                filterOption={
                  (input, option) => {
                    if (typeof option?.label === 'string') {
                      return option?.label.toLowerCase().includes(input.toLowerCase());
                    }
                    return false;
                  }
                }
              />
            </FormItem>
          </Col>

          <Col flex="auto">
            <StyledRegionCollapseTrigger
              active={!!activeKey}
              htmlType="button"
              onClick={triggerActiveKey}
              variant="tertiary"
              disabled={!country}
              icon={<PlusCircleIcon />}
            >
              {regionsTriggerText}
            </StyledRegionCollapseTrigger>
          </Col>
        </Row>
      </Space.Compact>

      <StyledTargetGroupStepFormItemRegionCollapse
        activeKey={activeKey}
        collapsible="disabled"
        ghost
      >
        <Collapse.Panel key="1" header="header">
          <FormItem
            name={['targetGroup', 'geo', 'regionType']}
            validateTrigger={['onChange', 'onBlur']}
            rules={[{ required: !!isRegionTypeRequired, message: 'Please select Region Type!' }]}
          >
            <Select
              placeholder="Region types"
              options={regionTypeOptions}
              disabled={!regionTypeOptions}
              onChange={onRegionTypeChange}
              labelInValue
              allowClear
              onClear={onRegionTypeClear}
              filterSort={
                (optionA, optionB) => {
                  if (typeof optionA.label === 'string' && typeof optionB.label === 'string') {
                    return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase());
                  }
                  return 0;
                }
              }
            />
          </FormItem>

          <FormItem name={['targetGroup', 'geo', 'regions']}>
            <Select
              selectorMaxWidth="100%"
              size="middle"
              open
              mode="multiple"
              placeholder="Regions"
              options={regionOptions}
              disabled={!regionOptions}
              onChange={onRegionsChange}
              labelInValue
              showSearch
              getPopupContainer={(triggerNode) => regionFormItemRef.current ?? triggerNode.parentElement}
              allowClear
              defaultActiveFirstOption={false}
              menuItemSelectedIcon={null}
              virtual={false}
              filterOption={
                (input, option) => {
                  if (typeof option?.label === 'string') {
                    return option?.label.toLowerCase().includes(input.toLowerCase());
                  }
                  return false;
                }
              }
              filterSort={
                (optionA, optionB) => {
                  if (typeof optionA.label === 'string' && typeof optionB.label === 'string') {
                    return (optionA?.label ?? '').toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase());
                  }
                  return 0;
                }
              }
            />

          </FormItem>
          <StyledRegionSelectPopupContainer
            ref={regionFormItemRef}
          />
        </Collapse.Panel>
      </StyledTargetGroupStepFormItemRegionCollapse>
    </>
  );
};
