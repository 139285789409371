import type { FC } from 'react';
import { TextAreaProps as AntDTextAreaProps } from 'antd/es/input';
import { StyledTextArea } from './TextArea.styles';


type TextAreaProps = AntDTextAreaProps;

export const TextArea: FC<TextAreaProps> = (props) => (
  <StyledTextArea {...props} />
);
