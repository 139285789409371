import translate from 'deepl';


export const deeplTranslate = async (text, lang) => {
  try {
    const resp = await translate({
      free_api: false,
      text,
      target_lang: lang,
      auth_key: process.env.REACT_APP_DEEPL_API_KEY || '',
    });

    return resp.data;
  } catch (e) {
    console.log(e);
    return;
  }
};
