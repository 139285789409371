import type { BaseEditor, Descendant, Element } from 'slate';
import type { ReactEditor } from 'slate-react';
import set from 'lodash/set';
import escapeRegExp from 'lodash/escapeRegExp';
import type { InterpolationCategory } from './types';
import { NodeType } from './enums';


const isReadonlyNode = (element: Element) => element.type === NodeType.Interpolation;

export const withInterpolation = (editor: BaseEditor & ReactEditor) => {
  const { isInline, isVoid, markableVoid } = editor;
  set(editor, 'isInline', (element: Element) => isReadonlyNode(element) || isInline(element));
  set(editor, 'isVoid', (element: Element) => isReadonlyNode(element) || isVoid(element));
  set(editor, 'markableVoid', (element: Element) => isReadonlyNode(element) || markableVoid(element));
  return editor;
};

export const getInitialValue = (variables: InterpolationCategory[], value = ''): Descendant[] => {
  const enabledVariables = variables
    .flatMap(({ children }) => children)
    .filter(({ disabled }) => !disabled);

  const interpolationRegExp = new RegExp(`(${
    enabledVariables
      .map(({ interpolation }) => escapeRegExp(interpolation))
      .join('|')
  })`, 'g');

  const parts = value.split(interpolationRegExp);
  return [
    {
      children: parts.map((v) => {
        const variable = enabledVariables.find(({ interpolation }) => interpolation === v);
        if (variable) {
          return {
            type: NodeType.Interpolation,
            display: variable.display,
            description: variable.description,
            children: [{ text: variable.interpolation }],
          };
        }
        return { text: v };
      }),
    },
  ];
};
