import type { FC, ReactNode } from 'react';
import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Form, InputNumber, Select
} from 'antd';
import { useTranslation } from 'react-i18next';
import type { Project } from '../../types/project';
import {
  CategorySurveyConfig,
  CategorySurveyStepConfigType,
  paymentMethodStepConfig,
  usageFrequencyStepConfig
} from '../../constants/surveyConfig';
import { RadioGroup } from '../../components/molecules/RadioGroup';
import { FormItem } from '../../components/molecules/FormItem';
import { CheckboxGroup } from '../../components/molecules/CheckboxGroup';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg';
import { IconButton } from '../../components/atoms/IconButton';
import {
  StyledFormItemWithEditButtonWrapper, StyledRenderProjectSurveyPreviewList,
} from './RenderProjectSurveyPreview.styles';
import {
  highlightSpecialPhrase,
  populateAndWrapWithLinkSpecialPhrase,
  populateObjectValue,
} from '../previewFormatters';
import '../../i18n';
import { appLangs } from '../../locales';
import { useOptionsTranslation } from '../../hooks/useOptionsTranslation';
import { getSurveyConfig } from '../getSurveyConfig';
import { composeBodySurveyFormItem, composeCheckboxListSurveyFormItem } from './RenderProjectSurveyPreview.helper';


interface RenderProjectSurveyPreviewProps {
  project: Project;
  onVariableClick?: (key: string) => void;
  projectSurveyConfig?: CategorySurveyConfig | null;
  isLanguageSelectDisabled?: boolean;
  isUseProjectSurveyConfigSteps?: boolean;
  isUseGolfConfig?: boolean;
  isAdminPanel?: boolean;
}

export const RenderProjectSurveyPreview: FC<RenderProjectSurveyPreviewProps> = ({
  project,
  onVariableClick,
  projectSurveyConfig,
  isLanguageSelectDisabled,
  isUseProjectSurveyConfigSteps,
  isUseGolfConfig,
  isAdminPanel,
}) => {
  const { i18n, t } = useTranslation();
  const { renderOptionsWithTranslate } = useOptionsTranslation();

  const [renderedSteps, setRenderedSteps] = useState<(JSX.Element | null)[]>([]);

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const projectTypeSurveyConfig = useMemo(() => {
    const type = project?.type;
    if (!project || !type) {
      return null;
    }

    const surveyConfig = getSurveyConfig(isUseGolfConfig);
    const config = { ...surveyConfig[project.type] };

    if (project.aim === 'change_payment_model') {
      const paymentMethodStep = paymentMethodStepConfig[project.type] as CategorySurveyStepConfigType;

      const placementIndex = config.steps.findIndex((stepConfig) => stepConfig.step.startsWith('price'));

      const newSteps = [...config.steps];
      newSteps.splice(placementIndex, 0, paymentMethodStep);
      config.steps = newSteps as CategorySurveyConfig['steps'];
    }
    if (project.item?.usageFrequency) {
      const isInterestedStepIndex = config.steps.findIndex((stepConfig) => stepConfig.step === 'isInterested');
      const usageFrequencyStep = usageFrequencyStepConfig[project.type] as CategorySurveyStepConfigType;

      const newSteps = [...config.steps];

      newSteps.splice(isInterestedStepIndex + 1, 0, usageFrequencyStep);

      config.steps = newSteps as CategorySurveyConfig['steps'];
    }

    return config;
  }, [isUseGolfConfig, project]);

  const populateAndWrapWithLinkProjectValue = useCallback((string: string | ReactNode[] | null) => {
    if (!string) return;
    let translatedProject = project;

    if (isLanguageSelectDisabled) {
      const {
        name,
        description,
        translatedName,
        translatedDescription
      } = project.item || {};

      translatedProject = {
        item: {
          name: translatedName || name,
          description: translatedDescription || description
        }
      } as typeof project;
    }
    const onClick = () => onVariableClick?.('description');

    return populateAndWrapWithLinkSpecialPhrase(string, translatedProject, onClick);
  }, [isLanguageSelectDisabled, onVariableClick, project]);

  const populateProjectValue = useCallback((string: string) => {
    return populateObjectValue(string, project);
  }, [project]);

  useEffect(() => {
    const renderSteps = async () => {
      // TODO: Might break main flow logic
      let currentConfigSteps = projectTypeSurveyConfig?.steps;
      if (isUseProjectSurveyConfigSteps && projectSurveyConfig?.steps) {
        currentConfigSteps = projectSurveyConfig?.steps;
      }

      if (!currentConfigSteps) return;

      const renderedStepsPromises = currentConfigSteps.map((stepConfig) => {
        const currentItemCategory = project?.item?.category;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const currentCategoryConfig = currentItemCategory && stepConfig.categories?.[currentItemCategory];

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const currentStepSpellCheckedConfig = projectSurveyConfig?.steps?.find((step) => {
          return step.step === stepConfig.step;
        });
        const currentCategoryStepSpellCheckedConfig = currentStepSpellCheckedConfig?.categories?.[currentItemCategory];

        switch (stepConfig.step) {
          case 'intro': {
            const bodyContent = currentStepSpellCheckedConfig?.body || stepConfig.body;

            const body = bodyContent?.map((bodyItem) => {
              let translatedBodyItem = bodyItem;

              if (bodyItem.startsWith('surveyConfig')) {
                translatedBodyItem = t(bodyItem);
              }

              const normalizedBodyItem = populateAndWrapWithLinkProjectValue(translatedBodyItem);

              return (
                <p
                  key={`${stepConfig.step}-paragraph-${bodyItem}`}
                  style={{ fontWeight: 500, fontSize: 16, marginTop: 0 }}
                >
                  {normalizedBodyItem}
                </p>
              );
            });

            return Promise.resolve(
              <li key={stepConfig.step} style={{ marginBottom: 32 }}>
                <StyledFormItemWithEditButtonWrapper>
                  <div>
                    {body}
                  </div>

                  {onVariableClick ? (
                    <IconButton
                      variant="secondary"
                      size="small"
                      shape="circle"
                      onClick={() => onVariableClick('description')}
                    >
                      <PencilIcon />
                    </IconButton>
                  ) : null}
                </StyledFormItemWithEditButtonWrapper>
              </li>
            );
          }
          case 'isInterested':
          case 'environmentSustainability': {
            if (!currentCategoryConfig) {
              return Promise.resolve(null);
            }
            const label = currentCategoryStepSpellCheckedConfig?.title || t(currentCategoryConfig?.title);

            return renderOptionsWithTranslate(currentCategoryConfig?.options)
              .then((options) => {
                return (
                  <li key={stepConfig.step}>
                    <FormItem
                      label={populateAndWrapWithLinkProjectValue(label)}
                      largeLabel
                    >
                      <RadioGroup options={options} />
                    </FormItem>
                  </li>
                );
              });
          }
          case 'usageFrequency':
          case 'paymentMethod':
            if (!currentCategoryConfig) {
              return Promise.resolve(null);
            }
            return renderOptionsWithTranslate(currentCategoryConfig?.options).then((options) => {
              const title = currentCategoryStepSpellCheckedConfig?.title || t(currentCategoryConfig?.title);
              const populatedTitle = populateAndWrapWithLinkProjectValue(title);

              return (
                <li key={stepConfig.step}>
                  <StyledFormItemWithEditButtonWrapper>
                    <FormItem
                      label={populatedTitle}
                      largeLabel
                    >
                      <RadioGroup options={options} />
                    </FormItem>

                    {onVariableClick ? (
                      <IconButton
                        variant="secondary"
                        size="small"
                        shape="circle"
                        onClick={() => onVariableClick('description')}
                      >
                        <PencilIcon />
                      </IconButton>
                    ) : null}
                  </StyledFormItemWithEditButtonWrapper>
                </li>
              );
            });
          case 'feature':
          case 'uniqueFeature':
          case 'disadvantage':
          case 'usage':
          case 'retailLocation':
          case 'retailPeriod': {
            if (!currentCategoryConfig) {
              return Promise.resolve(null);
            }
            const options = !!currentCategoryConfig?.options && populateProjectValue(currentCategoryConfig?.options);
            let parsedOptions: { label: string; value: string }[];

            if (!options
              || !Array.isArray(options)
              || (Array.isArray(options) && !options.length)
            ) {
              return Promise.resolve(null);
            }

            try {
              parsedOptions = options.map((option) => {
                return JSON.parse(option);
              }) as { label: string; value: string }[];
            } catch (err) {
              console.log(err);
              return Promise.resolve(null);
            }

            return renderOptionsWithTranslate(
              parsedOptions,
              'value',
              `surveyConfig.${project?.type}Steps.${stepConfig.step}.categories.${currentItemCategory}.options`
            ).then((translatedOptions) => {
              const title = currentCategoryStepSpellCheckedConfig?.title || t(currentCategoryConfig?.title);
              const populatedTitle = populateAndWrapWithLinkProjectValue(title);

              return (
                <li key={stepConfig.step}>
                  <StyledFormItemWithEditButtonWrapper>
                    <FormItem
                      label={populatedTitle}
                      largeLabel
                    >
                      <RadioGroup
                        options={translatedOptions}
                      />
                    </FormItem>

                    {onVariableClick ? (
                      <IconButton
                        variant="secondary"
                        size="small"
                        shape="circle"
                        onClick={() => onVariableClick(stepConfig.step)}
                      >
                        <PencilIcon />
                      </IconButton>
                    ) : null}

                  </StyledFormItemWithEditButtonWrapper>
                </li>
              );
            });
          }
          case 'competitor': {
            if (!currentCategoryConfig) {
              return Promise.resolve(null);
            }
            const options = !!currentCategoryConfig?.options
                && populateProjectValue(currentCategoryConfig?.options);
            let parsedOptions: { label: string; value: string }[];

            if (!options
                || !Array.isArray(options)
                || (Array.isArray(options) && !options.length)
            ) {
              return Promise.resolve(null);
            }

            try {
              parsedOptions = options.map((option) => {
                return JSON.parse(option);
              }) as { label: string; value: string }[];
            } catch (err) {
              console.log(err);
              return Promise.resolve(null);
            }
            const title = currentCategoryStepSpellCheckedConfig?.title || t(currentCategoryConfig?.title);
            const populatedTitle = populateAndWrapWithLinkProjectValue(title);

            return Promise.resolve(
              <li key={stepConfig.step}>
                <StyledFormItemWithEditButtonWrapper>
                  <FormItem
                    label={populatedTitle}
                    largeLabel
                  >
                    <RadioGroup options={parsedOptions} />
                  </FormItem>

                  {onVariableClick ? (
                    <IconButton
                      variant="secondary"
                      size="small"
                      shape="circle"
                      onClick={() => onVariableClick(stepConfig.step)}
                    >
                      <PencilIcon />
                    </IconButton>
                  ) : null}
                </StyledFormItemWithEditButtonWrapper>
              </li>
            );
          }
          case 'socialMedia': {
            if (!currentCategoryConfig) {
              return Promise.resolve(null);
            }
            const options = currentCategoryConfig?.options;
            if (!options || !Array.isArray(options)) {
              return Promise.resolve(null);
            }

            return renderOptionsWithTranslate(options).then((translatedOptions) => {
              return (
                <li key={stepConfig.step}>
                  <FormItem
                    label={t(currentCategoryConfig?.title)}
                    subTitle={t(currentCategoryConfig?.subTitle) || ''}
                    largeLabel
                  >
                    <CheckboxGroup
                      options={translatedOptions}
                    />
                  </FormItem>
                </li>
              );
            });
          }
          case 'price.low':
          case 'price.high':
          case 'price.midHigh':
          case 'price.midLow': {
            const spellcheckedBody = currentCategoryStepSpellCheckedConfig?.body || currentStepSpellCheckedConfig?.body;
            const bodySource = spellcheckedBody || currentCategoryConfig?.body || stepConfig.body;

            const productQuantity = project.item.quantity;
            let formattedQuantity = `[item.name] ${productQuantity?.amount} ${productQuantity?.measurement}`;

            if (isUseGolfConfig) {
              formattedQuantity = `${productQuantity?.amount} ${productQuantity?.measurement} [item.name]`;
            }

            const body = bodySource?.map((bodyItem: string) => {
              let translatedBodyItem = bodyItem;

              if (bodyItem.startsWith('surveyConfig')) {
                translatedBodyItem = t(bodyItem);
              }

              if (productQuantity?.amount && productQuantity?.measurement) {
                translatedBodyItem = translatedBodyItem.replace('[item.name]', formattedQuantity);
              }

              const normalizerOptions = {
                string: translatedBodyItem,
                typographyProps: { style: { fontSize: 16 } },
              };
              const normalizedBodyItem = highlightSpecialPhrase(normalizerOptions);
              const result = populateAndWrapWithLinkProjectValue(normalizedBodyItem as ReactNode[]);

              return (
                <p
                  key={`${stepConfig.step}-paragraph-${bodyItem}`}
                  style={{
                    fontWeight: 500, fontSize: 16, marginTop: 0, marginBottom: 8,
                  }}
                >
                  {result}
                </p>
              );
            });

            const priceObject = project?.item.price;
            const paymentModel = priceObject?.paymentModel;

            const wtpPrefix = priceObject?.wtpPrefix || priceObject?.currency;
            let wtpPostfix = priceObject?.wtpPostfix;

            if (!wtpPostfix && paymentModel) {
              let paymentMethodLabel = paymentModel;

              try {
                const parsedPaymentMethod = JSON.parse(paymentModel);
                const isPerUnitSold = parsedPaymentMethod.value === 'per_unit_sold';
                const isMeasurementDozen = productQuantity?.measurement === 'dozen';

                if (isPerUnitSold && isMeasurementDozen) {
                  const { /* amount, */ measurement } = productQuantity;

                  const measurementTranslationKey = `surveyConfig.quantity.${measurement}`;
                  const translatedMeasurement = t(measurementTranslationKey);

                  const perTranslated = t('surveyConfig.quantity.per');

                  paymentMethodLabel = `${perTranslated} ${translatedMeasurement}`;
                } else {
                  // Note: if isAdminPanel => use consolidated list of payment methods translations
                  const isUseConsolidatedPaymentModels = isAdminPanel || project?.isProvisioned;

                  if (isUseConsolidatedPaymentModels) {
                    paymentMethodLabel = t(`surveyConfig.paymentModels.${parsedPaymentMethod.value}`);
                  } else {
                    // eslint-disable-next-line max-len
                    paymentMethodLabel = t(`surveyConfig.${project?.type}Steps.paymentMethod.categories.${currentItemCategory}.options.${parsedPaymentMethod.value}`);
                  }
                }
              } catch (err) {
                console.log('err', err);
              }

              if (paymentMethodLabel) {
                wtpPostfix = paymentMethodLabel;
              }
            }

            return Promise.resolve(
              <li key={stepConfig.step} style={{ marginTop: 32 }}>
                {body}
                <InputNumber
                  addonBefore={wtpPrefix}
                  addonAfter={wtpPostfix}
                  style={{ width: 300 }}
                />
              </li>
            );
          }
          case 'income':
            return;
          default: {
            // Survey item with `body` field
            const bodyContent = currentStepSpellCheckedConfig?.body || stepConfig?.body;

            if (bodyContent) {
              const bodySurveyFormStep = composeBodySurveyFormItem({
                currentCategoryStepSpellCheckedConfig,
                currentStepSpellCheckedConfig,
                currentCategoryConfig,
                stepConfig,
                project,
                t,
                populateAndWrapWithLinkProjectValue
              });

              return Promise.resolve(bodySurveyFormStep);
            }

            // Survey item with `options` field
            let options = currentCategoryConfig?.options;

            if (typeof options === 'string') {
              options = populateProjectValue(currentCategoryConfig?.options);
            }

            if (options && Array.isArray(options) && options.length) {
              return composeCheckboxListSurveyFormItem({
                multiselect: stepConfig.multiselect,
                options,
                currentCategoryConfig,
                renderOptionsWithTranslate,
                project,
                stepConfig,
                currentItemCategory,
                currentCategoryStepSpellCheckedConfig,
                populateAndWrapWithLinkProjectValue,
                onVariableClick,
                t,
                skipOptionsTranslate: isUseGolfConfig,
                optionLabelKey: isAdminPanel ? 'translatedLabel' : 'label'
              });
            }

            return Promise.resolve(null);
          }
        }
      });

      const result = await Promise.all(renderedStepsPromises);

      setRenderedSteps(result);
    };

    renderSteps();
  }, [
    isUseProjectSurveyConfigSteps,
    isUseGolfConfig,
    projectSurveyConfig?.steps,
    onVariableClick,
    populateAndWrapWithLinkProjectValue,
    populateProjectValue,
    project.item.quantity,
    project.item?.category,
    project.item.price.currency,
    project.item.price.paymentModel,
    projectTypeSurveyConfig?.steps,
    project?.type,
    renderOptionsWithTranslate,
    t,
    project,
    isAdminPanel
  ]);

  const renderStepsWithIntro = useMemo(() => {
    if (projectTypeSurveyConfig && renderedSteps) {
      const stepsSource = projectSurveyConfig?.steps || projectTypeSurveyConfig.steps;

      const placementIndex = stepsSource.findIndex((stepConfig) => {
      // const placementIndex = projectTypeSurveyConfig.steps.findIndex((stepConfig) => {
        return stepConfig.step.startsWith('price');
      });

      const startSteps = renderedSteps.slice(0, placementIndex);
      const endSteps = renderedSteps.slice(placementIndex);

      const introSource = stepsSource[placementIndex];

      const { intro } = introSource;

      if (!intro) {
        return renderedSteps;
      }

      const isIntroArray = Array.isArray(intro);

      let introStepContent: JSX.Element | JSX.Element[];

      if (isIntroArray) {
        introStepContent = intro.map((introChunk) => {
          return (
            <h3
              key={`WTP-intro-${introChunk}`}
              style={{
                marginLeft: -26,
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              {introChunk}
            </h3>
          );
        });
      } else {
        // intro is a string
        let WTPIntro = intro;
        const productQuantity = project.item.quantity;

        if (intro.startsWith('surveyConfig')) {
          WTPIntro = t(intro);
        }

        if (productQuantity?.amount && productQuantity?.measurement) {
          let formattedQuantity = `[item.name] ${productQuantity?.amount} ${productQuantity?.measurement}`;
          if (isUseGolfConfig) {
            formattedQuantity = `${productQuantity?.amount} ${productQuantity?.measurement} [item.name]`;
          }

          WTPIntro = WTPIntro.replace('[item.name]', formattedQuantity);
        }

        const formattedWTPIntro = WTPIntro && populateAndWrapWithLinkProjectValue(WTPIntro);
        introStepContent = <h3 key="WTP-intro" style={{ marginLeft: -26 }}>{formattedWTPIntro}</h3>;
      }

      return [...startSteps, introStepContent, ...endSteps];
    }

    return renderedSteps;
  }, [
    projectTypeSurveyConfig,
    renderedSteps,
    projectSurveyConfig?.steps,
    project.item.quantity,
    populateAndWrapWithLinkProjectValue,
    t,
    isUseGolfConfig
  ]);

  const selectOptions = useMemo(() => {
    return appLangs.map((value) => ({ value, label: t(`language.${value}`) }));
  }, [t]);

  const defaultLanguageSelectValue = useMemo(() => {
    // isLanguageSelectDisabled === component is being used outside the project wizard
    if (isLanguageSelectDisabled) {
      return project.surveyLanguage ?? 'en';
    }
    return i18n.language?.toLowerCase();
  }, [i18n.language, isLanguageSelectDisabled, project.surveyLanguage]);

  return (
    <Form
      requiredMark={false}
      layout="vertical"
    >
      <>
        {/* TODO: Add a 'Change Survey language' label */}
        <Select
          defaultValue={defaultLanguageSelectValue}
          disabled={isLanguageSelectDisabled || isUseGolfConfig}
          options={selectOptions}
          onChange={handleLanguageChange}
          style={{
            position: 'absolute',
            top: 23,
            right: 50,
            width: 125
          }}
        />
        <StyledRenderProjectSurveyPreviewList>
          {renderStepsWithIntro}
        </StyledRenderProjectSurveyPreviewList>
      </>
    </Form>
  );
};
