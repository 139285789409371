import styled from 'styled-components';
import { Select } from 'antd';
import type { SelectProps } from './Select.types';


export const StyledSelect = styled(
  ({ selectorMaxWidth, ...props }: SelectProps) => <Select {...props} />,
)`
  &&&.ant-select-sm {
    .ant-select-selector {
      height: 32px;
      padding: 6px 14px 6px 16px;
    }
  }
  
  &&&.ant-select-sm {
    .ant-select-selector {
      height: 32px;
      padding: 6px 14px 6px 16px;
    }
  }
  
  &&& .ant-select-selector {
    height: 44px;
    padding: 12px 18px 12px 20px;
    font-weight: 400;
    box-shadow: none;
    border-radius: 4px;
    border-color: ${({ theme }) => theme.currentModeConfig.selectBorderColor};
    background: ${({ theme }) => theme.currentModeConfig.selectBackgroundColor};
  }

  &&& .ant-select-selection-search-mirror {
    font-weight: 400;
    min-width: 50px;
  }

  &&& .ant-select-selection-search {
    margin: 0;
  }

  &&.ant-select-multiple {
    min-width: 150px;
    min-height: 44px;
    height: auto;

    .ant-select-selection-placeholder {
      left: 20px;
    }
  }

  &&.ant-select-multiple .ant-select-selector {
    padding: 2px 18px 2px 20px;
    min-height: 44px;
    max-width: ${({ selectorMaxWidth }) => selectorMaxWidth || '400px'};
    height: auto;
  }

  &&& .ant-select-selection-search > input {
    line-height: 18px;
    padding-left: 9px;
    padding-right: 25px;
    font-size: 14px;
    font-family: Inter, sans-serif;
    height: 100% !important;
  }

  &&&.ant-select-multiple .ant-select-selection-search > input {
    padding-inline: 0;
  }

  &&&.ant-select-multiple .ant-select-selection-search {
    margin-right: 25px;
  }

  &&& .ant-select-selection-placeholder, &&& .ant-select-selection-item {
    line-height: 16px;
    height: calc(1em + 4px);
    font-size: 14px;
  }

  & .ant-select-arrow {
    right: 18px;
  }

  && .ant-select-clear {
    margin: 0;
    transform: translateY(-50%);
  }

  && .ant-select-clear, .ant-select-clear svg {
    right: 16px;
    width: 18px;
    height: 18px;
  }

  & svg {
    fill: ${({ theme }) => theme.color.greyscale.grey60};
  }

  &:focus-within, &:focus {
    &&& .ant-select-selector {
      border-color: ${({ theme }) => theme.color.primary.blue60};
      box-shadow: none;
    }
  }
`;
