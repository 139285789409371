import styled from 'styled-components';
import { Radio } from 'antd';


export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  
  & .ant-radio-inner {
    background: transparent;
  }
`;
