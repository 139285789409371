import styled, { DefaultTheme } from 'styled-components';
import { Tag } from 'antd';
import { StatusTagTypes, TagProps } from './Tag.types';


const handleColorType = (status: StatusTagTypes, theme: DefaultTheme) => {
  switch (status) {
    case 'success':
      return theme.color.tertiary.lightGreen;
    case 'warning':
      return theme.color.tertiary.orange;
    case 'error':
      return theme.color.tertiary.reddishPink;
    case 'default':
    default:
      return theme.color.greyscale.grey30;
  }
};

export const StyledTag = styled(({
  status,
  size,
  backgroundColor,
  ...res
}) => <Tag {...res} />)<TagProps>`
  display: flex;
  justify-content: center;
  border: none;
  color: ${({ theme, status }) => (status === 'default'
    ? theme.color.greyscale.grey60
    : theme.color.greyscale.white
  )};
  background: ${({ status, backgroundColor, theme }) => backgroundColor ?? handleColorType(status, theme)};
  padding: ${({ size }) => (size === 'large' ? '10px 16px' : '4px 16px')};
  font-weight: 700;

  & .ant-tag-close-icon {
    margin-left: 5px;
  }
`;
