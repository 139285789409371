import type { FC } from 'react';
import { FormItemProps, Typography } from 'antd';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { CheckboxGroupWithCustomFieldsFormField } from '../../../CheckboxGroupWithCustomFieldsFormField';


interface CompetitorsStepFormItemProps extends FormItemProps {
  cardTitle: string;
  label?: string;
  subTitle?: string;
  selectedValues?: string[];
  tooltip: string;
  placeholders?: string[];
}

export const CompetitorsStepFormItem: FC<CompetitorsStepFormItemProps> = ({
  cardTitle,
  label,
  subTitle,
  selectedValues,
  tooltip,
  placeholders,
  ...rest
}) => {
  const { Title } = Typography;
  const renderMarkdown = useMemo(
    () => <ReactMarkdown rehypePlugins={[rehypeRaw]}>{tooltip}</ReactMarkdown>,
    [tooltip]
  );

  return (
    <>
      <Title level={3}>{cardTitle}</Title>
      <CheckboxGroupWithCustomFieldsFormField
        name={['item', 'competitors']}
        label={`Optional: ${label}`}
        selectedValues={selectedValues}
        placeholders={placeholders}
        subTitle={subTitle}
        tooltip={{
          title: renderMarkdown,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' },
        }}
        largeLabel
        customFieldsCount={4}
        {...rest}
      />
    </>
  );
};
