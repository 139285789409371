import { FC } from 'react';
import { FormItem } from '../../../../../molecules/FormItem';
import { InputNumber } from '../../../../../atoms/InputNumber';
import { FormItemProps } from '../../../../../molecules/FormItem/FormItem.types';


export const MIN_SURVEY_COMPLETION_TIME = 60;

export const SettingsFormItem: FC<FormItemProps> = ({ ...props }) => (
  <>
    <FormItem
      {...props}
      initialValue={MIN_SURVEY_COMPLETION_TIME}
      name={['settings', 'qualityControl', 'minCompletionTime']}
      label="What is the minimal number of seconds respondents should spend on the survey?"
      rules={[
        {
          type: 'integer',
          min: MIN_SURVEY_COMPLETION_TIME,
          required: true,
          message: 'Please enter the amount of seconds (min 30)',
        },
      ]}
      tooltip={{
        title: 'If a respondent spends less than the minimal time on the survey, the answer will be disqualified.',
        arrow: true,
        placement: 'left',
        overlayInnerStyle: { padding: '2px 16px' },
      }}
      errorMessageTextAlign="left"
      largeLabel
    >
      <InputNumber />
    </FormItem>
  </>
);
