import axios from 'axios';
import {
  BillingDetails,
  CustomerPortalSessionFlowType,
  PaymentCollectionMethods,
  PaymentPlan,
  SubscriptionPlanQuota,
  SurveyPrice
} from '../types/stripe';


export const getStripePublishableKey = async (): Promise<{ key: string }> => {
  const url = '/stripe/publishable-key';
  return axios.get(url);
};

export const getStripeSubscriptionPlans = async (): Promise<PaymentPlan[]> => {
  const url = '/stripe/subscriptions';
  return axios.get(url);
};

export const getStripeSurveyPrices = async (): Promise<SurveyPrice[]> => {
  const url = '/stripe/survey/prices';
  return axios.get(url);
};

export const getStripeCurrentSubscription = async (): Promise<PaymentPlan> => {
  const url = '/stripe/subscriptions/current';
  return axios.get(url);
};

export const createStripePaymentIntent = async (
  productId: string,
  priceId: string,
  email: string,
  currency?: string
): Promise<{
  clientSecret?: string;
  invoiceStatus: string;
  subscriptionStatus: string;
  total: number;
}> => {
  const url = '/stripe/payment-intent';
  return axios.post(url, {
    productId, priceId, email, currency
  });
};

export const createStripeSetupIntent = async (): Promise<{ clientSecret: string; }> => {
  const url = '/stripe/setup-intent';
  return axios.post(url);
};

export const createStripeAdditionalProjectInvoice = async (
  productPriceStripeId: string
): Promise<{ invoice: string; clientSecret: string; hostedInvoiceUrl: string; skipConfirmPayment?: boolean; }> => {
  const url = '/stripe/additional-project/invoice';
  return axios.post(url, { priceId: productPriceStripeId });
};

export const createCustomerPortalSession = async (
  redirectUrl: string,
  flowType?: CustomerPortalSessionFlowType
): Promise<{ url: string }> => {
  const url = '/stripe/customer-portal-session';
  return axios.post(url, { redirectUrl, flowType });
};

export const getBillingDetails = async (): Promise<BillingDetails> => {
  const url = '/stripe/billing-details';
  return axios.get(url);
};

export const updateSubscriptionPlan = async (
  planId: string,
  priceId: string | null
): Promise<{ clientSecret?: string }> => {
  const url = '/stripe/subscription/plan';
  return axios.patch(url, { planId, priceId });
};

export const cancelSubscription = async (): Promise<void> => {
  const url = '/stripe/subscription/cancel';
  return axios.post(url);
};

export const getAvailableSubscriptionPlanQuota = async (): Promise<SubscriptionPlanQuota> => {
  const url = '/stripe/company/quota';
  return axios.get(url);
};

export const getProjectInvoiceStatus = async (invoiceId: string)
: Promise<{ invoiceStatus: string; paymentIntentStatus: string; }> => {
  const url = `/stripe/project/invoice/${invoiceId}`;
  return axios.get(url);
};

export const getInvoiceStatus = async (invoiceId: string)
: Promise<{ invoiceStatus: string; paymentIntentStatus: string; }> => {
  const url = `/stripe/invoice/${invoiceId}`;
  return axios.get(url);
};

export const checkCustomerPaymentMethods = async ()
: Promise<{ hasPaymentMethods: boolean; }> => {
  const url = '/stripe/customer/payment-methods/verify';
  return axios.get(url);
};

export const updatePaymentCollectionMethod = async (
  collectionMethod: PaymentCollectionMethods
): Promise<void> => {
  const url = '/stripe/company/payment-collection';
  return axios.patch(url, { collectionMethod });
};

export const getSubscriptionProration = async (
  priceId: string | null
): Promise<{ total: number }> => {
  const url = '/stripe/subscription/proration';
  return axios.put(url, { priceId });
};


export const adminUpdatePaymentCollectionMethod = async (
  companyId: string,
  collectionMethod: PaymentCollectionMethods
): Promise<void> => {
  const url = `/stripe/company/${companyId}/payment-collection`;
  return axios.patch(url, { collectionMethod });
};
