import styled from 'styled-components';
import { Modal } from 'antd';


export const StyledModal = styled(Modal)`
  && .ant-modal-content {
    padding: 0;
  }

  && .ant-modal-header {
    padding: 24px 28px 28px;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.greyscale.grey20}`};
  }

  && .ant-modal-title {
    color: ${({ theme }) => theme.currentModeConfig.titleVariant1};

    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 20px;
    font-feature-settings: 'liga' off;
  }

  && .ant-modal-body {
    padding: 32px 28px 44px;
  }

  && .ant-modal-footer {
    padding: 0 28px 44px;
  }
`;
