import { useCallback, useMemo } from 'react';
import type { ProjectWizardModeSelectorProps } from '../ProjectModeSelectorBase';
import type {
  ProjectWizardDefinition,
  ProjectWizardMode,
  ProjectWizardType,
} from '../types';
import { ProjectType } from '../../../../enums/project-type';
import { StyledProjectModeSelectorText } from '../ProjectModeSelector.styles';


export const useProjectModes = (
  projectType: ProjectType,
  onModeSelect: (mode: Partial<ProjectWizardDefinition>) => void
): ProjectWizardModeSelectorProps['modes'] => {
  const onClickHandlerFactory = useCallback((type: ProjectWizardType, mode: Partial<ProjectWizardMode>) => (
    () => onModeSelect({ type, mode })
  ), [onModeSelect]);

  return useMemo(() => {
    switch (projectType) {
      case ProjectType.Admin: {
        return [
          {
            key: 'customer',
            title: 'Customer Survey',
            description: (
              <>
                Create a survey with&nbsp;
                <StyledProjectModeSelectorText strong>
                  manual distribution
                </StyledProjectModeSelectorText>
                .
                <br />
                You will opt out of our application research services,
                so you can&nbsp;
                <StyledProjectModeSelectorText strong>
                  distribute the survey yourself
                </StyledProjectModeSelectorText>
                .
              </>
            ),
            actions: [
              {
                title: 'Create Survey Manually',
                buttonProps: {
                  variant: 'primary',
                  onClick: onClickHandlerFactory('customer', 'manual'),
                },
                tooltipProps: {
                  title: 'Create a survey using the manual process. '
                    + 'You will go through the same project creation form '
                    + 'as the AI, but here you don’t get any suggestions.'
                }
              }
            ],
          },
          {
            key: 'standard',
            title: 'Standard Survey',
            description: (
              <>
                Create a survey with&nbsp;
                <StyledProjectModeSelectorText strong>
                  application research capabilities
                </StyledProjectModeSelectorText>
                .
                You are only required to fill in the necessary information,
                the data collection process will be&nbsp;
                <StyledProjectModeSelectorText strong>
                  handled by the application
                </StyledProjectModeSelectorText>
                .
              </>
            ),
            actions: [
              {
                title: 'Create Survey Manually',
                buttonProps: {
                  variant: 'primary',
                  onClick: onClickHandlerFactory('standard', 'manual'),
                },
                tooltipProps: {
                  title: 'Create a survey using the manual process. '
                    + 'You will go through the same project creation form '
                    + 'as the AI, but here you don’t get any suggestions.'
                }
              }
            ],
          },
        ];
      }
      default: {
        return [
          {
            key: 'customer',
            title: 'Customer Survey',
            description: (
              <>
                Create a survey with&nbsp;
                <StyledProjectModeSelectorText strong>
                  manual distribution
                </StyledProjectModeSelectorText>
                .
                <br />
                You will opt out of our application research services,
                so you can&nbsp;
                <StyledProjectModeSelectorText strong>
                  distribute the survey yourself
                </StyledProjectModeSelectorText>
                .
              </>
            ),
            actions: [
              {
                title: 'Create AI Survey (Beta)',
                buttonProps: {
                  variant: 'primary',
                  onClick: onClickHandlerFactory('customer', 'AI'),
                },
                tooltipProps: {
                  title: 'Create a survey using AI suggestions. '
                    + 'You are still able to make edits and changes. '
                    + 'But this makes your job easier and faster.'
                }
              },
              {
                title: 'Create Survey Manually',
                buttonProps: {
                  variant: 'secondary',
                  onClick: onClickHandlerFactory('customer', 'manual'),
                },
                tooltipProps: {
                  title: 'Create a survey using the manual process. '
                    + 'You will go through the same project creation form '
                    + 'as the AI, but here you don’t get any suggestions.'
                }
              }
            ],
            ribbonProps: {
              text: 'New',
              color: 'volcano',
            },
          },
          {
            key: 'standard',
            title: 'Standard Survey',
            description: (
              <>
                Create a survey with&nbsp;
                <StyledProjectModeSelectorText strong>
                  application research capabilities
                </StyledProjectModeSelectorText>
                .
                You are only required to fill in the necessary information,
                the data collection process will be&nbsp;
                <StyledProjectModeSelectorText strong>
                  handled by the application
                </StyledProjectModeSelectorText>
                .
              </>
            ),
            actions: [
              {
                title: 'Create AI Survey (Beta)',
                buttonProps: {
                  variant: 'primary',
                  onClick: onClickHandlerFactory('standard', 'AI'),
                },
                tooltipProps: {
                  title: 'Create a survey using AI suggestions. '
                    + 'You are still able to make edits and changes. '
                    + 'But this makes your job easier and faster.'
                }
              },
              {
                title: 'Create Survey Manually',
                buttonProps: {
                  variant: 'secondary',
                  onClick: onClickHandlerFactory('standard', 'manual'),
                },
                tooltipProps: {
                  title: 'Create a survey using the manual process. '
                    + 'You will go through the same project creation form '
                    + 'as the AI, but here you don’t get any suggestions.'
                }
              }
            ],
          },
        ];
      }
    }
  }, [onClickHandlerFactory, projectType]);
};
