import styled from 'styled-components';
import { Select } from 'antd';


export const StyledFormItemWithEditButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const StyledRenderProjectSurveyPreviewList = styled.ol`
  padding-left: 24px;

  & > * {
    ::marker {
      font-family: Inter, sans-serif;
      font-size: 16px;
    }
  }
`;

export const StyledSelect = styled(Select)`
  position: absolute;
  top: 23px;
  right: 50px;
`;
