import type { FC } from 'react';
import { FormItemProps, Typography } from 'antd';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { CheckboxGroupWithCustomFieldsFormField } from '../../../CheckboxGroupWithCustomFieldsFormField';


interface UsageStepFormItemProps extends FormItemProps {
  cardTitle: string;
  label?: string;
  subTitle?: string;
  options?: { label: string; value: string }[];
  tooltip: string;
  placeholders?: string[];
  selectedValues?: string[];
}

export const UsageStepFormItem: FC<UsageStepFormItemProps> = ({
  cardTitle,
  label,
  subTitle,
  options,
  tooltip,
  placeholders,
  selectedValues,
  ...rest
}) => {
  const { Title } = Typography;
  const renderMarkdown = useMemo(
    () => (
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {tooltip}
      </ReactMarkdown>
    ),
    [tooltip]
  );

  return (
    <>
      <Title level={3}>{cardTitle}</Title>
      <CheckboxGroupWithCustomFieldsFormField
        name={['item', 'usage']}
        label={label}
        placeholders={placeholders}
        options={options}
        selectedValues={selectedValues}
        subTitle={subTitle}
        tooltip={{
          title: renderMarkdown,
          arrow: true,
          placement: 'left',
          overlayInnerStyle: { padding: '2px 16px' }
        }}
        rules={[{
          required: true,
          type: 'array',
          min: 2,
          max: 4,
          message: 'Please select from 2 to 4 usages!',
        }]}
        largeLabel
        customFieldsCount={4}
        errorMessageJustify="start"
        {...rest}
      />
    </>

  );
};
