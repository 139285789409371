import type { FormInstance } from 'antd';
import type { NamePath } from 'antd/es/form/interface';


interface ForceDispatchFormUpdateEventArgumentsType {
  form: FormInstance;
  namePath: NamePath;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

// Hack to dispatch Ant design's Form onValuesChange programmatically
export const forceDispatchFormUpdateEvent = ({ form, namePath, value } : ForceDispatchFormUpdateEventArgumentsType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formDispatch = (form as any).getInternalHooks('RC_FORM_INTERNAL_HOOKS').dispatch;
  formDispatch({ type: 'updateValue', namePath, value });
};
