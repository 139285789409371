import axios, { AxiosRequestConfig } from 'axios';
import {
  AdminProvisionCompanyFieldsType,
  IAdminCompaniesList, IAdminCompanyDetails, ICompany, UpdateCompanyParams
} from '../types/company.types';


export const createCompany = async (company: Omit<ICompany, '_id' | 'updatedAt' | 'paymentPlan' | 'owner' | 'projects'>)
: Promise<ICompany> => {
  const url = '/company';
  return axios.post(url, company);
};

export const getCompany = async (companyId: string): Promise<ICompany> => {
  const url = `/company/${companyId}`;

  return axios.get(url);
};

export const updateCompany = async (companyId: string, company: UpdateCompanyParams): Promise<ICompany> => {
  const url = `/company/${companyId}`;

  return axios.patch(url, company);
};

export const adminGetCompanies = async (onlyNames?: boolean): Promise<IAdminCompaniesList[]> => {
  const url = '/admin/companies';
  const options: AxiosRequestConfig = {};

  if (onlyNames) {
    options.params = {
      onlyNames
    };
  }

  return axios.get(url, options);
};

export const adminGetCompanyDetails = async (id: string): Promise<IAdminCompanyDetails> => {
  const url = `/admin/companies/${id}`;

  return axios.get(url);
};

export const delegateCompanyOwnership = async (userId: string) => {
  const url = '/company/ownership';

  return axios.patch(url, { userId });
};

export const adminProvisionCompany = async (provisioningFields: AdminProvisionCompanyFieldsType) => {
  const url = '/admin/company/provision';

  return axios.post(url, provisioningFields);
};

export const adminGetCompanyAuthToken = async (companyId: string): Promise<{ token: string }> => {
  const url = `/admin/company/${companyId}/token`;
  return axios.get(url);
};
